export function fastSortOnIntKey(array, keyFunction) {
  const MASK = 0xfff;
  const count = array.length;
  if (count >= 4095) {
    array.sort((a, b) => {
      return keyFunction(a) - keyFunction(b);
    });
    return;
  }
  /* eslint-disable */
  const sortingArray = new Uint32Array(count);
  /* eslint-enable */
  for (let i = 0; i < count; i++) {
    sortingArray[i] = (keyFunction(array[i]) << 12) | i;
  }
  sortingArray.sort(); // native numeric sort
  const copiedArray = array.slice();
  for (let i = 0; i < count; i++) {
    array[i] = copiedArray[sortingArray[i] & MASK];
  }
}

export function testFastSortOnIntKey() {
  // 30 bits of unsigned space in a v8 SMI
  // const maxValue = 262143; // ~ 2^18
  const maxValue = 524286; // ~ 2^19
  const size = 4095; // ~ 2^12
  const a = Array(size);
  a[0] = maxValue;
  for (let i = 1; i < size; i++) {
    a[i] = Math.floor(Math.random() * maxValue);
  }

  let b = Uint32Array.from(a);
  b.sort();
  b = Array.from(b);
  if (JSON.stringify(a) === JSON.stringify(b)) {
    console.log('oops');
  }

  const t1 = Date.now();
  fastSortOnIntKey(a, a => a);
  console.log('time: ' + (Date.now() - t1));

  if (JSON.stringify(a) === JSON.stringify(b)) {
    console.log('working');
    console.log(a[size - 1]);
  } else {
    console.log('hmm');
  }
  return a;
}

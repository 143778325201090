import { Alert, AlertMessages } from '../../../alert-messages';
import { Cue, ElementId, isNil, notNil } from '../../../basic-types';
import { getContentStringFromWordIdMaxChars } from '../../../content-funcs';
import { ElementList } from '../../../elements/element-list';
import { swalPromptYN } from '../../../sweetalert-yn';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class CueActions {
  lastCue = { wordId: null, timestamp: 0, input: false, navStop: false };

  audioPositionWordId = 0; // TODO compute from audio pos and words entity list?

  async addCue() {
    // TODO properly deal with promise
    const insertWordId = this.cueInsertWordId;
    if (isNil(insertWordId)) {
      this.alertMessages.add({
        ...Alert,
        text: 'Error:Trying to add cue without setting cue insertion position',
      });
      return;
    }

    const audioPos = this.currentAudioPosition;

    let problematic = false;
    if (
      insertWordId === this.lastCue.wordId &&
      Math.abs(audioPos - this.lastCue.timestamp) > 1000
    ) {
      problematic = true;
    }
    if (Math.abs(audioPos - this.words.time(insertWordId)) > 1500) {
      problematic = true;
    }

    let goahead = true;

    if (problematic) {
      goahead = await swalPromptYN({
        title: 'Mistake?',
        html:
          'Current audio position seems far from cue words<br> Cue at: ' +
          "<span style='color:red;'>" +
          getContentStringFromWordIdMaxChars(insertWordId, this.words, 40) +
          '</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Add cue',
      });
    }

    if (goahead) {
      this.lastCue = { wordId: insertWordId, timestamp: audioPos, input: true, navStop: false };
      this.mutationActions.addUpdateCue(this.lastCue);
    }
  }

  currentTimeWordId(): ElementId {
    const currentWord = this.words.getElementContainingTime(this.currentAudioPosition);
    return currentWord?.id ?? null;
  }

  addShiftCue() {
    this.cueInsertWordId = this.currentTimeWordId();
    this.addCue();
  }

  addShiftEndCue() {
    const currentWordId = this.currentTimeWordId();
    if (notNil(currentWordId)) {
      this.cueInsertWordId = this.words.nextId(currentWordId);
      this.addCue();
    }
  }

  removeCueAtCurrent() {
    const insertWordId = this.cueInsertWordId;
    if (isNil(insertWordId)) {
      this.alertMessages.add({
        ...Alert,
        text: 'Error:Trying to remove cue without setting cue insertion position',
      });
      return;
    }
    const existing: Cue = this.existingCues.find(cue => (cue.wordId = insertWordId));
    if (existing) {
      this.lastCue = existing;
      this.mutationActions.removeCue(existing.wordId, true);
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get alertMessages(): AlertMessages;
  abstract get cueInsertWordId(): ElementId;
  abstract set cueInsertWordId(value: ElementId);
  abstract get currentAudioPosition(): number;
  abstract get words(): ElementList;
  abstract get existingCues(): Cue[]; // TODO change to map from wordId???
}

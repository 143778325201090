import { jsArraySlice as jsArraySlice_1 } from "./util.js";
import { rangeNumber } from "../../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { indexed } from "../../../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { searchSorted } from "../../../../sorted/search-sorted.fs.js";
import { System_Array__$005B$005D$1_get_lastIndex, System_Array__$005B$005D$1_append_1505, System_Array__$005B$005D$1_extend_5975E3 } from "../../../../basic-types.fs.js";

export const jsArraySlice = jsArraySlice_1;

export function System_Array__$005B$005D$1_slice_Z3227AE51(x, slice) {
    return jsArraySlice(x, slice);
}

export function sliceIndexes(slice_0, slice_1) {
    const slice = [slice_0, slice_1];
    const starts = slice[0] | 0;
    const ends = slice[1] | 0;
    return rangeNumber(starts, 1, ends);
}

export function unionIntervals(aStarts, aEnds, bStarts, bEnds) {
    let indexACopyFrom = 0;
    let indexACopyTo = 0;
    let unionStart = 0;
    let unionEnd = 0;
    const startsOut = [];
    const endsOut = [];
    const maxValidIndexA = (aStarts.length - 1) | 0;
    if (maxValidIndexA < 0) {
        return [bStarts, bEnds];
    }
    const arr = indexed(bStarts);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const startVal = forLoopVar[1] | 0;
        const indexB = forLoopVar[0] | 0;
        const startIndexA = (searchSorted(aStarts, startVal, false) - 1) | 0;
        if ((startIndexA <= maxValidIndexA) ? (aEnds[startIndexA] > startVal) : false) {
            unionStart = aStarts[startIndexA];
            indexACopyTo = (startIndexA - 1);
        }
        else {
            indexACopyTo = startIndexA;
            unionStart = startVal;
        }
        if ((indexACopyFrom <= maxValidIndexA) ? (indexACopyFrom <= indexACopyTo) : false) {
            System_Array__$005B$005D$1_extend_5975E3(startsOut, aStarts.slice(indexACopyFrom, indexACopyTo + 1));
            System_Array__$005B$005D$1_extend_5975E3(endsOut, aEnds.slice(indexACopyFrom, indexACopyTo + 1));
        }
        const endIndexA = searchSorted(aEnds, bEnds[indexB], false) | 0;
        if ((endIndexA <= maxValidIndexA) ? (aStarts[endIndexA] < bEnds[indexB]) : false) {
            unionEnd = aEnds[endIndexA];
            indexACopyFrom = (endIndexA + 1);
        }
        else {
            indexACopyFrom = endIndexA;
            unionEnd = bEnds[indexB];
        }
        System_Array__$005B$005D$1_append_1505(startsOut, unionStart);
        System_Array__$005B$005D$1_append_1505(endsOut, unionEnd);
    }
    if (indexACopyFrom <= maxValidIndexA) {
        System_Array__$005B$005D$1_extend_5975E3(startsOut, aStarts.slice(indexACopyFrom, System_Array__$005B$005D$1_get_lastIndex(aStarts) + 1));
        System_Array__$005B$005D$1_extend_5975E3(endsOut, aEnds.slice(indexACopyFrom, System_Array__$005B$005D$1_get_lastIndex(aStarts) + 1));
    }
    return [startsOut, endsOut];
}


import { makeObservable, observable, reaction } from 'mobx';

export class TransportState {
  constructor() {
    this.audioPosition = 0;
    this.audioRestartPosition = 0;
    this.audioLastPlayToPosition = 0;
    this.isPlaying = false;
    this.playbackRate = 1.0;
    this.kerningEnabled = false;
    makeObservable(this, {
      audioPosition: observable,
      audioRestartPosition: observable,
      audioLastPlayToPosition: observable,
      isPlaying: observable,
      playbackRate: observable,
      kerningEnabled: observable,
    });
  }
}

const CURRENTTIME_SAMPLING_START_COUNT = -3;
const SYNC_AUDIO_START_COUNT = -5;

export class AudioTransport {
  constructor(transportState) {
    this.transportState = transportState;
    // __audioSource = null;
    this.audioElement = null;
    this._audioDuration = 0;
    this.audioStart = 0;
    this.audioEnd = 0;
    this.playbackRate = 1.0;
    this.timeTweak = 0;

    this.syncAudioCount = SYNC_AUDIO_START_COUNT;
    this.syncAudioPositionTimer = null;

    this.audioPositionHandlerAttached = false;
    this.currentTimeLastUpdateTimestamp = CURRENTTIME_SAMPLING_START_COUNT;
    this.currentTimeLastUpdatePosition = null;

    this.pauseAfter = 0; // TODO put in TransportState?

    this.kerningEnabled = false;
    this.activeKerningTimer = null;
    this.kerningPoints = null;
    this.kerningIntervals = null;
    this.currentKerningInterval = null;
    this.kerningLoweringVolume = false;
  }

  setAudioSource(audioSource) {
    // this.__audioSource = audioSourceRef;
    this._audioDuration = 0;
    const audioElementFunc = audioSource.audioElementFunction;
    this.audioElement = audioElementFunc();
    reaction(audioElementFunc, element => (this.audioElement = element));
  }

  play() {
    this.syncAudioCount = 0;
    this.listenCurrentTimeUpdate();
    this.activateSyncAudioTimer();
    this.transportState.isPlaying = true;
    this.audioSource.play();
  }

  pause(keepPauseAfter = false) {
    this.clearKerningResumeTimer();
    this.audioSource.pause();
    // TODO add conditional behavior
    if (!keepPauseAfter) {
      this.pauseAfter = 0;
    }
    this.clearSyncAudioTimer(100);
    this.resetCurrentTimeSampleCount();
    this.transportState.isPlaying = false;
  }

  seek(time) {
    // TODO if seek should we cancel an activeKerningTimer and play immediately?
    this.transportState.audioPosition = time;
    this.currentKerningInterval = null;
    this.resetSyncAudioCount();
    // TODO translate and limit for self audio interval
    this.audioSource.currentTime = (time - this.timeTweak) / 1000;
  }

  rewind() {
    if (this.transportState.audioRestartPosition) {
      this.seek(this.transportState.audioRestartPosition);
    }
  }

  get audioDuration() {
    if (
      !this._audioDuration &&
      // this.__audioSource &&
      this.audioSource &&
      // this.__audioSource.current &&
      // this.__audioSource.current.duration
      this.audioSource &&
      this.audioSource.duration
    ) {
      this._audioDuration = Math.floor(
        // this.__audioSource.current.duration * 1000
        this.audioSource.duration * 1000
      );
    }
    return this._audioDuration;
  }

  get audioSource() {
    //return this.__audioSource.current;
    // return this.__audioSource;
    return this.audioElement;
  }

  setPauseAfter(time) {
    // TODO audioTransport.setPauseAfter(Math.min(this.audioPosition + this.peekDuration, this.selfInterval.end));
    this.pauseAfter = time;
  }

  clearPauseAfter() {
    this.pauseAfter = 0;
  }

  resetCurrentTimeSampleCount() {
    this.currentTimeLastUpdateTimestamp = CURRENTTIME_SAMPLING_START_COUNT;
  }

  resetSyncAudioCount() {
    this.syncAudioCount = SYNC_AUDIO_START_COUNT;
  }

  get reallyPlaying() {
    return this.transportState.isPlaying && !this.activeKerningTimer;
  }

  get sufficientCurrentTimeSamples() {
    return this.currentTimeLastUpdateTimestamp > -1;
  }

  incrementCurrentTimeSampleCount() {
    this.currentTimeLastUpdateTimestamp++;
  }

  get audioPosition() {
    // TODO if stop considering interpolation then time returned can jump backwards until currentTime updates
    if (
      !this.reallyPlaying ||
      !this.sufficientCurrentTimeSamples ||
      !this.currentTimeLastUpdateTimestamp
    ) {
      return (
        // Math.round(this.__audioSource.current.currentTime * 1000) +
        Math.round(this.audioSource.currentTime * 1000) + this.timeTweak
      );
    } else {
      return (
        (Date.now() - this.currentTimeLastUpdateTimestamp) * this.playbackRate +
        this.currentTimeLastUpdatePosition +
        this.timeTweak
      );
    }
  }

  listenCurrentTimeUpdate() {
    if (!this.audioPositionHandlerAttached) {
      this.audioSource.addEventListener('timeupdate', () => {
        this.handleAudioCurrentTimeUpdate(
          // this.__audioSource.current.currentTime
          this.audioSource.currentTime
        );
      });
      this.audioPositionHandlerAttached = true;
    }
  }

  activateSyncAudioTimer() {
    // TODO calculate actually setInterval average period when set to 15ms
    this.syncAudioPositionTimer = setInterval(() => this.syncAudioPosition(), 15);
  }

  clearSyncAudioTimer(onceAgain = 0) {
    // TODO add conditional behavior
    if (this.syncAudioPositionTimer) {
      clearInterval(this.syncAudioPositionTimer);
      this.syncAudioPositionTimer = null;
      if (onceAgain) {
        setTimeout(() => {
          this.resetCurrentTimeSampleCount();
          this.resetSyncAudioCount();
          this.syncAudioPosition();
        }, onceAgain);
      }
    }
  }

  clearKerningResumeTimer() {
    if (this.activeKerningTimer) {
      clearTimeout(this.activeKerningTimer);
      this.activeKerningTimer = null;
    }
  }

  setAudioRestartPosition(time) {
    this.transportState.audioRestartPosition = time;
  }

  clearAudioRestartPosition() {
    if (this.transportState.audioRestartPosition) {
      this.transportState.audioRestartPosition = 0;
    }
  }

  handleAudioCurrentTimeUpdate(time) {
    if (!this.transportState.isPlaying || this.activeKerningTimer) {
      // TODO this is key to kerning smooth resume?
      return;
    }
    if (!this.sufficientCurrentTimeSamples) {
      this.incrementCurrentTimeSampleCount();
      return;
    }
    this.currentTimeLastUpdateTimestamp = Date.now(); // todo: rename this
    this.currentTimeLastUpdatePosition = Math.round(time * 1000);
  }

  syncAudioPosition() {
    const pos1 = this.transportState.audioPosition;
    this.syncAudioCount++;
    const pos2 = this.audioPosition;
    if ((this.syncAudioCount < 0 || this.syncAudioCount & 0x1) && pos1 !== pos2) {
      this.transportState.audioPosition = pos2;
    }
    //TODO don't hardcode -7 use half setInterval timer val
    if (this.transportState.isPlaying && this.pauseAfter && pos2 > this.pauseAfter - 7) {
      // TODO consider this.audioEnd
      this.pause();
      this.rewind();
    }
    if (this.kerningEnabled) {
      this.checkKerning(pos2);
    }
  }

  checkKerning(pos) {
    if (this.kerningEnabled && !this.activeKerningTimer) {
      const currentInterval = this.currentKerningInterval;
      if (!currentInterval) {
        const kernIdx = this.kerningIntervals.containing(pos);
        this.currentKerningInterval = this.kerningIntervals.interval(kernIdx);
      } else {
        const intervalStart = currentInterval.start;
        const intervalEnd = currentInterval.end;
        if (pos > intervalStart && pos < intervalEnd) {
          if (pos > intervalStart + 90 && pos < intervalEnd - 90) {
            if (this.kerningLoweringVolume) {
              this.audioSource.volume = 1;
              this.kerningLoweringVolume = false;
            }
          } else {
            if (!this.kerningLoweringVolume) {
              this.audioSource.volume = 0.1;
              this.kerningLoweringVolume = true;
            }
          }
          return;
        }
        const kernIdx = this.kerningIntervals.containing(pos);
        this.currentKerningInterval = this.kerningIntervals.interval(kernIdx);
        if (pos < currentInterval.start) {
          return;
        }
        this.activeKerningTimer = setTimeout(() => this.handleKerningTimer(), this.kerningDuration);
        this.audioSource.pause();
        this.resetCurrentTimeSampleCount();
        this.resetSyncAudioCount();
        clearInterval(this.syncAudioPositionTimer);
      }
    }
  }

  handleKerningTimer() {
    if (!this.activeKerningTimer) {
      return;
    }
    this.activeKerningTimer = null;
    this.play();
  }

  setPlaybackRate(rate) {
    // this value is duplicated on this and in transportState, it's more efficient to read a non-observable
    // but is the difference relevant?
    this.playbackRate = rate;
    this.transportState.playbackRate = rate;
    // this.__audioSource.current.playbackRate = this.playbackRate;
    this.audioSource.playbackRate = this.playbackRate;
  }

  setKerningPoints(points) {
    this.kerningPoints = points;
    this.kerningIntervals = points.asIntervals(0, 10000000); // TODO
  }

  setKerningDuration(duration) {
    this.kerningDuration = duration;
  }

  setKerning(points, duration) {
    this.setKerningPoints(points);
    this.setKerningDuration(duration);
  }

  kerningEnable(enable) {
    // this value is duplicated on this and in transportState, it's more efficient to read a non-observable
    // but is the difference relevant?
    this.kerningEnabled = enable;
    this.transportState.kerningEnabled = enable;
  }
}

/* TODO
think about change to?

audioRewindPosition
audioStopAfterPosition

rewindOnPause
rewindOnReachStop
clearStopOnSeek
clearStopOnSeekOutside
notifyReachStop?
 */

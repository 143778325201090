// open BasicTypes
// open ElementList
// open ElementTypes
// open ElementSort
// open ContentRoots
// open TimelineNavigator
// open JSBoilerplate

import { computed } from 'mobx';
import { ContentRootsBase } from './masala-lib/editorial/content-roots/content-roots';
import { Element } from './masala-lib/basic-types';
import { EKinds } from './masala-lib/elements/element-kinds';
import { sortElements } from './masala-lib/elements/element-sort';
import { ElementList } from './masala-lib/elements/element-list';
import { EdgeEnum, TimelineNavigator } from './masala-lib/navigation/timeline-navigator';

// type IChaatContentRoots =

// type ChaatContentRoots0() as s0 =
export class ChaatContentRoots extends ContentRootsBase {
  stateVersion0 = 1;

  @computed({ keepAlive: true })
  get words() {
    return this.words1;
  }

  @computed({ keepAlive: true })
  get content() {
    const result = [];
    result.push(...this.sentences1);
    const displayStructuralKinds: string[] = [
      EKinds.CHAPTER_TITLE,
      EKinds.PASSAGE_HINT,
      EKinds.SPEAKER_LABEL,
    ];
    const chaatDisplayStructuralElement = (e: Element) => displayStructuralKinds.includes(e.kind);
    result.push(...this.structural0.filter(chaatDisplayStructuralElement));
    sortElements(result);
    return new ElementList(result, this.episodeKey, null, this.words1, null, null, null);
  }

  @computed({ keepAlive: true })
  get warningSentences() {
    return this.content.fromIds(this.warningSentenceIds);
  }

  @computed({ keepAlive: true })
  get segmentNavigator(): TimelineNavigator {
    const navigator = new TimelineNavigator('SEGMENT');
    // TODO consider better way besides option param??
    navigator.setIntervals(this.segmentTimeIntervals, EdgeEnum.Midpoint);
    return navigator;
  }

  @computed({ keepAlive: true })
  get notchNavigator(): TimelineNavigator {
    const navigator = new TimelineNavigator('NOTCH');
    navigator.setIntervals(this.notchTimeIntervals, EdgeEnum.Midpoint);
    return navigator;
  }

  @computed({ keepAlive: true })
  get playableElements() {
    const result = [];
    result.push(...this.content.getKindSubList(EKinds.SENTENCE).elements);
    result.push(...this.words.elements);
    sortElements(result);
    return new ElementList(result, this.episodeKey, null, this.words1, null, null, null);
  }

  get stateVersion() {
    const touch = [this.words, this.content];
    this.stateVersion0 = this.stateVersion0 + 1;
    return this.stateVersion0;
  }
}

// let ChaatContentRoots():IChaatContentRoots = !< ChaatContentRoots0()

import { Record } from "../../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, int32_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";

export class TimeInterval extends Record {
    constructor(startTime, endTime) {
        super();
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function TimeInterval$reflection() {
    return record_type("TimestamperTypes.TimeInterval", [], TimeInterval, () => [["startTime", int32_type], ["endTime", int32_type]]);
}


export enum EKinds {
  SENTENCE = 'SENTENCE',
  WORD_GROUP = 'WORD_GROUP',

  CHAPTER_TITLE = 'CHAPTER_TITLE',
  PASSAGE_HINT = 'PASSAGE_HINT',
  PARAGRAPH_BREAK = 'PARAGRAPH_BREAK',
  SPEAKER_LABEL = 'SPEAKER_LABEL',
  CULTURAL_NOTE = 'CULTURAL_NOTE',
  CHAPTER_SUMMARY = 'CHAPTER_SUMMARY',
  CHAPTER_COMPLETE = 'CHAPTER_COMPLETE',

  WORD = 'WORD',
  TRICKY = 'TRICKY',
  VOCAB = 'VOCAB',
  SIC = 'SIC',

  TRANSLATION = 'TRANSLATION',
  METADATA_BLOCK = 'METADATA_BLOCK',
  METADATA_URL = 'METADATA_URL',

  METADATA = 'METADATA',
  CAST = 'CAST',
  ASSET_LINKS = 'ASSET_LINKS',
  NOTES = 'NOTES',

  ADHOC_WORD_RANGE = 'ADHOC_WORD_RANGE',
}

export const structuralKinds: string[] = [
  EKinds.CHAPTER_TITLE,
  EKinds.CHAPTER_SUMMARY,
  EKinds.CULTURAL_NOTE,
  EKinds.SPEAKER_LABEL,
  EKinds.PASSAGE_HINT,
  EKinds.PARAGRAPH_BREAK,
  EKinds.CHAPTER_COMPLETE,
];

// open Fable.Core
// open Fable.Core.JsInterop
// open BasicTypes
// open Fable.React
// open Keyboardist
// open Mobx
// open AppRoot
// open ElementList
// open ElementTypes
// open StyleLayers
// open DomScroll
// open Browser.Types
// open JSBoilerplate

import React from 'react';
import { appBus, appRoot, chaatToolModel, player, tracking, transportState } from './app-root';
import Keyboardist from 'keyboardist';
import { domIdToElementId, ElementId, getKindFromId } from './masala-lib/basic-types';
import {
  simpleRenderStylesForElementId,
  StyleLayer,
  StyleLayersRenderer,
} from './masala-lib/editorial/ui/style-painting/style-layers';
import { EKinds } from './masala-lib/elements/element-kinds';
import { SimpleElementList } from './masala-lib/elements/element-list';
import { autorun, reaction } from 'mobx';
import { scrollIfNotVisible } from './masala-lib/editorial/ui/dom-scroll';

// type IChaatTool =

// let inline renderChaatToolView(chaat:IChaatTool):ReactElement = JsInterop.import "renderView" "./chaat-tool-view.js"
import { renderView as renderChaatToolView } from './chaat-tool-view.js';
import { observer } from 'mobx-react';

// type ChaatTool0(initialProps) as s0 =
@observer
export class ChaatTool extends React.Component<any> {
  model = chaatToolModel;

  disposers: (() => void)[] = [];

  unsubscribeWordIsUnderNotify: () => void = null;
  unsubscribeSentenceIsUnderNotify: () => void = null;

  inputHandler = Keyboardist();

  constructor(props) {
    super(props);
    this.initializeInputHandlers();
    this.disposers.push(
      reaction(
        () => this.styleLayers(),
        () => this.renderStyleLayers()
      )
    );
    autorun(() => this.setupCursorTracking());
    this.disposers.push(
      reaction(
        () => this.model.currentSentenceId,
        () => scrollIfNotVisible(this.model.currentSentenceId, 'nearest', 'scriptId')
      )
    );
  }

  initializeInputHandlers() {
    const handler = this.inputHandler;
    // TODO actual forwards
    handler.subscribe('KeyC', () => this.model.addCue());
    handler.subscribe('Shift+KeyC', () => this.model.removeCueAtCurrent());

    handler.subscribe('KeyR', () => this.model.createAudioRegion());
    handler.subscribe('Shift+KeyR', () => this.model.removeAudioRegion());

    handler.subscribe('KeyM', () => this.model.createAudioMarker());
    handler.subscribe('Shift+KeyM', () => this.model.removeAudioMarker());

    handler.subscribe('KeyA', () => this.model.addShiftCue());
    handler.subscribe('KeyS', () => this.model.addShiftEndCue());

    handler.subscribe('KeyO', () => this.model.setCurrentSentenceSignoff(true));
    handler.subscribe('Shift+KeyO', () => this.model.setCurrentSentenceSignoff(false));

    // TODO change param value to enum is now a bool but thought was int
    handler.subscribe('Equal', () => appBus.emit('timeZoom', true));
    handler.subscribe('Plus', () => appBus.emit('timeZoom', true));
    handler.subscribe('NumpadAdd', () => appBus.emit('timeZoom', true));

    handler.subscribe('Minus', () => appBus.emit('timeZoom', false));
    handler.subscribe('NumpadSubtract', () => appBus.emit('timeZoom', false));

    handler.subscribe('Escape', () => this.model.deselect());

    handler.subscribe('Space', () => player.togglePlay());
    handler.subscribe('Ctrl+Space', () => player.playSection());
    handler.subscribe('KeyQ', () => player.playSection());
    handler.subscribe('Enter', () => player.rewind());
    handler.subscribe('Shift+Enter', () => player.playPeekBack());
    handler.subscribe('KeyX', () => player.playPeek());
    handler.subscribe('Shift+KeyX', () => player.playPeekBack());
    handler.subscribe('Up', () => player.adjustPlaybackRateAction(true)); // TODO
    handler.subscribe('Down', () => player.adjustPlaybackRateAction(false)); // TODO
    handler.subscribe('Shift+Left', () => player.seek(transportState.audioPosition - 1500)); // TODO

    handler.subscribe('Shift+Right', () => player.seek(transportState.audioPosition + 1500)); // TODO
    handler.subscribe('Alt+Left', () => player.nudge(-20));
    handler.subscribe('Alt+Right', () => player.nudge(20));
    handler.subscribe('Left', () => player.prevClosest());
    handler.subscribe('Right', () => player.nextClosest());
    handler.subscribe('Digit0', () => appRoot.runTimestamping());
  }

  handleCursorChange(id: ElementId) {
    const under = tracking.isUnder(id);
    simpleRenderStylesForElementId(id, ['cursor-is-under'], !under);
  }

  handleWordClick(event: MouseEvent, id) {
    if (event.altKey || event.ctrlKey || event.shiftKey) {
      appBus.emit('setCuePoint', id);
    } else {
      player.seekElement(id);
    }
  }

  handleLineClick(event: MouseEvent, domId) {
    const id = domIdToElementId(null, domId);
    const kind = getKindFromId(id);
    if (kind === EKinds.WORD) {
      this.handleWordClick(event, id);
    } else {
    }
  }

  setupCursorTracking() {
    if (this.unsubscribeWordIsUnderNotify) {
      this.unsubscribeWordIsUnderNotify();
    }

    if (this.unsubscribeSentenceIsUnderNotify) {
      this.unsubscribeSentenceIsUnderNotify();
    }

    this.unsubscribeWordIsUnderNotify = this.model.wordTracker.subscribeIsUnder((id: ElementId) =>
      this.handleCursorChange(id)
    );
    this.unsubscribeSentenceIsUnderNotify = this.model.sentenceTracker.subscribeIsUnder(
      (id: ElementId) => this.handleCursorChange(id)
    );
  }

  minorWarningsStyleLayer() {
    // TODO remove these conditionals everywhere because model initialized to EmptyElementList
    const elements = this.model.minorWarnings ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
    return {
      styles: ['minor-warning'],
      domScope: '',
      supportWordRanges: true,
      elements: elements,
      stylesString: null,
    };
  }

  // TODO computedFunc?
  majorWarningsStyleLayer() {
    const elements = this.model.majorWarnings ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
    return {
      styles: ['major-warning'],
      domScope: '',
      supportWordRanges: true,
      elements: elements,
      stylesString: null,
    };
  }

  // TODO computed?
  segmentsStyleLayer() {
    const elements = this.model.segmentStopWords ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
    return {
      styles: ['segment-stop-word'],
      domScope: '',
      supportWordRanges: false,
      elements: elements,
      stylesString: null,
    };
  }

  cueMarkersStyleLayer() {
    const elements = this.model.cuedWords ?? SimpleElementList([]); // TODO change to some const EMPTY_ELEMENT_LIST??
    return {
      styles: ['cue-marker'],
      domScope: '',
      supportWordRanges: false,
      elements: elements,
      stylesString: null,
    };
  }

  cueInsertPointStyleLayer() {
    const cuePointWordId = this.model.currentCuePointWordId;
    const currentCuePointElement = cuePointWordId
      ? this.model.words.getElement(cuePointWordId)
      : null;
    const els = currentCuePointElement ? [currentCuePointElement] : [];
    return {
      styles: ['cue-insert-point'],
      domScope: '',
      supportWordRanges: false,
      elements: SimpleElementList(els),
      stylesString: null,
    };
  }

  unsignedoffSentencesStyleLayer() {
    return {
      styles: ['unsignedoff'],
      domScope: '',
      supportWordRanges: false,
      elements: this.model.unsignedoffSentences,
      stylesString: null,
    };
  }

  styleLayers() {
    const result: Map<string, StyleLayer> = new Map();
    // TODO should the individual style layers be @computed or @computedFunc, reference ids changing every time any changes?
    result.set('minorWarnings', this.minorWarningsStyleLayer());
    result.set('majorWarnings', this.majorWarningsStyleLayer());
    result.set('segmentStopWords', this.segmentsStyleLayer());
    result.set('cueMarkers', this.cueMarkersStyleLayer());
    result.set('cueInsertPoint', this.cueInsertPointStyleLayer());
    result.set('unsignedOffSentences', this.unsignedoffSentencesStyleLayer());
    return result;
  }

  styleLayersRenderer = new StyleLayersRenderer();

  renderStyleLayers() {
    this.styleLayersRenderer.renderStyleLayers(this.model.episodeKey, this.styleLayers());
  }

  render() {
    return renderChaatToolView(this);
  }
}

// export const ChaatTool = observer(ChaatToolImpl);

// let ChaatTool:ReactElement = emitJsStatement observer "return observer(ChaatTool0)"

import { isEmpty } from 'lodash';
import { makeObservable, observable, when } from 'mobx';
import { COMPLETE, ERROR_NOT_LOADABLE, NOT_INITIATED, IN_PROGRESS } from './firestore-doc-set.js';

// TODO rename to FirestoreDocLoader and also put "Doc" in all subclasses, reflects actual purpose
export class FirestoreLoader {
  /** @type {FirestoreDocSet} */

  constructor() {
    this.docSet = null;
    this.key = null;
    makeObservable(this, {
      docSet: observable.ref,
    });
  }

  /** @return {FirestoreDocSet} */
  newDocSet(key, listenMode = true) {
    throw Error('Abstract method unimplemented');
  }

  async loadEpisode(key, listenMode = true) {
    console.log(`loadEpisode(${key})`);
    if (key === this.key) {
      console.log(`ignoring redundant loadEpisode(${key})`);
      return;
    }

    // don't close during initial load
    // this was some defeating the review tool player loading
    if (this.docSet) {
      console.log(`oldKey: ${JSON.stringify(this.key)}, newKey: ${key}`);
      this.closeEpisode();
    }
    this.key = key;
    this.docSet = this.newDocSet(key, listenMode);
    this.docSet.load();
  }

  closeEpisode() {
    console.log('fs-loader.closeEpisode()');
    this.key = null;
    if (this.docSet) {
      this.docSet.close();
    }
    this.docSet = null;
  }

  getStateVersion() {
    if (!this.docSet) {
      return '';
    }
    return this.docSet.key + '_' + this.docSet.status + '_' + this.docSet.stateVersion;
  }

  getStatus() {
    if (!this.docSet) {
      return '';
    }
    return this.docSet.status;
  }

  promiseWhenComplete() {
    const completeOrError = () => {
      if (!this.docSet) {
        return false; // TODO or true?
      }
      const status = this.docSet.status;
      return status === COMPLETE || (status !== NOT_INITIATED && status !== IN_PROGRESS);
    };
    return when(completeOrError, {});
  }
}

import { Record } from "../../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { ContentSpan__get_words, AudioRegionsSpan, CuesSpan, TranscriptSpan, ContentSpan, computeCueFrameSliceByTime, computeTranscriptSliceByTime, AudioRegionsSpan$reflection, CuesSpan$reflection, TranscriptSpan$reflection, ContentSpan$reflection } from "./spans.fs.js";
import { array_type, record_type, int32_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { TimeInterval, TimeInterval$reflection } from "./timestamper-types.fs.js";
import { sliceFrame } from "./frame.fs.js";
import { sort } from "../../../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { min, comparePrimitives, max, compare } from "../../../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { System_Array__$005B$005D$1_get_lastIndex, System_Array__$005B$005D$1_append_1505 } from "../../../../basic-types.fs.js";
import { sliceIndexes, System_Array__$005B$005D$1_slice_Z3227AE51 } from "./util.fs.js";
import { map, delay } from "../../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { SliceIntervalsPair } from "./match-result.fs.js";

export class Section extends Record {
    constructor(content, transcript, contentCues, audioRegions, startTime, endTime) {
        super();
        this.content = content;
        this.transcript = transcript;
        this.contentCues = contentCues;
        this.audioRegions = audioRegions;
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function Section$reflection() {
    return record_type("Section.Section", [], Section, () => [["content", ContentSpan$reflection()], ["transcript", TranscriptSpan$reflection()], ["contentCues", CuesSpan$reflection()], ["audioRegions", AudioRegionsSpan$reflection()], ["startTime", int32_type], ["endTime", int32_type]]);
}

export class ZipRun extends Record {
    constructor(globalPosition, intervals) {
        super();
        this.globalPosition = (globalPosition | 0);
        this.intervals = intervals;
    }
}

export function ZipRun$reflection() {
    return record_type("Section.ZipRun", [], ZipRun, () => [["globalPosition", int32_type], ["intervals", array_type(TimeInterval$reflection())]]);
}

export class SkipRun extends Record {
    constructor(globalPosition, skipCount, interval) {
        super();
        this.globalPosition = (globalPosition | 0);
        this.skipCount = (skipCount | 0);
        this.interval = interval;
    }
}

export function SkipRun$reflection() {
    return record_type("Section.SkipRun", [], SkipRun, () => [["globalPosition", int32_type], ["skipCount", int32_type], ["interval", TimeInterval$reflection()]]);
}

export function makeSubSection(section, startTime, endTime, contentSlice) {
    const transcriptSlice = computeTranscriptSliceByTime(section.transcript.data, startTime, endTime);
    const cuesSlice = computeCueFrameSliceByTime(section.contentCues.data, startTime, endTime);
    const newContentSpan = new ContentSpan(sliceFrame(section.content.data, contentSlice), startTime, endTime);
    const newTranscriptSpan = new TranscriptSpan(sliceFrame(section.transcript.data, transcriptSlice), startTime, endTime);
    const newCuesSpan = new CuesSpan(sliceFrame(section.contentCues.data, cuesSlice), startTime, endTime);
    const newAudioRegionsSpan = new AudioRegionsSpan(section.audioRegions.data, startTime, endTime);
    if (newContentSpan.data.word.length === 0) {
        debugger;
    }
    return new Section(newContentSpan, newTranscriptSpan, newCuesSpan, newAudioRegionsSpan, startTime, endTime);
}

export function makeZipRun(section, globalPosition, intervals) {
    return new ZipRun(globalPosition, intervals);
}

export function splitSectionWithSliceIntervalsPairs(section, pairs0) {
    let sectionStartTime = section.startTime | 0;
    let sectionStartIdx = 0;
    let sectionEndTime = 0;
    const pairs = sort(pairs0, {
        Compare: compare,
    });
    const sectionWordCount = ContentSpan__get_words(section.content).length | 0;
    const result = [];
    for (let idx = 0; idx <= (pairs.length - 1); idx++) {
        const forLoopVar = pairs[idx];
        const terminal = forLoopVar.terminal;
        const s = forLoopVar.contentSlice;
        const intervals = forLoopVar.intervals;
        const sliceStart = s[0] | 0;
        const sliceLast = s[1] | 0;
        const sectionEndIdx = (sliceStart - 1) | 0;
        sectionEndTime = intervals[0].startTime;
        if (sectionEndIdx >= sectionStartIdx) {
            System_Array__$005B$005D$1_append_1505(result, makeSubSection(section, sectionStartTime, sectionEndTime, [sectionStartIdx, sectionEndIdx]));
        }
        sectionStartIdx = (sliceLast + 1);
        sectionStartTime = intervals[System_Array__$005B$005D$1_get_lastIndex(intervals)].endTime;
        const sliceLen = ((sliceLast - sliceStart) + 1) | 0;
        if ((sliceLen === intervals.length) ? terminal : false) {
            const startGPos = System_Array__$005B$005D$1_slice_Z3227AE51(section.content.data.globalPosition, s)[0] | 0;
            System_Array__$005B$005D$1_append_1505(result, makeZipRun(section, startGPos, intervals));
        }
        else {
            System_Array__$005B$005D$1_append_1505(result, makeSubSection(section, intervals[0].startTime, intervals[0].endTime, s));
        }
    }
    if (sectionStartIdx < sectionWordCount) {
        sectionEndTime = section.endTime;
        System_Array__$005B$005D$1_append_1505(result, makeSubSection(section, sectionStartTime, sectionEndTime, [sectionStartIdx, null]));
    }
    return result;
}

export function sectionGetTranscriptWordInterval(section, i) {
    const startTime = section.transcript.data.startTime[i] | 0;
    const endTime = section.transcript.data.endTime[i] | 0;
    return new TimeInterval(max(comparePrimitives, startTime, section.startTime), min(comparePrimitives, endTime, section.endTime));
}

export function sectionGetTranscriptWordIntervalsSlice(section, slice) {
    return Array.from(delay(() => map((i) => sectionGetTranscriptWordInterval(section, i), sliceIndexes(slice[0], slice[1]))));
}

export function splitSectionWithSlicePairs(section, pairs) {
    const mapped = [];
    for (let idx = 0; idx <= (pairs.length - 1); idx++) {
        const r = pairs[idx];
        const intervals = sectionGetTranscriptWordIntervalsSlice(section, r.transcriptSlice);
        System_Array__$005B$005D$1_append_1505(mapped, new SliceIntervalsPair(r.contentSlice, intervals, true));
    }
    return splitSectionWithSliceIntervalsPairs(section, mapped);
}

export function splitSectionWithPositionPairs(section, pairs) {
    const mapped = [];
    for (let idx = 0; idx <= (pairs.length - 1); idx++) {
        const r = pairs[idx];
        const mappedPair = new SliceIntervalsPair([r.contentPos, r.contentPos], [sectionGetTranscriptWordInterval(section, r.transcriptPos)], true);
        System_Array__$005B$005D$1_append_1505(mapped, mappedPair);
    }
    return splitSectionWithSliceIntervalsPairs(section, mapped);
}

export function splitSectionWithGapPairs(section, pairs) {
    let startContentPos = 0;
    let endContentPos = 0;
    let startTime = section.startTime | 0;
    let endTime = 0;
    if (ContentSpan__get_words(section.content).length === 0) {
        return [];
    }
    const mapped = [];
    for (let idx = 0; idx <= (pairs.length - 1); idx++) {
        const pair = pairs[idx];
        const contentPos = pair.contentPos | 0;
        const gapInterval = pair.gapInterval;
        endContentPos = (contentPos - 1);
        endTime = gapInterval.startTime;
        if (endContentPos >= startContentPos) {
            const mappedPair = new SliceIntervalsPair([startContentPos, endContentPos], [new TimeInterval(startTime, endTime)], false);
            System_Array__$005B$005D$1_append_1505(mapped, mappedPair);
        }
        startTime = gapInterval.endTime;
        startContentPos = (endContentPos + 1);
    }
    endContentPos = (ContentSpan__get_words(section.content).length - 1);
    endTime = section.endTime;
    if (endContentPos >= startContentPos) {
        const mappedPair_1 = new SliceIntervalsPair([startContentPos, endContentPos], [new TimeInterval(startTime, endTime)], false);
        System_Array__$005B$005D$1_append_1505(mapped, mappedPair_1);
    }
    return splitSectionWithSliceIntervalsPairs(section, mapped);
}

export function splitSectionWithMatchResult(section, m) {
    const matchValue = m.matchInfo;
    switch (matchValue.tag) {
        case 0: {
            const pairs_1 = matchValue.fields[0];
            return splitSectionWithSlicePairs(section, pairs_1);
        }
        case 2: {
            const pairs_2 = matchValue.fields[0];
            return splitSectionWithPositionPairs(section, pairs_2);
        }
        case 1: {
            const pairs_3 = matchValue.fields[0];
            return splitSectionWithGapPairs(section, pairs_3);
        }
        default: {
            const pairs = matchValue.fields[0];
            return splitSectionWithSliceIntervalsPairs(section, pairs);
        }
    }
}


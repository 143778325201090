import { computed, makeObservable } from 'mobx';
import { strongNormalizeWordArray } from '../../../content-funcs';
import { ContentRootsBase } from '../../content-roots/content-roots';
import {
  AudioRegionsSpan,
  ContentSpan,
  CuesSpan,
  interleavedToStartsEnds,
  reconcileAudioIntervals,
  startsAndEndsToInterleaved,
  TranscriptSpan,
} from './core/spans.fs.js';
import { unionIntervals } from './core/util.fs.js';

export class TimestamperRoots {
  contentRoots: ContentRootsBase;
  startTime = 0;
  endTime = 0;

  constructor(contentRoots0: ContentRootsBase) {
    this.contentRoots = contentRoots0;
    const notchEnds = this.contentRoots.notchTimeIntervals.endPoints;
    this.endTime = notchEnds[notchEnds.length - 1];
    makeObservable(this);
  }

  @computed({ keepAlive: true })
  get contentSpan(): ContentSpan {
    const wordElements = this.contentRoots.words0.elements;
    const originalWords: string[] = wordElements.map(el => el['text']);
    const normalizedWords = strongNormalizeWordArray(originalWords);
    const globalPositions = [...normalizedWords.keys()];

    return new ContentSpan(
      {
        word: normalizedWords,
        globalPosition: globalPositions,
        originalWord: originalWords,
      },
      this.startTime,
      this.endTime
    );
  }

  get cuesSpan(): CuesSpan {
    const words = this.contentRoots.words0;
    const cues = this.contentRoots.chaatInputCues;
    const cueTimestamps = cues.map(cue => cue.timestamp);
    const cuePositions = cues.map(cue => words.getIndex(cue.wordId));
    return new CuesSpan(
      {
        globalPosition: cuePositions,
        timestamp: cueTimestamps,
      },
      this.startTime,
      this.endTime
    );
  }

  @computed({ keepAlive: true })
  get audioRegionsSpan(): AudioRegionsSpan {
    const regionIntervals = this.contentRoots.nonVoiceAudioRegionIntervals;
    const regionStarts = regionIntervals.startPoints;
    const regionEnds = regionIntervals.endPoints;
    const silenceIntervals = this.contentRoots.silenceTimeIntervals;
    const silenceStarts = silenceIntervals.startPoints;
    const silenceEnds = silenceIntervals.endPoints;
    const [mergedStarts, mergedEnds] = unionIntervals(
      silenceStarts,
      silenceEnds,
      regionStarts,
      regionEnds
    );
    const timestamps = startsAndEndsToInterleaved(mergedStarts, mergedEnds, false, 0);
    // TODO can this be different from mergedStarts/Ends??
    const noVoiceStarts: number[] = [];
    for (let i = 0; i < timestamps.length; i = i + 2) {
      noVoiceStarts.push(timestamps[i]);
    }
    const noVoiceEnds: number[] = [];
    for (let i = 1; i < timestamps.length; i = i + 2) {
      noVoiceEnds.push(timestamps[i]);
    }

    return new AudioRegionsSpan(
      {
        timestamp: timestamps,
        silenceStartTime: noVoiceStarts,
        silenceEndTime: noVoiceEnds,
      },
      this.startTime,
      this.endTime
    );
  }

  @computed({ keepAlive: true })
  get transcriptSpan(): TranscriptSpan {
    const originalWords = this.contentRoots.transcriptWords; // TODO this is actually not the raw transcript words need to save raw data
    const normalizedWords = strongNormalizeWordArray(originalWords);
    const rawTranscriptIntervals = this.contentRoots.transcriptWordTimeIntervals; // TODO this is actually not raw need to save raw!!!
    const rawTranscriptStarts = rawTranscriptIntervals.startPoints;
    const rawTranscriptEnds = rawTranscriptIntervals.endPoints;

    // TODO feel this is wrong result format does not include 0 and endTime???
    const transcriptInterleavedTimes = startsAndEndsToInterleaved(
      rawTranscriptStarts,
      rawTranscriptEnds,
      true,
      this.endTime
    );

    // TODO to duplicating existing logic applying reconcile for silence and regions in succesion, don't know if makes any difference
    const regionIntervals = this.contentRoots.nonVoiceAudioRegionIntervals;
    const regionStarts = regionIntervals.startPoints;
    const regionEnds = regionIntervals.endPoints;
    const notchIntervals = this.contentRoots.notchTimeIntervals;
    const notchStarts = notchIntervals.startPoints;
    const notchEnds = notchIntervals.endPoints;

    const conflict = (idx: number) => !!(idx % 2);
    reconcileAudioIntervals(transcriptInterleavedTimes, notchStarts, notchEnds, conflict);
    reconcileAudioIntervals(transcriptInterleavedTimes, regionStarts, regionEnds, conflict);
    const [adjustedStarts, adjustedEnds] = interleavedToStartsEnds(
      transcriptInterleavedTimes,
      true
    );

    return new TranscriptSpan(
      {
        word: normalizedWords,
        originalWord: originalWords,
        startTime: adjustedStarts,
        endTime: adjustedEnds,
      },
      this.startTime,
      this.endTime
    );
  }
}

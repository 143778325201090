// open BasicTypes
// open CueActions
// open AppRoot

import { alertMessages, chaatToolModel, mutationActions, transportState } from '../app-root';
import { Cue, ElementId } from '../masala-lib/basic-types';
import { CueActions } from '../masala-lib/editorial/models/actions/cue-actions';

// type CueActions1() =
export class ChaatCueActions extends CueActions {
  get mutationActions() {
    return mutationActions;
  }
  get alertMessages() {
    return alertMessages;
  }
  get cueInsertWordId() {
    return chaatToolModel.currentCuePointWordId;
  }
  set cueInsertWordId(id: ElementId) {
    chaatToolModel.currentCuePointWordId = id;
  }
  get currentAudioPosition() {
    return transportState.audioPosition;
  }
  get existingCues(): Cue[] {
    // TODO work on typing
    return <Cue[]>chaatToolModel.cuesData;
  }
  get words() {
    return chaatToolModel.words;
  }
}

// let CueActions():ICueActions= !< CueActions1()

import { elementIdToDomId } from './masala-lib/basic-types';
import { chaatToolModel } from './app-root';

export function chaatRenderWordRange(styleLayers, wordElements, indexRange) {
  let html = '';
  const words = wordElements.elements;
  const wordTracker = chaatToolModel.wordTracker;
  for (let i = indexRange.starts; i <= indexRange.ends; i++) {
    const word = words[i];
    const wordId = word.id;
    const domId = elementIdToDomId('', wordId);
    let wordStyles =
      styleLayers.getStyleForWordAddress(i) + ' ' + styleLayers.getStyleForElement(wordId);
    if (wordTracker.isUnder(wordId)) {
      wordStyles += ' cursor-is-under';
    }
    html = html + `<span class="word ${wordStyles}" id="${domId}">${word.text} </span>`;
  }
  return html;
}

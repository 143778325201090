import { Alert } from '../../../alert-messages';
import { Element, ElementId, elementIdToDomId, isNil } from '../../../basic-types';
import { AdhocWordRange, isWordRange } from '../../../elements/ad-hoc-word-range';
import { ElementList } from '../../../elements/element-list';

export function renderStylesForDomId(domId: string, styles: string[], remove: boolean) {
  const node = document.getElementById(domId);
  if (node) {
    if (remove) {
      node.classList.remove(...styles);
    } else {
      node.classList.add(...styles);
    }
  }
}

// TODO Enum for add/remove??
export function renderStylesForElement(
  element: Element,
  styles: string[],
  elementList: ElementList,
  domScope,
  remove: boolean
) {
  if (isWordRange(element)) {
    const adhocWordRange = element as unknown as AdhocWordRange;
    const range = adhocWordRange.range;
    const wordIds = elementList.words.idRangeAsIds(range);
    for (const wordId of wordIds) {
      const domId = elementIdToDomId(domScope, wordId);
      renderStylesForDomId(domId, styles, remove);
    }
  } else {
    const domId = elementIdToDomId(domScope, element.id);
    renderStylesForDomId(domId, styles, remove);
  }
}

export function simpleRenderStylesForElementId(id: ElementId, styles: string[], remove: boolean) {
  const domId = elementIdToDomId('', id);
  renderStylesForDomId(domId, styles, remove);
}

export function renderStylesForElementList(
  elementList: ElementList,
  styles: string[],
  domScope,
  remove: boolean
) {
  for (const element of elementList.elements) {
    renderStylesForElement(element, styles, elementList, domScope, remove);
  }
}

export type StyleLayer = {
  styles: string[];
  elements: ElementList;
  domScope: string; // TODO use? OR MAYBE NEEDS TO BE FUNCTION id -> dom id to handle mapping to correct sentence element etc???
  supportWordRanges: boolean;
  stylesString: string;
  // }
};

export function computeStylesStringForLayer(layer: StyleLayer) {
  if (!layer.stylesString) {
    layer.stylesString = layer.styles.join(' ');
  }
  return layer.stylesString;
}

export class StyleLayersRenderer {
  episodeKey = '';
  domScope: string = ''; // TODO
  layers: Map<string, StyleLayer> = new Map();
  layerStack: StyleLayer[] = [];

  getStyleForWordAddress(index) {
    let style = '';
    for (const layer of this.layerStack) {
      if (layer.supportWordRanges) {
        const found = layer.elements.getElementContainingWordAddress(index);
        if (found && isWordRange(found)) {
          style += ' ' + computeStylesStringForLayer(layer);
        }
      }
    }
    return style;
  }

  getStyleForElement(id: ElementId) {
    const styles: string[] = [];
    for (const layer of this.layerStack) {
      if (layer.elements.hasElement(id)) {
        styles.push(...layer.styles);
      }
    }
    return styles.join(' ');
  }

  setStyleLayers(layers0: Map<string, StyleLayer>) {
    this.layers = layers0;
    // compute layer stack
    this.layerStack = [...layers0.values()];
  }

  renderStyleLayers(episodeKey0: string, layers0: Map<string, StyleLayer>) {
    // TODO change name renderStyleOverlays??
    // DOM manipulation is done imperatively in this function nothing is driven by observable
    // if episodeKey same current (otherwise style changes and expressed by html rendering not DOM manipulation)
    if (this.episodeKey === episodeKey0) {
      // for key in layers do
      for (const key of layers0.keys()) {
        // get the old and new layers for key
        const newOverlay = layers0.get(key);
        const oldOverlay = this.layers.get(key);
        // compute differences in both directions using elementlist difference
        // TODO difference is minus here, use .minus instead of .difference?
        // TODO figure out what the bool param is
        if (isNil(oldOverlay)) {
          renderStylesForElementList(newOverlay.elements, newOverlay.styles, null, false);
        } else {
          const addedElements = newOverlay.elements.difference(oldOverlay.elements);
          const removedElements = oldOverlay.elements.difference(newOverlay.elements);
          renderStylesForElementList(removedElements, newOverlay.styles, null, true);
          renderStylesForElementList(addedElements, newOverlay.styles, null, false);
        }
      }
    }

    this.episodeKey = episodeKey0;
    this.layers = layers0;
    // compute layer stack
    this.layerStack = [...layers0.values()];
  }
}

import { runInAction } from 'mobx';
import { ContentRootsBase } from '../../content-roots/content-roots';
import { ChaatLoader } from '../../db/chaat-loader';
import { updateChaatOutputs } from '../../db/mutation-actions';
import { Timestamper } from './core/timestamper.fs.js';
import { TimestamperRoots } from './timestamper-roots';

export async function runTimestampingWithContentRoots(roots: ContentRootsBase) {
  const timestamperRoots = new TimestamperRoots(roots);
  const timestamper = Timestamper();
  timestamper.contentSpan = timestamperRoots.contentSpan;
  timestamper.transcriptSpan = timestamperRoots.transcriptSpan;
  timestamper.cuesSpan = timestamperRoots.cuesSpan;
  timestamper.audioRegionsSpan = timestamperRoots.audioRegionsSpan;
  timestamper.startTime = timestamperRoots.startTime;
  timestamper.endTime = timestamperRoots.endTime;
  timestamper.language = roots.audioLanguage;

  timestamper.run();
  const result = timestamper.getResult();
  // TODO expose these as typed values on ContentRoots?
  const scriptTimestamp: any = roots.verbatimDoc.timestamp;
  const cuesTimestamp: any = roots.cuesDoc.timestamp;
  const regionsTimestamp: any = roots.audioRegionsDoc.timestamp;

  // TODO update transcript time intervals if changed
  await updateChaatOutputs(
    roots.episodeKey,
    result,
    scriptTimestamp,
    cuesTimestamp,
    regionsTimestamp,
    null,
    null
  );
}

export async function runTimestamping(episodeKey: string) {
  const loader = new ChaatLoader();
  loader.loadEpisode(episodeKey, false);
  const loaded = loader.promiseWhenComplete();

  const doneLoading = await loaded;
  const roots = new ContentRootsBase();
  runInAction(() => {
    roots.episodeKey = episodeKey;
    loader.docSet.copyTo(roots);
  });
  const result = await runTimestampingWithContentRoots(roots);
  return result;
}

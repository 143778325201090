import { TrackGadget } from './track-gadget';
import { makeObservable, observable, reaction } from 'mobx';

export class WaveformTrackGadget extends TrackGadget {
  constructor() {
    super();
    this.notifyTimeSelect = 0;
    this.lastTimeSelect = -1;
    this.waveformData = null;
    this.fillStyle = '#000000';
    makeObservable(this, {
      notifyTimeSelect: observable,
    });
  }

  onTimeSelect(f) {
    this.disposers.push(reaction(() => this.notifyTimeSelect, f));
  }

  handleMouseClickAtTime(x, y, time) {
    this.lastTimeSelect = time;
    this.notifyTimeSelect++;
    return true;
  }

  setWaveformData(waveformData) {
    this.waveformData = waveformData;
    this.requestFullRedraw();
  }

  drawForegroundLayer(ctx) {}

  drawBackgroundLayer(ctx) {
    if (!this.waveformData) {
      return;
    }
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    const sampleDurationMS = 10;
    const startTime = this.canvasStartTime;
    const startSample = Math.round(startTime / sampleDurationMS);
    const numSamples = Math.round(this.canvasTimeExtent / sampleDurationMS);
    for (let i = 0; i < numSamples; i++) {
      const sampleValue = this.waveformData[startSample + i];
      const sampleTime = startTime + sampleDurationMS * i;
      // TODO unhardcode amplitude scaling
      this.drawTimerangeRect(
        ctx,
        { starts: sampleTime, ends: sampleTime + sampleDurationMS },
        (this.height * sampleValue) / 80000
      );
    }
    ctx.restore();
  }
}

import { AudioMarkerDTO, NO_INDEX } from '../../../basic-types';
import { Sorted } from '../../../sorted/sorted';
import { MutationActions } from '../../db/mutation-actions.js';

// [<AbstractClass>]
export abstract class AudioMarkerActions {
  getTargetMarker(): AudioMarkerDTO {
    const audioPos = this.currentAudioPosition;
    const markerHitIntervals = this.currentAudioMarkerHitIntervals;
    const targetMarkerIndex = markerHitIntervals.containing(audioPos);
    if (targetMarkerIndex !== NO_INDEX) {
      return this.currentAudioMarkers[targetMarkerIndex];
    } else {
      return null;
    }
  }

  addUpdate(data: AudioMarkerDTO) {
    this.mutationActions.addUpdateChaatAudioMarker(data);
  }

  remove(id) {
    this.mutationActions.removeChaatAudioMarker(id);
  }

  addUpdateFromAudioPosition() {
    const existingMarker = this.getTargetMarker();
    if (!existingMarker) {
      this.addUpdate({ id: null, time: this.currentAudioPosition });
    } else {
    }
  }

  removeFromAudioPosition() {
    const targetMarker = this.getTargetMarker();
    if (targetMarker) {
      // TODO alert when not in a marker hit region?
      this.remove(targetMarker.id);
    } else {
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get currentAudioPosition(): number;
  abstract get currentAudioMarkers(): any[]; // TODO typing
  abstract get currentAudioMarkerHitIntervals(): Sorted;
}

import { IObservableValue, observable } from 'mobx';
import { isNull } from '../basic-types';

export class Signal {
  value = 0;
  boxed: IObservableValue<number> = null;
  fastReactions: (() => void)[] = [];

  doFastReactions() {
    for (const callback of this.fastReactions) {
      callback();
    }
  }

  set(aValue: number) {
    this.value = aValue;
    this.doFastReactions();
    if (this.boxed) {
      this.boxed.set(aValue);
    }
  }

  watch(): number {
    if (isNull(this.boxed)) {
      this.boxed = observable.box(this.value);
    }
    return this.boxed.get();
  }

  addFastReaction(f: () => void) {
    this.fastReactions.push(f);
  }
}

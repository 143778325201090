import { makeObservable, observable } from 'mobx';

export enum AlertLevel {
  Info = 1,
  Warning1 = 2,
  Warning2 = 3,
}

type AlertMessage = {
  text: string;
  timestamp: number;
  forceAcknowlege: boolean;
  level: AlertLevel;
  // }
};

export const Alert = {
  text: '',
  timestamp: 0,
  forceAcknowlege: false,
  level: AlertLevel.Warning1,
  // }
};

export class AlertMessages {
  keepDuration: number = 4000; // TODO make configurable on new instance?
  @observable.ref currentMessages: AlertMessage[] = [];

  constructor() {
    makeObservable(this);
  }

  clearExpiredMessages() {
    const expiredTime = Date.now() - this.keepDuration;
    this.currentMessages = this.currentMessages.filter(m => m.timestamp > expiredTime);
  }

  add(message0: AlertMessage) {
    let message = { ...message0, timestamp: Date.now() };
    if (message.level === AlertLevel.Warning2) {
      message = { ...message, forceAcknowlege: true };
    }

    this.currentMessages = [...this.currentMessages, message];
    setTimeout(() => this.clearExpiredMessages(), this.keepDuration + 200);
  }
}

import React from 'react';
import styled from 'styled-components';

// TODO move all styles to App.css?
const Container = styled.div`
  font-family: 'Roboto';
  border-right: 1px solid #eee;
  min-height: 100vh;
  padding-bottom: 200px;
  background: #f1f6f6;
  background: #e7f0f2;
  background: #e4f3f5;
  background: #f8f8f8;
  background: #fcfcfc;
  /* background: #e9f3f3; */
`;

export const Sidebar = ({ episode }) => (
  <Container>
    <div />
  </Container>
);

export class WrappedAudioElement {
  constructor(src) {
    this.src = src;
    this.audio = this.src ? new Audio(this.src) : new Audio();
    this.audio.onerror = () => this.resetAndPlay();
  }

  printDebug() {
    console.log('src: ' + this.audio.src);
    console.log('muted: ' + this.audio.muted);
    console.log('volume: ' + this.audio.volume);
    console.log('ended: ' + this.audio.ended);
    console.log('seekable: ' + this.audio.seekable);
    console.log('duration: ' + this.audio.duration);
    console.log('currentTime: ' + this.audio.currentTime);
    console.log('readyState: ' + this.audio.readyState);
    console.log('networkState: ' + this.audio.networkState);
    console.log('error...');
    console.log(this.audio.error);
  }

  resetAndPlay() {
    if (this.audio.error && (this.audio.error.code === 2 || this.audio.error.code === 3)) {
      const currentTime = this.audio.currentTime;
      const volume = this.audio.volume;
      const playbackRate = this.audio.playbackRate;
      this.audio = new Audio(this.audio.src);
      this.audio.currentTime = currentTime;
      this.audio.volume = volume;
      this.audio.playbackRate = playbackRate;
      this.audio.onerror = () => this.resetAndPlay();
      this.audio.play();
      console.log('*** RESET AUDIO');
      console.log('RESET AUDIO');
      console.log('RESET AUDIO ***');
    }
  }

  play() {
    // this.printDebug();
    return this.audio.play();
  }

  pause() {
    return this.audio.pause();
  }

  addEventListener(key, f) {
    return this.audio.addEventListener(key, f);
  }

  get currentTime() {
    return this.audio.currentTime;
  }

  set currentTime(val) {
    this.audio.currentTime = val;
  }

  get playbackRate() {
    return this.audio.playbackRate;
  }

  set playbackRate(val) {
    this.audio.playbackRate = val;
  }

  get duration() {
    return this.audio.duration;
  }

  get volume() {
    return this.audio.volume;
  }

  set volume(val) {
    this.audio.volume = val;
  }
}

import { observable, computed, makeObservable } from 'mobx';
import {
  ChaatInputCue,
  Element,
  ElementId,
  IdRange,
  IndexMapping,
  isNull,
  numberProjectionSort,
  SpanAnchors,
  SpanExclusiveAnchors,
  StructuralAnchor,
} from '../../basic-types';
import { getSentenceWordIdRange } from '../../content-funcs';
import { makeAdhocWordRange } from '../../elements/ad-hoc-word-range';
import { ElementList } from '../../elements/element-list';
import { sortElements } from '../../elements/element-sort';
import { Precedence } from '../../elements/precedence';
import { fromIntervals, size, Sorted } from '../../sorted/sorted';
import { getIndex, isDeletedId } from '../ids/positional-ids';

// // TODO move and create more string utilities
// [<Emit("$0.indexOf($1)")>]

function mapSentenceAnchorsToWordAddresses(sentences: Element[], words: ElementList) {
  for (const sentence of sentences) {
    const sentenceAnchors = <SpanExclusiveAnchors>sentence.anchors;
    sentence.wordAddress = words.getIndex(sentenceAnchors.startWordId);
    sentence.endWordAddress = words.getIndex(sentenceAnchors.endWordIdExclusive) - 1;
  }
}

function mapStructuralAnchorsToWordAddresses(
  structural: Element[],
  words: ElementList,
  sentenceIntervals: Sorted
) {
  // TODO needs to use sentence intervals
  for (const element of structural) {
    const wordId = <StructuralAnchor>element.anchors;
    element.wordAddress = words.getIndex(wordId);
  }
}

function mapWordGroupAnchorsToWordAddresses(wordGroups: Element[], wordIdMapping: IndexMapping) {
  for (const wordGroup of wordGroups) {
    const wordGroupAnchors = <SpanAnchors>wordGroup.anchors;
    wordGroup.wordAddress = getIndex(wordIdMapping, wordGroupAnchors.startWordId);
    const endWordId = wordGroupAnchors.endWordId;
    let index0 = getIndex(wordIdMapping, endWordId);
    if (isDeletedId(wordIdMapping, endWordId) && index0 > 0) {
      index0--;
    }
    wordGroup.endWordAddress = index0;
  }
}

function partitionOrphanedWordGroups(wordGroups: Element[]) {
  const nonOrphaned = [];
  const orphaned = [];
  for (const g of wordGroups) {
    if (g.wordAddress > g.endWordAddress) {
      orphaned.push(g);
    } else {
      nonOrphaned.push(g);
    }
  }
  return { orphaned, nonOrphaned };
}

function mapElementsWordAddressesToTimes(elements: Element[], words: Element[]) {
  for (const elem of elements) {
    elem.time = words[elem.wordAddress].time;
    elem.endTime = words[elem.endWordAddress].endTime;
  }
  return elements;
}

function getElementsTimestampingSignature(elements: Element[]) {
  let result = '';
  for (const element of elements) {
    result += element.id + '_' + element.time;
  }
  return result;
}

export function getSentenceTimestampingSignature(sentence: Element, words: ElementList) {
  const wordRange = getSentenceWordIdRange(sentence, words);
  // TODO optimize, put some more efficient method on element list to get element array from IdRange?/
  const sentenceWords = words.idRangeAsElements(wordRange);
  return getElementsTimestampingSignature(sentenceWords);
}

export function translationId(id: ElementId, translationLanguage: string): ElementId {
  // TODO don't use forward slash
  return id + '/' + translationLanguage;
}

// TODO direction Enum
export function interleave(
  elements: Element[],
  lookup: (id: ElementId) => Element,
  direction: number
) {
  const result = [];
  for (const el of elements) {
    const found = lookup(el.id);
    if (found) {
      if (direction === -1) {
        result.push(found);
        result.push(el);
      } else {
        result.push(el);
        result.push(found);
      }
    } else {
      result.push(el);
    }
  }
  return result;
}

// TODO move?
export const metadataOrder = new Precedence(['METADATA-URL', 'NOTES', 'ASSET-LINKS', 'CAST']);

// TODO needed?
const languageCodeToLanguage = {
  'en-US': 'english',
  'es-US': 'spanish',
  'de-DE': 'german',
  'pt-BR': 'portuguese',
};

function cuedWordsFromCues(cues: ChaatInputCue[], words: ElementList): ElementList {
  const wordIds = cues.map(cue => cue.wordId);
  return words.fromIds(wordIds);
}

function segmentStopWordsFromWords(words: ElementList): ElementList {
  const allGaps = words.timeIntervals.fromGapIntervals(-1).asIntervals();
  let lastUsedGapEnd = 0;
  const wordIndexes = [];
  for (const [index, gap] of allGaps.entries()) {
    const gapSize = gap.ends - gap.starts;
    if (gapSize >= 30) {
      if (gap.starts - lastUsedGapEnd < 1500 && gapSize < 225) {
      } else {
        wordIndexes.push(index);
        lastUsedGapEnd = gap.ends;
      }
    }
  }
  return words.fromIndexes(wordIndexes);
}

function wordIdRangesFromTimeIntervals(intervals: Sorted, words: ElementList) {
  const wordTimeIntervals = words.timeIntervals;
  const indexRanges = wordTimeIntervals.mapRangesContained(intervals.asIntervals());
  const resultElements: Element[] = [];
  for (const range of indexRanges) {
    if (range) {
      const idRange = words.indexRangeToIdRange(range);
      resultElements.push(makeAdhocWordRange(idRange, words));
    } else {
      resultElements.push(null);
    }
  }
  return resultElements;
}

function filterDeleted(elements: Element[]): Element[] {
  const results = [];
  for (const element of elements) {
    if (!element['deleted']) {
      results.push(element);
    }
  }
  return results;
}

function splitWarningElementsByWarningType(
  idRanges: IdRange[],
  warningData: any[],
  words: ElementList
) {
  const minorWarningRanges = [];
  const majorWarningRanges = [];
  for (const [index, range] of idRanges.entries()) {
    if (range) {
      if (warningData[index] === 'silence') {
        majorWarningRanges.push(range);
      } else {
        minorWarningRanges.push(range);
      }
    }
  }
  return {
    minorWarnings: new ElementList(minorWarningRanges, null, null, words, null, null, null),
    majorWarnings: new ElementList(majorWarningRanges, null, null, words, null, null, null),
  };
}

export class ContentRootsBase {
  @observable.ref episodeMetadataDoc = null;

  @observable.ref verbatimDoc = null;

  @observable.ref structuralDoc = null;

  @observable.ref wordGroupsDoc = null;

  @observable.ref translationsDoc = null;

  @observable.ref metadataBlocksDoc = null;

  @observable.ref warningSuppressionsDoc = null;

  @observable.ref episodeKey = '';

  audioLanguageCode = 'es-US'; // TODO get from the chaat metadata doc

  @observable.ref timestampsDoc = null;

  @observable.ref cuesDoc = null;

  @observable.ref speechTranscriptDoc = null;

  @observable.ref audioAnalysisDoc = null;

  @observable.ref audioRegionsDoc = null;

  @observable.ref audioMarkersDoc = null;

  @observable.ref chaatMetadataDoc = null;

  @observable.ref audioProcessingJobDoc = null;

  @observable.ref transcriptionJobDoc = null;

  @observable.ref chaatSignoffsDoc = null;

  constructor() {
    makeObservable(this);
  }

  get wordIdMapping(): IndexMapping {
    return this.verbatimDoc.wordIdMapping;
  }

  get editEnabled() {
    return this.episodeMetadataDoc.editEnabled;
  }

  // TODO as these are not keep alive make sure words0/words1 are only accessed in workable contexts
  @computed
  get words0(): ElementList {
    // TODO change wordIdMapping to wordIndexMapping everywhere
    const mapping = this.wordIdMapping;
    return new ElementList(this.verbatimDoc.words, this.episodeKey, null, null, mapping, null, id =>
      getIndex(mapping, id)
    );
    // TODO seems like wordAddress is not mapped on word objects, need it?
  }

  @computed
  get words1(): ElementList {
    const wordIdMapping: IndexMapping = this.verbatimDoc.wordIdMapping;
    const words: Element[] = this.verbatimDoc.words;
    const startTimes: number[] = this.timestampsDoc.wordTimeIntervals.startTimes;
    const endTimes: number[] = this.timestampsDoc.wordTimeIntervals.endTimes;
    for (let i = 0; i <= words.length - 1; i++) {
      const word = words[i];
      word.time = startTimes[i];
      word.endTime = endTimes[i];
    }
    return new ElementList(words, this.episodeKey, null, null, wordIdMapping, null, id =>
      getIndex(wordIdMapping, id)
    );
  }

  get audioLanguage(): string {
    return 'spanish'; // TODO lookup with audio language code
  }

  @computed
  get sentences0() {
    const sentences: Element[] = Object.values(this.verbatimDoc.sentences);
    const activeSentences = filterDeleted(sentences);
    mapSentenceAnchorsToWordAddresses(activeSentences, this.words0);
    return activeSentences;
  }

  @computed
  get sentences1() {
    return mapElementsWordAddressesToTimes(this.sentences0, this.words1.elements);
  }

  @computed
  get sentencesList0() {
    const sentences: Element[] = [...this.sentences0];
    sortElements(sentences);
    return new ElementList(sentences, this.episodeKey, null, null, null, null, null);
  }

  @computed
  get structural0() {
    // TODO but null test conditional in other x0 funcs?
    if (isNull(this.structuralDoc)) {
      return [];
    } else {
      const structural: Element[] = Object.values(this.structuralDoc.structural);
      const activeStructural = filterDeleted(structural);
      mapStructuralAnchorsToWordAddresses(
        activeStructural,
        this.words0,
        this.sentencesList0.wordIntervals
      );
      return activeStructural;
    }
  }

  @computed
  get orphanedAndNonOrphanedWordGroups() {
    const wordGroups: Element[] = Object.values(this.wordGroupsDoc.wordGroups);
    const activeWordGroups = filterDeleted(wordGroups);
    mapWordGroupAnchorsToWordAddresses(activeWordGroups, this.wordIdMapping);
    return partitionOrphanedWordGroups(activeWordGroups);
  }

  get orphanedWordGroups(): Element[] {
    return this.orphanedAndNonOrphanedWordGroups.orphaned;
  }

  get wordGroups0(): Element[] {
    return this.orphanedAndNonOrphanedWordGroups.nonOrphaned;
  }

  @computed
  get wordGroups1(): Element[] {
    // TODO put time data on word groups
    return this.wordGroups0;
  }

  get translationLanguage(): string {
    return this.episodeMetadataDoc.learnersLanguage;
  }

  @computed
  get translations0() {
    const result = this.translationsDoc.translations[this.translationLanguage];
    return result ?? {};
  }

  @computed
  get metadataBlocks0(): Element[] {
    // TODO presort?
    return Object.values(this.metadataBlocksDoc.metadata);
  }

  @computed({ keepAlive: true })
  get warningSuppressions(): Set<ElementId> {
    if (isNull(this.warningSuppressionsDoc)) {
      return new Set([]);
    } else {
      return new Set(this.warningSuppressionsDoc.suppressions);
    }
  }

  @computed({ keepAlive: true })
  get chaatInputCues(): ChaatInputCue[] {
    // TODO includes all cues not just Chaat input type
    const result: ChaatInputCue[] = <ChaatInputCue[]>[...Object.values(this.cuesDoc.cues)];
    numberProjectionSort(result, (cue: ChaatInputCue) => cue.timestamp);
    return result;
  }

  @computed({ keepAlive: true })
  get cuedWords(): ElementList {
    return cuedWordsFromCues(this.chaatInputCues, this.words1);
  }

  @computed({ keepAlive: true })
  get cueTimestamps(): number[] {
    return this.chaatInputCues.map(cue => cue.timestamp);
  }

  @computed({ keepAlive: true })
  get cueDisplayTimeIntervals(): Sorted {
    const timestamps = this.cueTimestamps;
    const startPoints = timestamps.map(t => t - 2);
    const endPoints = timestamps.map(t => t + 10);
    return new Sorted(startPoints, endPoints);
  }

  @computed({ keepAlive: true })
  get segmentTimeIntervals(): Sorted {
    return this.words1.timeIntervals.fromGapIntervals(20);
  }

  @computed({ keepAlive: true })
  get segmentStopWords(): ElementList {
    return segmentStopWordsFromWords(this.words1);
  }

  @computed({ keepAlive: true })
  get notchTimeIntervals(): Sorted {
    const intervals = this.audioAnalysisDoc.notchTimeIntervals;
    return new Sorted(intervals.startTimes, intervals.endTimes);
  }

  @computed({ keepAlive: true })
  get silenceTimeIntervals(): Sorted {
    const notches = this.notchTimeIntervals;
    const silences = [];
    for (const interval of notches.asIntervals()) {
      if (size(interval) >= 125) {
        silences.push(interval);
      }
    }
    return fromIntervals(silences);
  }

  @computed({ keepAlive: true })
  get nonVoiceAudioRegions(): any[] {
    const audioRegions = [...Object.values(this.audioRegionsDoc.regions)];
    numberProjectionSort(audioRegions, r => r.startTime);
    return audioRegions;
  }

  @computed({ keepAlive: true })
  get nonVoiceAudioRegionIntervals(): Sorted {
    const intervals = this.nonVoiceAudioRegions.map(r => {
      return { starts: r.startTime, ends: r.endTime };
    });
    // TODO figure out how to do access qualified by Sorted -> Sorted.fromIntervals(..)
    return fromIntervals(intervals);
  }

  @computed({ keepAlive: true })
  get audioMarkers(): any[] {
    const markers = [...Object.values(this.audioMarkersDoc.markers)];
    numberProjectionSort(markers, m => m.time);
    return markers;
  }

  @computed({ keepAlive: true })
  get audioMarkerHitIntervals(): Sorted {
    const intervals = this.audioMarkers.map(m => {
      return { starts: m.time, ends: m.time + 35 };
    });
    return fromIntervals(intervals);
  }

  @computed({ keepAlive: true })
  get interpolatedTimeIntervals(): Sorted {
    // TODO consistent naming
    const interpolatedIntervals0 = this.timestampsDoc.interpolationTimeIntervals;
    return new Sorted(interpolatedIntervals0.startTimes, interpolatedIntervals0.endTimes);
  }

  @computed({ keepAlive: true })
  get warningTimeIntervals(): Sorted {
    const warningIntervals0 = this.timestampsDoc.warningTimeIntervals;
    return new Sorted(warningIntervals0.startTimes, warningIntervals0.endTimes);
  }

  @computed({ keepAlive: true })
  get sentenceTimestampingSignatures() {
    const words = this.words1;
    const sentences = this.sentences1;
    const result = {};
    for (const sentence of sentences) {
      result[sentence.id] = getSentenceTimestampingSignature(sentence, words);
    }
    return result;
  }

  @computed({ keepAlive: true })
  get chaatSignoffs(): Set<ElementId> {
    if (isNull(this.chaatSignoffsDoc)) {
      return new Set([]);
    } else {
      return new Set(this.chaatSignoffsDoc.signoffs);
    }
  }

  @computed({ keepAlive: true })
  get chaatUnsignedoffSentences(): ElementList {
    const result = [];
    const sentences = this.sentences1;
    const signatures = this.sentenceTimestampingSignatures;
    const signoffs = this.chaatSignoffs;
    for (const sentence of sentences) {
      const signature: string = signatures[sentence.id];
      if (!signoffs.has(signature)) {
        result.push(sentence);
      }
    }
    return new ElementList(result, this.episodeKey, null, this.words1, null, null, null);
  }

  get warningData(): string[] {
    return this.timestampsDoc.warningData;
  }

  @computed({ keepAlive: true })
  get warnings(): ElementList {
    const warningElements = wordIdRangesFromTimeIntervals(this.warningTimeIntervals, this.words1);
    const warningData: string[] = this.timestampsDoc.warningData;
    const matchedWarningElements = [];
    for (let i = 0; i < warningElements.length - 1; i++) {
      const warningElement = warningElements[i];
      if (warningElement) {
        const data = warningData[i];
        const subKind = data === 'silences' ? 'MAJOR' : 'MINOR';
        warningElement.subKind = subKind;
        matchedWarningElements.push(warningElement);
      }
    }

    return new ElementList(
      matchedWarningElements,
      this.episodeKey,
      null,
      this.words1,
      null,
      null,
      null
    );
  }

  @computed({ keepAlive: true })
  get majorWarnings(): ElementList {
    // TODO use subkind filter?
    return this.warnings.filter(e => e.subKind === 'MAJOR');
  }

  @computed({ keepAlive: true })
  get minorWarnings(): ElementList {
    return this.warnings.filter(e => e.subKind === 'MINOR');
  }

  @computed({ keepAlive: true })
  get warningSentenceIds(): ElementId[] {
    const warnIntervals = this.warningTimeIntervals;
    const result = [];
    for (const s of this.sentences1) {
      if (warnIntervals.hasIntersecting(s.time, s.endTime)) {
        result.push(s.id);
      }
    }
    return result;
  }

  @computed({ keepAlive: true })
  get transcriptWords(): string[] {
    return this.speechTranscriptDoc.transcriptWords;
  }

  @computed({ keepAlive: true })
  get transcriptWordTimeIntervals(): Sorted {
    const intervals = this.speechTranscriptDoc.transcriptWordTimeIntervals;
    return new Sorted(intervals.startTimes, intervals.endTimes);
  }

  get audioUrls() {
    const audioStorageId = this.audioProcessingJobDoc.m16000AudioId;
    // TODO put base url to cloud storage bucket somewhere else
    const downsampledAudioURL = `https://storage.googleapis.com/jw-timestamper/${audioStorageId}`;

    return {
      audioUrl: this.chaatMetadataDoc.finalAudioUrl,
      transcribeAudioUrl: downsampledAudioURL,
      noMusicAudioUrl: this.chaatMetadataDoc.audioNoMusicUrl,
    };
  }
}

import { reaction, runInAction } from 'mobx';
import { ElementId, isNull, NO_INDEX } from '../basic-types';
import { ElementList, EmptyElementList } from '../elements/element-list';
import { EmptySorted, Sorted } from '../sorted/sorted';
import { Signal } from './signal';
import {
  clearChangeRecords,
  currentIsUnder,
  isBefore,
  isUnder,
  isVisited,
  recordChangesForNewPosition,
  refreshIntervals,
  TrackingState,
} from './tracking-engine';

class SignalArr {
  signals: Signal[] = null;
  // }
}

export class Tracker {
  triggerFunction: () => any;
  positionFunction: () => number;
  disposers: (() => void)[];
  elements: ElementList;
  sorted: Sorted;
  trackingState: TrackingState;
  isUnderListeners: Set<(el: ElementId) => void>;
  isBeforeListeners: Set<(el: ElementId) => void>;
  isVisitedListeners: Set<(el: ElementId) => void>;
  isUnderSignals: SignalArr;
  isBeforeSignals: SignalArr;
  isVisitedSignals: SignalArr;
  changeSignals: SignalArr;
  anyIsChanged0: Signal;
  constructor(triggerFunction0: () => any, positionFunction0: () => number) {
    this.triggerFunction = triggerFunction0;
    this.positionFunction = positionFunction0;
    this.disposers = [];
    this.elements = EmptyElementList;
    this.sorted = EmptySorted;
    this.trackingState = new TrackingState();
    this.isUnderListeners = null;
    this.isBeforeListeners = null;
    this.isVisitedListeners = null;
    this.isUnderSignals = new SignalArr();
    this.isBeforeSignals = new SignalArr();
    this.isVisitedSignals = new SignalArr();
    this.changeSignals = new SignalArr();
    this.anyIsChanged0 = null;
    this.disposers.push(reaction(this.triggerFunction, () => this.processPositionChange()));
  }

  dispose() {
    for (const dispose of this.disposers) {
      dispose();
    }
  }

  setElements(elements0) {
    if (elements0 !== this.elements) {
      this.isUnderSignals.signals = this.elements.remapContentDimensionedArray(
        this.isUnderSignals.signals,
        elements0
      );
      this.isBeforeSignals.signals = this.elements.remapContentDimensionedArray(
        this.isBeforeSignals.signals,
        elements0
      );
      this.isVisitedSignals.signals = this.elements.remapContentDimensionedArray(
        this.isVisitedSignals.signals,
        elements0
      );
      this.elements = elements0;
      this.sorted = this.elements.timeIntervals;
      refreshIntervals(this.trackingState, this.sorted);
    }
  }

  indexToElementId(index): ElementId {
    if (index !== NO_INDEX) {
      return this.elements.getId(index);
    } else {
      return null;
    }
  }

  elementIdToIndex(elementId: ElementId) {
    return this.elements.getIndex(elementId);
  }

  newListeners() {
    return new Set<(el: ElementId) => void>();
  }

  makeUnsubscribe(listeners: Set<(el: ElementId) => void>, f: (el: ElementId) => void) {
    return () => listeners.delete(f);
  }

  addListener(listeners: Set<(el: ElementId) => void>, f: (el: ElementId) => void) {
    listeners.add(f);
    return this.makeUnsubscribe(listeners, f);
  }

  notifyListenersForRange(listeners: Set<(el: ElementId) => void>, starts: number, ends: number) {
    if (listeners && starts >= 0) {
      for (const callback of listeners) {
        for (let i = starts; i <= ends; i++) {
          const id = this.indexToElementId(i);
          callback(id);
        }
      }
    }
  }

  notifyListeners(listeners: Set<(el: ElementId) => void>, index: number) {
    if (listeners && index !== NO_INDEX) {
      for (const callback of listeners) {
        const id = this.indexToElementId(index);
        callback(id);
      }
    }
  }

  makeSignal() {
    return new Signal();
  }

  createSignalsArray(): Signal[] {
    return new Array(this.sorted.length).fill(null);
  }

  getOrCreateSignals(signalsArr: SignalArr) {
    if (isNull(signalsArr.signals)) {
      signalsArr.signals = this.createSignalsArray();
    }
    return signalsArr.signals;
  }

  getSignal(signalsArr: SignalArr, idx: number) {
    const signals = this.getOrCreateSignals(signalsArr);
    let signal = signals[idx];
    if (isNull(signal)) {
      signal = this.makeSignal();
      signals[idx] = signal;
    }
    return signal;
  }

  needNotifyAnyChange() {
    return !!this.changeSignals.signals;
  }

  notifySignal(signal: Signal) {
    if (signal) {
      signal.set(this.trackingState.vIdx);
    }
  }

  notifySignalIndex(signalsArr: SignalArr, index: number) {
    const signals = signalsArr.signals;
    if (signals && index !== NO_INDEX) {
      this.notifySignal(signals[index]);
    }
  }

  notifySignalRange(signalsArr: SignalArr, starts: number, ends: number) {
    // TODO is length check ever needed?
    const signals = signalsArr.signals;
    if (signals && starts >= 0 && ends < signals.length) {
      for (let i = starts; i <= ends; i++) {
        this.notifySignal(signals[i]);
      }
    }
  }

  subscribeIsUnder(f: (el: ElementId) => void) {
    if (!this.isUnderListeners) {
      this.isUnderListeners = this.newListeners();
    }
    this.addListener(this.isUnderListeners, f);
  }

  subscribeIsBefore(f: (el: ElementId) => void) {
    if (!this.isBeforeListeners) {
      // isBeforeListeners <- newListeners ()
      this.isBeforeListeners = this.newListeners();
    }
    this.addListener(this.isBeforeListeners, f);
  }

  subscribeIsVisited(f: (el: ElementId) => void) {
    if (!this.isVisitedListeners) {
      this.isVisitedListeners = this.newListeners();
    }
    this.addListener(this.isVisitedListeners, f);
  }

  notifyChange() {
    if (this.anyIsChanged0) {
      this.anyIsChanged0.set(this.trackingState.vIdx);
    }
  }

  notifyIsBefore(starts: number, ends: number) {
    this.notifyListenersForRange(this.isBeforeListeners, starts, ends);
    this.notifySignalRange(this.isBeforeSignals, starts, ends);
    if (this.needNotifyAnyChange()) {
      this.notifySignalRange(this.changeSignals, starts, ends);
    }
  }

  notifyIsVisited(starts: number, ends: number) {
    this.notifyListenersForRange(this.isVisitedListeners, starts, ends);
    this.notifySignalRange(this.isVisitedSignals, starts, ends);
  }

  notifyIsUnder(index: number) {
    this.notifyListeners(this.isUnderListeners, index);
    this.notifySignalIndex(this.isUnderSignals, index);
  }

  notifyAllChanges() {
    runInAction(() => {
      this.notifyIsUnder(this.trackingState.isUnderOldChangeIndex);
      this.notifyIsUnder(this.trackingState.isUnderNewChangeIndex);
      this.notifyIsBefore(
        this.trackingState.isBeforeChangeRangeStart,
        this.trackingState.isBeforeChangeRangeEnd
      );
      this.notifyIsVisited(
        this.trackingState.isVisitedChangeRangeStart,
        this.trackingState.isVisitedChangeRangeEnd
      );
      this.notifyChange();
    });
  }

  processPositionChange() {
    const position = this.positionFunction();
    recordChangesForNewPosition(this.trackingState, position);
    if (this.trackingState.anyChangeRecord) {
      this.notifyAllChanges();
      clearChangeRecords(this.trackingState);
    }
  }

  get anyIsChangedSignal() {
    if (!this.anyIsChanged0) {
      this.anyIsChanged0 = this.makeSignal();
    }
    return this.anyIsChanged0;
  }

  isUnderSignal(elementId: ElementId) {
    return this.getSignal(this.isUnderSignals, this.elementIdToIndex(elementId));
  }

  isBeforeSignal(elementId: ElementId) {
    return this.getSignal(this.isBeforeSignals, this.elementIdToIndex(elementId));
  }

  isVisitedSignal(elementId: ElementId) {
    return this.getSignal(this.isVisitedSignals, this.elementIdToIndex(elementId));
  }

  changedSignal(elementId: ElementId) {
    return this.getSignal(this.changeSignals, this.elementIdToIndex(elementId));
  }

  currentIsUnder() {
    return this.indexToElementId(currentIsUnder(this.trackingState));
  }

  isUnder(elementId: ElementId) {
    return isUnder(this.trackingState, this.elementIdToIndex(elementId));
  }

  isBefore(elementId: ElementId) {
    return isBefore(this.trackingState, this.elementIdToIndex(elementId));
  }

  isVisited(elementId: ElementId) {
    isVisited(this.trackingState, this.elementIdToIndex(elementId));
  }

  elementInterval(elementId: ElementId) {
    return this.sorted.intervalAt(this.elementIdToIndex(elementId));
  }

  observableIsUnder() {
    // TODO optimize when implement anyIsUnderChanged signal (instead of anyIsChanged)
    this.anyIsChangedSignal.watch();
    return this.currentIsUnder();
  }
}

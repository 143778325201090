
export function System_Array__$005B$005D$1_append_1505(x, item) {
    x.push(item);
}

export function System_Array__$005B$005D$1_extend_5975E3(x, items) {
    x.push(...items);
}

export function System_Array__$005B$005D$1_pop(x) {
    return x.pop();
}

export function System_Array__$005B$005D$1_popLeft(x) {
    return x.shift();
}

export function System_Array__$005B$005D$1_get_lastIndex(x) {
    return x.length - 1;
}

export function System_Array__$005B$005D$1_indexOf_1505(x, item) {
    return x.indexOf(item);
}

export function Fable_Core_JS_Map$2__Map$2_setDefault_2A0A0(x, key, default0) {
    const value = x.get(key);
    if (!value) {
        x.set(key, default0);
        return default0;
    }
    else {
        return value;
    }
}

export const JSObject = Object;

export function Fable_Core_JS_ObjectConstructor__ObjectConstructor_values_1505(x, o) {
    return Object.values(o);
}

export function Fable_Core_JS_ObjectConstructor__ObjectConstructor_entries_1505(x, o) {
    return Object.entries(o);
}


import {
  Element,
  ElementId,
  ElementKind,
  getKindFromId,
  IdRange,
  IndexMapping,
  IndexRange,
  isNull,
  isNumber,
  notNil,
  NO_INDEX,
  xrange,
} from '../basic-types';
import { Interval, Sorted } from '../sorted/sorted';
import { EKinds } from './element-kinds';

export class ElementList {
  episodeKey: string;
  elements: Element[];
  domain: ElementList;
  words: ElementList;
  wordsIndexMapping: IndexMapping;
  kindsSublists: Map<string, ElementList>;
  idToIndexF: ((id: ElementId) => number) | null;
  idToIndexMap0: any;
  wordIntervals0: Sorted | null;
  timeIntervals0: Sorted | null;

  constructor(
    elements0: Element[],
    episodeKey0: string,
    domain0: ElementList,
    words0: ElementList,
    wordsIndexMapping0: IndexMapping,
    idToIndex0: any,
    idToIndexF0: (id: ElementId) => number
  ) {
    this.episodeKey = episodeKey0;
    this.elements = elements0;
    this.domain = domain0;
    this.words = words0;
    this.wordsIndexMapping = wordsIndexMapping0;
    this.kindsSublists = new Map();
    this.idToIndexF = idToIndexF0;
    this.idToIndexMap0 = idToIndex0;
    this.wordIntervals0 = null;
    this.timeIntervals0 = null;
  }

  get idToIndexMap() {
    if (this.idToIndexMap0) {
      return this.idToIndexMap0;
    } else {
      const result = {};
      for (const [i, element] of this.elements.entries()) {
        result[element.id] = i;
      }
      this.idToIndexMap0 = result;
      return this.idToIndexMap0;
    }
  }

  getIndex(id: ElementId) {
    if (this.idToIndexF) {
      return this.idToIndexF(id);
    } else {
      const result = this.idToIndexMap[id];
      if (isNumber(result)) {
        return result as number;
      } else {
        return NO_INDEX;
      }
    }
  }

  getId(index: number) {
    return this.elements[index].id;
  }

  getElement(id: ElementId) {
    const index = this.getIndex(id);
    if (index !== NO_INDEX && isNumber(index)) {
      return this.elements[this.getIndex(id)];
    } else {
      return null;
    }
  }

  hasElement(id: ElementId) {
    return !!this.getElement(id);
  }

  get wordIntervals() {
    if (!this.wordIntervals0) {
      const startPoints = [];
      const endPoints = [];
      let hasEndPoints = false;
      if (this.elements.length > 0) {
        if (notNil(this.elements[0].endWordAddress)) {
          hasEndPoints = true;
        }
      }
      for (const elem of this.elements) {
        // TODO handle case of only startWord
        startPoints.push(elem.wordAddress);
        if (hasEndPoints) {
          endPoints.push(elem.endWordAddress);
        }
      }
      if (hasEndPoints) {
        this.wordIntervals0 = new Sorted(startPoints, endPoints);
      } else {
        if (startPoints.length > 0) {
          this.wordIntervals0 = new Sorted(startPoints, null);
        } else {
          this.wordIntervals0 = new Sorted(startPoints, []);
        }
      }
    }
    return this.wordIntervals0;
  }

  get timeIntervals() {
    if (isNull(this.timeIntervals0)) {
      const startPoints = [];
      const endPoints = [];
      for (const elem of this.elements) {
        startPoints.push(elem.time);
        endPoints.push(elem.endTime);
      }
      this.timeIntervals0 = new Sorted(startPoints, endPoints);
    }
    return this.timeIntervals0;
  }

  idRangeToIndexRange(range: IdRange): IndexRange {
    return { starts: this.getIndex(range.starts), ends: this.getIndex(range.ends) };
  }

  indexRangeToIdRange(range: IndexRange): IdRange {
    return { starts: this.getId(range.starts), ends: this.getId(range.ends) };
  }

  stepId(id: ElementId, useWordAddresses = false, direction: number): ElementId {
    const isIdRange = el => false; // TODO really implement, hmm is actually needed here?

    // TODO needs to consider a multiple current positional states? - focused line, focused element, selected word
    // TODO given that is the cursoring algorithm specific to the state modeling of specific app, belong on app layer?
    // or could consolidate input output state model to single dimension (one element)?
    const index = this.getIndex(id);
    if (index !== NO_INDEX) {
      const testIndex = index + direction;
      if (testIndex >= 0 && testIndex < this.elements.length) {
        return this.elements[index + direction].id;
      } else {
        return null;
      }
    } else if (this.domain.hasElement(id)) {
      const nextElement = this.domain.findStep(
        id,
        (el: Element) => this.hasElement(el.id),
        direction
      );
      return nextElement ? nextElement.id : null;
    } else if (useWordAddresses) {
      if (getKindFromId(id) === EKinds.WORD) {
        // TODO or word id test here????
        const index = this.words.getIndex(id);
        const wordIntervals = this.wordIntervals;
        const elementIndex =
          direction === 1
            ? this.wordIntervals.firstStartsAfter(index)
            : this.wordIntervals.lastEndsBeforeOrAt(index);
        // TODO check NO_INDEX
        if (elementIndex !== NO_INDEX) {
          return this.elements[elementIndex].id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  nextId(id: ElementId, useWordAddresses = false): ElementId {
    return this.stepId(id, useWordAddresses, 1);
  }

  prevId(id, useWordAddresses = false) {
    return this.stepId(id, useWordAddresses, -1);
  }

  // TODO move this somewhere else because does not use local state?
  rangeAsSeq(range: IndexRange) {
    return xrange(range.starts, range.ends);
  }

  idRangeAsIds(range: IdRange) {
    const indexSeq = this.rangeAsSeq(this.idRangeToIndexRange(range));
    return Array.from(indexSeq, i => this.elements[i].id);
  }

  rangeAsElements(range: IndexRange) {
    // TODO optimize?
    const indexSeq = this.rangeAsSeq(range);
    return Array.from(indexSeq, i => this.elements[i]);
  }

  idRangeAsElements(range: IdRange) {
    const indexSeq = this.rangeAsSeq(this.idRangeToIndexRange(range));
    return Array.from(indexSeq, i => this.elements[i]);
  }

  findNext(id: ElementId, f: (el: Element) => boolean): Element {
    // TODO if id is null start at end?
    const start = this.getIndex(id);
    const len = this.elements.length;
    for (let i = start; i < len; i++) {
      const element = this.elements[i];
      if (f(element)) {
        return element;
      }
    }
    return null;
  }

  findPrevious(id: ElementId, f: (el: Element) => boolean): Element {
    // TODO if id is null start at end?
    const start = this.getIndex(id);
    for (let i = start; i >= 0; i--) {
      const element = this.elements[i];
      if (f(element)) {
        return element;
      }
    }
    return null;
  }

  findStep(id: ElementId, f: (el: Element) => boolean, direction: number) {
    // TODO rethink this
    if (direction === -1) {
      return this.findPrevious(id, f);
    } else {
      return this.findNext(id, f);
    }
  }

  filter(f: (el: Element) => boolean): ElementList {
    const filtered: Element[] = [];
    const filterDomain = this.domain ?? this;
    for (const element of this.elements) {
      if (f(element)) {
        filtered.push(element);
      }
    }
    return new ElementList(
      filtered,
      this.episodeKey,
      filterDomain,
      this.words,
      this.wordsIndexMapping,
      null,
      null
    );
  }

  fromIds(ids: ElementId[]): ElementList {
    const filtered: Element[] = [];
    for (const id of ids) {
      const element = this.getElement(id);
      if (element) {
        filtered.push(element);
      }
    }
    return new ElementList(
      filtered,
      this.episodeKey,
      this.domain,
      this.words,
      this.wordsIndexMapping,
      null,
      null
    );
  }

  fromIndexes(indexes: number[]): ElementList {
    const filtered: Element[] = [];
    for (const index of indexes) {
      const element = this.elements[index];
      if (element) {
        filtered.push(element);
      }
    }
    return new ElementList(
      filtered,
      this.episodeKey,
      this.domain,
      this.words,
      this.wordsIndexMapping,
      null,
      null
    );
  }

  getKindSubList(kind: ElementKind): ElementList {
    // TODO should ElementList have known kind restrictions then if
    // only one kind inside and kind param is same return self?
    const result = this.kindsSublists.get(kind);
    if (result) {
      return result;
    } else {
      const filtered = [];
      // TODO decide if should initially implement as list comprehension and switch to imperative is there are performance issues?
      for (const element of this.elements) {
        if (element.kind === kind) {
          filtered.push(element);
        }
      }
      const list: ElementList = new ElementList(
        filtered,
        this.episodeKey,
        this.domain || this,
        this.words,
        this.wordsIndexMapping,
        null,
        null
      );
      this.kindsSublists.set(kind, list);
      return list;
    }
  }

  getKindsSubListsAsArray(kinds: string[]) {
    return kinds.map(kind => this.getKindSubList(kind));
  }

  getKindsSubLists(kinds: string[]) {
    const result = {};
    for (const kind of kinds) {
      result[kind] = this.getKindSubList(kind);
    }
    return result;
  }

  wordAddress(id: ElementId): number {
    return this.getElement(id).wordAddress;
  }

  endWordAddress(id: ElementId): number {
    return this.getElement(id).endWordAddress;
  }

  getWordInterval(id): Interval {
    const element = this.getElement(id);
    return { starts: element.wordAddress, ends: element.endWordAddress };
  }

  getElementsIntersectWordIdRange(wordRange: IdRange) {
    const wordIntervals = this.wordIntervals;
    const wordIndexRange = this.words.idRangeToIndexRange(wordRange);
    const elementRange = wordIntervals.rangeIntersecting(
      wordIndexRange.starts,
      wordIndexRange.ends
    );
    if (elementRange) {
      return this.rangeAsElements(elementRange);
    } else {
      return null; // TODO or []?
    }
  }

  hasElementsIntersectWordIdRange(wordRange: IdRange) {
    const wordIntervals = this.wordIntervals;
    const wordIndexRange = this.words.idRangeToIndexRange(wordRange);
    return notNil(wordIntervals.rangeIntersecting(wordIndexRange.starts, wordIndexRange.ends));
  }

  time(id: ElementId) {
    return this.getElement(id).time;
  }

  endTime(id: ElementId) {
    return this.getElement(id).endTime;
  }

  getTimeInterval(id: ElementId): Interval {
    const element = this.getElement(id);
    return { starts: element.time, ends: element.endTime };
  }

  getElementContainingWordAddress(address: number) {
    const wordIntervals = this.wordIntervals;
    const elementIndex = wordIntervals.containing(address);
    if (elementIndex !== NO_INDEX) {
      return this.elements[elementIndex];
    } else {
      return null;
    }
  }

  getElementContainingWordId(id: ElementId): Element {
    const wordIndex = this.words.getIndex(id);
    return this.getElementContainingWordAddress(wordIndex);
  }

  getElementContainingTime(time: number): Element {
    const timeIntervals = this.timeIntervals;
    const elementIndex = timeIntervals.containing(time);
    if (elementIndex !== NO_INDEX) {
      return this.elements[elementIndex];
    } else {
      return null;
    }
  }

  getDomainIndex(id) {
    return this.domain.getIndex(id);
  }

  joinWithIdMap(key: string, map: any, defaults: any): ElementList {
    const mergedElements: Element[] = [];
    let attach = defaults;
    for (const element0 of this.elements) {
      const element: Element = { ...element0 };
      attach = defaults;
      const toJoin = map[element.id];
      // TODO incorporate defaults
      if (toJoin) {
        attach = { ...defaults, ...toJoin };
      }
      element[key] = attach;
      mergedElements.push(element);
    }
    return new ElementList(
      mergedElements,
      this.episodeKey,
      null,
      this.words,
      this.wordsIndexMapping,
      null,
      null
    );
  }

  difference(elementList: ElementList): ElementList {
    return this.filter((element: Element) => !elementList.hasElement(element.id));
  }

  remapContentDimensionedArray(remap: any[], newList: ElementList): any[] {
    if (isNull(remap)) {
      return null;
    } else {
      // const len = newList.elements.length;
      const result = new Array(newList.elements.length);
      for (const [i, value] of remap.entries()) {
        const id = this.getId(i);
        const newIndex = newList.getIndex(id);
        result[newIndex] = value;
      }
      return result;
    }
  }
}

function SimpleElementList0(elements0: Element[]): ElementList {
  return new ElementList(elements0, '', null, null, null, null, null);
}

export function SimpleElementList(elements0: Element[]): ElementList {
  return new ElementList(elements0, '', null, SimpleElementList0([]), null, null, null);
}

export function CreateElementList(
  elements0: Element[],
  episodeKey0: string,
  domain0: ElementList,
  words0: ElementList,
  wordsIndexMapping0: IndexMapping,
  idToIndex0: any,
  idToIndexF0: (id: ElementId) => number
): ElementList {
  return new ElementList(
    elements0,
    episodeKey0,
    domain0,
    words0,
    wordsIndexMapping0,
    idToIndex0,
    idToIndexF0
  );
}

export const EmptyElementList = SimpleElementList([]);

import { makeObservable, computed, observable, reaction, runInAction } from 'mobx';
import { WrappedAudioElement } from './wrapped-audio-element.js';

const emptyAudioSource = new WrappedAudioElement();

export class MultiAudioSource {
  constructor(audioSourceKeySortOrder, defaultSourceKey) {
    this.audioSourceKeySortOrder = audioSourceKeySortOrder;
    this.defaultSourceKey = defaultSourceKey;
    this.sourcesKey = ''; // TODO use sourcesKey to not do mutation if same?
    this.audioSourceDefinitions = {};
    this.currentAudioSourceKey = this.defaultSourceKey;
    this.currentAudioElement = emptyAudioSource;
    makeObservable(this, {
      audioSourceDefinitions: observable.ref,
      currentAudioSourceKey: observable.ref,
      currentAudioElement: observable.ref,
      audioSourceElements: computed({ keepAlive: true }),
      sourceKeys: computed({ keepAlive: true }),
      computedAudioElement: computed({ keepAlive: true }),
    });
    reaction(
      () => this.computedAudioElement,
      () => this.configureCurrentAudioElement()
    );
  }

  setAudioSourceDefinitions(key, sources) {
    this.sourcesKey = key;
    runInAction(() => {
      this.audioSourceDefinitions = sources;
      this.currentAudioSourceKey = this.defaultSourceKey;
    });
  }

  setCurrentAudioSourceKey(sourceKey) {
    this.currentAudioSourceKey = sourceKey;
  }

  get audioSourceElements() {
    const result = {};
    for (const [key, url] of Object.entries(this.audioSourceDefinitions)) {
      result[key] = new WrappedAudioElement(url);
    }
    return result;
  }

  get sourceKeys() {
    const availableSources = new Set(Object.keys(this.audioSourceDefinitions));
    return this.audioSourceKeySortOrder.filter(key => availableSources.has(key));
  }

  get computedAudioElement() {
    const result = this.audioSourceElements[this.currentAudioSourceKey];
    if (result) {
      return result;
    }
    return emptyAudioSource;
  }

  configureCurrentAudioElement() {
    if (this.computedAudioElement !== emptyAudioSource) {
      this.computedAudioElement.currentTime = this.currentAudioElement.currentTime;
      this.computedAudioElement.playbackRate = this.currentAudioElement.playbackRate;
    }
    this.currentAudioElement = this.computedAudioElement;
  }

  audioElementFunction = () => this.currentAudioElement;
}

export const SHIFTED_BY_DELETE_FLAG = 1 << 25;
export const MASK_OUT_DELETE_FLAG = ~SHIFTED_BY_DELETE_FLAG;

export type IndexMapping = {
  indexLookup: number[];
  contentLength: number;
  // }
};

// TODO consider requiring IndexIds module prefix on calls and shortening function names

export function getIndex(mapping, contentId) {
  return mapping.indexLookup[contentId] & MASK_OUT_DELETE_FLAG;
}

export function recordInsert(mapping, index) {
  const indexLookup = mapping.indexLookup;
  for (const [contentId, i] of indexLookup.entries()) {
    if ((i & MASK_OUT_DELETE_FLAG) >= index) {
      indexLookup[contentId] = i + 1;
    }
  }
  mapping.indexLookup = [...mapping.indexLookup, index];
  mapping.contentLength++;
}

export function recordInsertAtId(mapping, contentId) {
  recordInsert(mapping, getIndex(mapping, contentId));
}

export function recordRemove(mapping, index) {
  const indexLookup = mapping.indexLookup;
  for (const [contentId, i] of indexLookup.entries()) {
    if ((i & MASK_OUT_DELETE_FLAG) > index) {
      indexLookup[contentId] = i - 1;
    } else if (i === index) {
      indexLookup[contentId] = index | SHIFTED_BY_DELETE_FLAG;
    }
  }
  mapping.contentLength--;
}

export function recordRemoveAtId(mapping, contentId) {
  recordRemove(mapping, getIndex(mapping, contentId));
}

export function recordSwapsert(mapping, index) {
  const indexLookup = mapping.indexLookup;
  for (const [contentId, i] of indexLookup.entries()) {
    if ((i & MASK_OUT_DELETE_FLAG) > index) {
      indexLookup[contentId] = i + 1;
    }
  }
  mapping.indexLookup = [...mapping.indexLookup, index + 1];
  mapping.contentLength++;
}

export function isDeletedId(mapping, contentId) {
  return (mapping.indexLookup[contentId] & SHIFTED_BY_DELETE_FLAG) !== 0;
}

export function createMapping(length): IndexMapping {
  const indexLookup = new Array(length + 1);
  for (let contentId = 0; contentId <= length; contentId++) {
    indexLookup[contentId] = contentId;
  }
  return { indexLookup, contentLength: length };
}

export function makeIndexToIdMapping(mapping) {
  const length = mapping.contentLength;
  const result = new Array(length + 1);
  for (const [contentId, i] of mapping.indexLookup.entries()) {
    if ((contentId & SHIFTED_BY_DELETE_FLAG) === 0) {
      result[i] = contentId;
    }
  }
  return result;
}

export function adaptContentDimensionedArray(
  oldArray: any[],
  oldMapping: IndexMapping,
  newMapping: IndexMapping,
  fillValue
) {
  const result = new Array(newMapping.contentLength + 1).fill(fillValue);
  const oldMaxId = oldMapping.indexLookup.length - 1;
  for (let contentId = 0; contentId <= oldMaxId; contentId++) {
    const oldIndex = getIndex(oldMapping, contentId);
    const newIndex = getIndex(newMapping, contentId);
    result[newIndex] = oldArray[oldIndex];
  }
  return result;
}

export function copyMapping(mapping) {
  return { indexLookup: [...mapping.indexLookup], contentLength: mapping.contentLength };
}

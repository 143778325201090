import { Element } from '../basic-types';
import { fastSortOnIntKey } from '../fast-sort-on-int-key';
import { EKinds } from './element-kinds';
import { Precedence } from './precedence';
// [<ImportMember("../fast-sort-on-int-key.js")>]

let elementSecondarySortOrder = new Precedence([
  EKinds.PARAGRAPH_BREAK,
  EKinds.CHAPTER_COMPLETE,
  EKinds.CHAPTER_TITLE,
  EKinds.CHAPTER_SUMMARY,
  EKinds.CULTURAL_NOTE,
  EKinds.PASSAGE_HINT,
  EKinds.SPEAKER_LABEL,
  EKinds.SENTENCE,
  EKinds.WORD,
  EKinds.WORD_GROUP,
]);

export const elementKindToSortPrecedence = elementSecondarySortOrder.keyToPrecedenceLevel;

export function elementSortProjection(element: Element) {
  const wordIndex: number = element.wordAddress;
  const kindPrecedence: number = elementKindToSortPrecedence[element.kind];
  return (wordIndex << 4) | kindPrecedence;
}

export function sortElements(elements: Element[]) {
  fastSortOnIntKey(elements, elementSortProjection);
}

import { newRecordOfSameClass } from "./frame.js";
import { getEnumerator } from "../../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { System_Array__$005B$005D$1_slice_Z3227AE51 } from "./util.fs.js";

export function sliceFrame(frame0, slice) {
    const frame = frame0;
    const newFrame = newRecordOfSameClass(frame);
    let enumerator = getEnumerator(Object.getOwnPropertyNames(frame0));
    try {
        while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
            const field = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
            const arr = frame[field];
            newFrame[field]=System_Array__$005B$005D$1_slice_Z3227AE51(arr, slice);
        }
    }
    finally {
        enumerator.Dispose();
    }
    return newFrame;
}


import { firebase } from './firebase-init';

export const firebaseAuth = firebase.auth();

// not sure why this attemp hit's a run-time error
// export const { onAuthStateChanged } = firebaseAuth;

export const signOut = () => firebaseAuth.signOut();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => firebaseAuth.signInWithPopup(provider);

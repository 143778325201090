import { ElementId, ElementKind, getKindFromId } from '../basic-types';
import { EKinds } from '../elements/element-kinds';
import { ElementList, EmptyElementList } from '../elements/element-list';
import { Tracker } from './tracker';

export class Tracking {
  positionFunction: () => number;
  trackers: Map<ElementKind, Tracker>;
  elements: ElementList;

  constructor(positionFunction0: () => number) {
    this.positionFunction = positionFunction0;
    this.trackers = new Map();
    this.elements = EmptyElementList;
  }

  setElements(newElements: ElementList) {
    if (this.elements && newElements && this.elements.episodeKey !== newElements.episodeKey) {
      for (const tracker of this.trackers.values()) {
        tracker.dispose();
      }
      this.trackers.clear();
    } else {
      for (const [kind, tracker] of this.trackers.entries()) {
        tracker.setElements(newElements.getKindSubList(kind));
      }
    }
    this.elements = newElements;
  }

  createTrackerWithKind(kind: ElementKind): Tracker {
    const kindList = this.elements.getKindSubList(kind);
    if (kind === EKinds.WORD) {
      const tracker = new Tracker(this.positionFunction, this.positionFunction);
      tracker.setElements(kindList);
      return tracker;
    } else {
      const wordTracker = this.getTrackerWithKind0(EKinds.WORD);
      const tracker = new Tracker(
        () => wordTracker.anyIsChangedSignal.watch(),
        this.positionFunction
      );
      tracker.setElements(kindList);
      return tracker;
    }
  }

  getTrackerWithKind0(kind: ElementKind): Tracker {
    const tracker = this.trackers.get(kind);
    if (tracker) {
      return tracker;
    } else {
      const tracker = this.createTrackerWithKind(kind);
      this.trackers.set(kind, tracker);
      return tracker;
    }
  }

  getTrackerWithKind(kind: ElementKind) {
    return this.getTrackerWithKind0(kind);
  }

  getTracker(elementId: ElementId) {
    const kind = getKindFromId(elementId);
    return this.getTrackerWithKind(kind);
  }

  currentIsUnder(kind: ElementKind) {
    const tracker = this.getTrackerWithKind(kind);
    return tracker.currentIsUnder();
  }

  isUnder(elementId: ElementId) {
    return this.getTracker(elementId).isUnder(elementId);
  }

  isBefore(elementId: ElementId) {
    return this.getTracker(elementId).isBefore(elementId);
  }

  isVisited(elementId: ElementId) {
    return this.getTracker(elementId).isVisited(elementId);
  }

  isUnderSignal(elementId: ElementId) {
    return this.getTracker(elementId).isUnderSignal(elementId);
  }

  isBeforeSignal(elementId: ElementId) {
    return this.getTracker(elementId).isBeforeSignal(elementId);
  }

  isVisitedSignal(elementId: ElementId) {
    return this.getTracker(elementId).isVisitedSignal(elementId);
  }

  changedSignal(elementId: ElementId) {
    return this.getTracker(elementId).changedSignal(elementId);
  }

  anyIsChangedSignal(kind: ElementKind) {
    const tracker = this.getTrackerWithKind(kind);
    return tracker.anyIsChangedSignal;
  }
}

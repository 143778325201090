
export function searchSorted(sorted, value, returnHighest) {
    let low = 0;
    let high = sorted.length | 0;
    while (low < high) {
        const mid = ((low + high) >> 1) | 0;
        const computed = sorted[mid] | 0;
        if (returnHighest ? (computed <= value) : (computed < value)) {
            low = (mid + 1);
        }
        else {
            high = mid;
        }
    }
    return high | 0;
}


import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import './index.css';

global.jwBuildTimestamp = '2021.06.09-15:00pdt';

// TODO turn off mobx strict mode

ReactDOM.render(<App />, document.getElementById('root'));

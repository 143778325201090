import { AudioRegionDTO, NO_INDEX } from '../../../basic-types';
import { Interval, Sorted } from '../../../sorted/sorted';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class AudioRegionActions {
  getTargetRegion() {
    const selection = this.audioSelectionRegion;
    const regionIntervals = this.currentAudioRegionIntervals;
    if (selection) {
      const intersecting = regionIntervals.rangeIntersecting(selection.starts, selection.ends);
      if (intersecting) {
        const intervals = regionIntervals.retrieveRange(intersecting.starts, intersecting.ends);
        if (intervals.length === 1) {
        }
      }
      return { id: null, startTime: selection.starts, endTime: selection.ends };
    } else {
      const targetRegionIndex = regionIntervals.containing(this.currentAudioPosition);
      if (targetRegionIndex !== NO_INDEX) {
        return this.currentAudioRegions[targetRegionIndex];
      } else {
        return null;
      }
    }
  }

  addUpdate(data: AudioRegionDTO) {
    this.mutationActions.addUpdateChaatAudioRegion(data);
  }

  remove(id) {
    this.mutationActions.removeChaatAudioRegion(id);
  }

  addUpdateFromAudioSelection() {
    const targetRegion = this.getTargetRegion();
    if (targetRegion) {
      this.addUpdate(targetRegion);
    }
  }

  removeFromAudioSelection() {
    const targetRegion = this.getTargetRegion();
    if (targetRegion) {
      this.remove(targetRegion.id);
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get currentAudioPosition(): number;
  abstract get audioSelectionRegion(): Interval;
  abstract get currentAudioRegions(): any[]; // TODO typing
  abstract get currentAudioRegionIntervals(): Sorted;
}

import { Section, ZipRun, SkipRun } from "./sections.fs.js";
import { InfoTag } from "./match-result.fs.js";
import { TimeInterval } from "./timestamper-types.fs.js";
import { splitSectionWithMatchResult, SkipRun as SkipRun_1 } from "./sections.fs.js";
import { System_Array__$005B$005D$1_popLeft, System_Array__$005B$005D$1_append_1505, System_Array__$005B$005D$1_extend_5975E3 } from "../../../../basic-types.fs.js";
import { sortBy, copy, append } from "../../../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { class_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import lodash from "lodash";
import { comparePrimitives } from "../../../../../.fable/fable-library.3.1.0-beta-001/Util.js";

export function jsClass(o) {
    return o.constructor;
}

export const SkipRunClass = SkipRun;

export const ZipRunClass = ZipRun;

export const SectionClass = Section;

export const InfoTagClass = InfoTag;

export function makeSkipRun(section) {
    const positions = section.content.data.globalPosition;
    return new SkipRun_1(positions[0], positions.length, new TimeInterval(section.startTime, section.endTime));
}

export function processWithMatchers(inputs, matchers) {
    const output = [];
    for (let idx = 0; idx <= (inputs.length - 1); idx++) {
        const input = inputs[idx];
        const kind = jsClass(input);
        if (kind === SectionClass) {
            const section = input;
            let matchResult = null;
            for (let idx_1 = 0; idx_1 <= (matchers.length - 1); idx_1++) {
                const matcher = matchers[idx_1];
                matchResult = matcher(section);
                if (matchResult) {
                    break;
                }
            }
            if (matchResult) {
                System_Array__$005B$005D$1_extend_5975E3(output, append(splitSectionWithMatchResult(section, matchResult), matchResult.infoTags));
            }
            else {
                System_Array__$005B$005D$1_append_1505(output, section);
            }
        }
        else {
            System_Array__$005B$005D$1_append_1505(output, input);
        }
    }
    return output;
}

export class TimestamperEngine0 {
    constructor() {
        this.processingQueue = [];
        this.outputQueue = [];
        this.matchers = [];
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    routeOutputs(outputs) {
        return TimestamperEngine0__routeOutputs_6A260A7D(this, outputs)
    }
    run() {
        return TimestamperEngine0__run(this)
    }
    setMatchers(matchers0) {
        return TimestamperEngine0__setMatchers_Z4AECB7F0(this, matchers0)
    }
    setSections(sections) {
        return TimestamperEngine0__setSections_Z46D1A653(this, sections)
    }
}

export function TimestamperEngine0$reflection() {
    return class_type("Engine.TimestamperEngine0", void 0, TimestamperEngine0);
}

export function TimestamperEngine0_$ctor() {
    return new TimestamperEngine0();
}

function TimestamperEngine0__setSections_Z46D1A653(this$, sections) {
    this$.processingQueue = copy(sections);
}

function TimestamperEngine0__setMatchers_Z4AECB7F0(this$, matchers0) {
    this$.matchers = copy(matchers0);
}

function TimestamperEngine0__processOutput_4E60E31B(this$, output) {
    System_Array__$005B$005D$1_append_1505(this$.outputQueue, output);
}

function TimestamperEngine0__routeOutputs_6A260A7D(this$, outputs) {
    for (let idx = 0; idx <= (outputs.length - 1); idx++) {
        const output = outputs[idx];
        if (SectionClass === jsClass(output)) {
            System_Array__$005B$005D$1_append_1505(this$.processingQueue, output);
        }
        else {
            TimestamperEngine0__processOutput_4E60E31B(this$, output);
        }
    }
}

function TimestamperEngine0__run(this$) {
    let x;
    while (x = this$.processingQueue, !(lodash.isEmpty(x))) {
        const section = System_Array__$005B$005D$1_popLeft(this$.processingQueue);
        let matchResult = null;
        const arr = this$.matchers;
        for (let idx = 0; idx <= (arr.length - 1); idx++) {
            const matcher = arr[idx];
            matchResult = matcher(section);
            if (matchResult) {
                break;
            }
        }
        if (matchResult) {
            TimestamperEngine0__routeOutputs_6A260A7D(this$, append(splitSectionWithMatchResult(section, matchResult), matchResult.infoTags));
        }
        else {
            TimestamperEngine0__routeOutputs_6A260A7D(this$, [makeSkipRun(section)]);
        }
    }
}

export function TimestamperEngine() {
    return TimestamperEngine0_$ctor();
}

export function extractDataFromOutput(output0, words, endTime) {
    const wordIntervals = [];
    const outputWords = [];
    const warningIntervals = [];
    const warningData = [];
    const interpolateIntervals = [];
    const interpolateData = [];
    const output = sortBy((o) => o.globalPosition, output0, {
        Compare: comparePrimitives,
    });
    for (let idx = 0; idx <= (output.length - 1); idx++) {
        const o_1 = output[idx];
        const kind = jsClass(o_1);
        if (kind === ZipRunClass) {
            const run = o_1;
            let pos = run.globalPosition | 0;
            const arr = run.intervals;
            for (let idx_1 = 0; idx_1 <= (arr.length - 1); idx_1++) {
                const interval = arr[idx_1];
                System_Array__$005B$005D$1_append_1505(wordIntervals, interval);
                if (pos !== outputWords.length) {
                }
                System_Array__$005B$005D$1_append_1505(outputWords, words[pos]);
                pos = (pos + 1);
            }
        }
        else if (kind === SkipRunClass) {
        }
        else if (kind === InfoTagClass) {
            const tag = o_1;
            if (tag.kind === "warn") {
                System_Array__$005B$005D$1_append_1505(warningIntervals, tag.interval);
                System_Array__$005B$005D$1_append_1505(warningData, tag.name);
            }
            else if (tag.kind === "interpolate") {
                System_Array__$005B$005D$1_append_1505(interpolateIntervals, tag.interval);
                System_Array__$005B$005D$1_append_1505(interpolateData, tag.name);
            }
        }
    }
    return {
        interpolateData: interpolateData,
        interpolateIntervals: interpolateIntervals,
        warningData: warningData,
        warningIntervals: warningIntervals,
        wordIntervals: wordIntervals,
        words: outputWords,
    };
}

// JS BOILERPLATE GENERATED
 
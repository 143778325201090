// open Fable.Core.JsInterop
// open Fable.Core.DynamicExtensions
// open Mobx
// open BasicTypes
// open ElementList
// open ElementTypes
// open Sorted
// open JSBoilerplate
// open ChaatContentRoots
// open Singletons
// open Tracking
// open ContentFuncs
// open ContentRoots

import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ChaatInputCue, ElementId, IdRange } from './masala-lib/basic-types';
import { ElementList, EmptyElementList } from './masala-lib/elements/element-list';
import { Tracking } from './masala-lib/tracking/tracking';
import * as AppRoot from './app-root';
import { EKinds } from './masala-lib/elements/element-kinds';
import { EmptySorted, Interval, Sorted } from './masala-lib/sorted/sorted';
import { wordIdRangeToWordStrings } from './masala-lib/content-funcs';
import { getSentenceTimestampingSignature } from './masala-lib/editorial/content-roots/content-roots';
import { ChaatContentRoots } from './content-roots';

// let warningUiDescriptionLookup:obj = !< {|
export const warningUiDescriptionLookup = {
  short: 'seems like a short audio period for: ',
  long: 'seems like a long audio period for: ',
  silences: 'audio silences inside interpolation for: ',
  // |}
};

// type ChaatToolModel0() as s0 =
export class ChaatToolModel {
  @observable.ref episodeKey = '';

  @observable.ref elements: ElementList = EmptyElementList;

  words: ElementList = EmptyElementList;

  sentences: ElementList = EmptyElementList;

  disposers: (() => void)[] = [];

  @observable.ref editEnabled = false; // TODO??

  @observable.ref currentCuePointWordId: ElementId = null;

  tracking: Tracking = null;

  @observable.ref wordTracker = null;

  @observable.ref sentenceTracker = null;

  @observable.ref warnings: ElementList = EmptyElementList;

  @observable.ref warningSentences = EmptyElementList;

  @observable.ref notchTimeIntervals: Sorted = EmptySorted;

  @observable.ref segmentTimeIntervals: Sorted = EmptySorted; // segmentElements and segmentIntervals also???
  // or the controller code initing the track area extracts intervals from elements?

  @observable.ref segmentStopWords: ElementList = EmptyElementList;

  @observable.ref transcriptWords: string[] = [];

  @observable.ref transcriptWordTimeIntervals: Sorted = EmptySorted;

  @observable.ref cuesData: ChaatInputCue[] = [];

  @observable.ref cuedWords: ElementList = EmptyElementList;

  @observable.ref cueDisplayTimeIntervals = EmptySorted;

  @observable.ref interpolatedTimeIntervals = EmptySorted;

  @observable.ref majorWarnings: ElementList = EmptyElementList;

  @observable.ref minorWarnings: ElementList = EmptyElementList;

  @observable.ref warningTimeIntervals: Sorted = EmptySorted;

  @observable.ref warningData: string[] = [];

  @observable.ref interpolationData = null; // TODO or interpolations?

  @observable.ref nonVoiceAudioRegions = [];

  @observable.ref nonVoiceAudioRegionIntervals = EmptySorted;

  @observable.ref audioMarkers = [];

  @observable.ref audioMarkerHitIntervals = EmptySorted;

  @observable.ref unsignedoffSentences: ElementList = EmptyElementList;

  @observable.ref audioUrls: any = {};

  @observable.ref stateVersion = 0;

  audioRegionSelection: Interval = null;

  constructor() {
    makeObservable(this);
  }

  init() {
    this.tracking = AppRoot.tracking;
    this.wordTracker = this.tracking.getTrackerWithKind(EKinds.WORD);
    this.sentenceTracker = this.tracking.getTrackerWithKind(EKinds.SENTENCE);
    this.disposers.push(
      AppRoot.appBus.subscribe('setCuePoint', cuePoint => (this.currentCuePointWordId = cuePoint))
    );
  }

  get wordStrings(): string[] {
    return this.words.elements.map(word => (<any>word).text);
  }

  get wordTimeIntervals(): Sorted {
    return this.words.timeIntervals;
  }

  @computed
  get currentWordId(): ElementId {
    return this.wordTracker.observableIsUnder();
  }

  @computed
  get currentSentenceId(): ElementId {
    return this.sentenceTracker.observableIsUnder();
  }

  getWarningLevel(warningIndex) {
    return this.warningData[warningIndex] === 'silences' ? 1 : 0;
  }

  getWarningUiText(warningIndex) {
    const warningRange: IdRange = this.warnings.elements[warningIndex]['range'];
    const warningWords = wordIdRangeToWordStrings(warningRange, this.elements.words);
    return warningUiDescriptionLookup[this.warningData[warningIndex]] + warningWords.join(' ');
  }

  setAudioRegionSelection(selection: Interval) {
    this.audioRegionSelection = selection;
  }

  addCue() {
    AppRoot.cueActions.addCue();
  }

  addShiftCue() {
    AppRoot.cueActions.addShiftCue();
  }

  addShiftEndCue() {
    AppRoot.cueActions.addShiftEndCue();
  }

  removeCueAtCurrent() {
    AppRoot.cueActions.removeCueAtCurrent();
  }

  createAudioRegion() {
    AppRoot.audioRegionActions.addUpdateFromAudioSelection();
  }

  removeAudioRegion() {
    AppRoot.audioRegionActions.removeFromAudioSelection();
  }

  createAudioMarker() {
    AppRoot.audioMarkerActions.addUpdateFromAudioPosition();
  }

  removeAudioMarker() {
    AppRoot.audioMarkerActions.removeFromAudioPosition();
  }

  setCurrentSentenceSignoff(signoff: boolean) {
    const sentenceId = this.currentSentenceId; // TODO this is calling function not getter
    if (sentenceId) {
      const sentence = this.sentences.getElement(sentenceId);
      const key = getSentenceTimestampingSignature(sentence, this.words);
      AppRoot.mutationActions.setChaatSignoff(key, signoff);
    }
  }

  deselect() {
    AppRoot.appBus.emit('deselect', null);
  }

  updateFromContentRoots(roots: ChaatContentRoots) {
    runInAction(() => {
      this.episodeKey = roots.episodeKey;
      this.elements = roots.content;
      this.words = roots.words;
      this.sentences = this.elements.getKindSubList(EKinds.SENTENCE);
      this.nonVoiceAudioRegions = roots.nonVoiceAudioRegions;
      this.nonVoiceAudioRegionIntervals = roots.nonVoiceAudioRegionIntervals;
      this.audioMarkers = roots.audioMarkers;
      this.audioMarkerHitIntervals = roots.audioMarkerHitIntervals;
      this.segmentTimeIntervals = roots.segmentTimeIntervals;
      this.segmentStopWords = roots.segmentStopWords;
      this.warnings = roots.warnings;
      this.majorWarnings = roots.majorWarnings;
      this.minorWarnings = roots.minorWarnings;
      this.warningSentences = roots.warningSentences;
      this.warningTimeIntervals = roots.warningTimeIntervals;
      this.warningData = roots.warningData;
      this.interpolatedTimeIntervals = roots.interpolatedTimeIntervals;
      this.cuesData = roots.chaatInputCues;
      this.cuedWords = roots.cuedWords;
      this.cueDisplayTimeIntervals = roots.cueDisplayTimeIntervals;
      this.transcriptWords = roots.transcriptWords;
      this.transcriptWordTimeIntervals = roots.transcriptWordTimeIntervals;
      this.notchTimeIntervals = roots.notchTimeIntervals;
      this.unsignedoffSentences = roots.chaatUnsignedoffSentences;
      this.audioUrls = roots.audioUrls;
      this.wordTracker = this.tracking.getTrackerWithKind(EKinds.WORD);
      this.sentenceTracker = this.tracking.getTrackerWithKind(EKinds.SENTENCE);
      // TODO something more context sensitive to set the nav point here?
      AppRoot.playerState.navigationPoint = AppRoot.navigation
        .getNavigatorForKey('SEGMENT')
        .navigationPoint(0);
      this.stateVersion++;
    });
  }
}

// let ChaatToolModel():IChaatToolModel = !< ChaatToolModel0()

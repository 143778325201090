import { Record } from "../../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, int32_type, array_type, string_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { min, comparePrimitives, max } from "../../../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { TimeInterval } from "./timestamper-types.fs.js";
import lodash from "lodash";
import { searchSorted } from "../../../../sorted/search-sorted.fs.js";
import { System_Array__$005B$005D$1_append_1505, System_Array__$005B$005D$1_get_lastIndex } from "../../../../basic-types.fs.js";
import { zip, indexed } from "../../../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { singleton, collect, rangeNumber, map, delay } from "../../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";

export class TranscriptFrame extends Record {
    constructor(word, startTime, endTime, originalWord) {
        super();
        this.word = word;
        this.startTime = startTime;
        this.endTime = endTime;
        this.originalWord = originalWord;
    }
}

export function TranscriptFrame$reflection() {
    return record_type("Spans.TranscriptFrame", [], TranscriptFrame, () => [["word", array_type(string_type)], ["startTime", array_type(int32_type)], ["endTime", array_type(int32_type)], ["originalWord", array_type(string_type)]]);
}

export class TranscriptSpan extends Record {
    constructor(data, startTime, endTime) {
        super();
        this.data = data;
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function TranscriptSpan$reflection() {
    return record_type("Spans.TranscriptSpan", [], TranscriptSpan, () => [["data", TranscriptFrame$reflection()], ["startTime", int32_type], ["endTime", int32_type]]);
}

export function TranscriptSpan__get_words(this$) {
    return this$.data.word;
}

export class ContentFrame extends Record {
    constructor(word, globalPosition, originalWord) {
        super();
        this.word = word;
        this.globalPosition = globalPosition;
        this.originalWord = originalWord;
    }
}

export function ContentFrame$reflection() {
    return record_type("Spans.ContentFrame", [], ContentFrame, () => [["word", array_type(string_type)], ["globalPosition", array_type(int32_type)], ["originalWord", array_type(string_type)]]);
}

export class ContentSpan extends Record {
    constructor(data, startTime, endTime) {
        super();
        this.data = data;
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function ContentSpan$reflection() {
    return record_type("Spans.ContentSpan", [], ContentSpan, () => [["data", ContentFrame$reflection()], ["startTime", int32_type], ["endTime", int32_type]]);
}

export function ContentSpan__get_words(this$) {
    return this$.data.word;
}

export class CuesFrame extends Record {
    constructor(globalPosition, timestamp) {
        super();
        this.globalPosition = globalPosition;
        this.timestamp = timestamp;
    }
}

export function CuesFrame$reflection() {
    return record_type("Spans.CuesFrame", [], CuesFrame, () => [["globalPosition", array_type(int32_type)], ["timestamp", array_type(int32_type)]]);
}

export class CuesSpan extends Record {
    constructor(data, startTime, endTime) {
        super();
        this.data = data;
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function CuesSpan$reflection() {
    return record_type("Spans.CuesSpan", [], CuesSpan, () => [["data", CuesFrame$reflection()], ["startTime", int32_type], ["endTime", int32_type]]);
}

export class AudioRegionsFrame extends Record {
    constructor(timestamp, silenceStartTime, silenceEndTime) {
        super();
        this.timestamp = timestamp;
        this.silenceStartTime = silenceStartTime;
        this.silenceEndTime = silenceEndTime;
    }
}

export function AudioRegionsFrame$reflection() {
    return record_type("Spans.AudioRegionsFrame", [], AudioRegionsFrame, () => [["timestamp", array_type(int32_type)], ["silenceStartTime", array_type(int32_type)], ["silenceEndTime", array_type(int32_type)]]);
}

export class AudioRegionsSpan extends Record {
    constructor(data, startTime, endTime) {
        super();
        this.data = data;
        this.startTime = (startTime | 0);
        this.endTime = (endTime | 0);
    }
}

export function AudioRegionsSpan$reflection() {
    return record_type("Spans.AudioRegionsSpan", [], AudioRegionsSpan, () => [["data", AudioRegionsFrame$reflection()], ["startTime", int32_type], ["endTime", int32_type]]);
}

export function intervalIntersection(a, b) {
    const lower = max(comparePrimitives, a.startTime, b.startTime) | 0;
    const upper = min(comparePrimitives, a.endTime, b.endTime) | 0;
    if (lower > upper) {
        return null;
    }
    else {
        return new TimeInterval(lower, upper);
    }
}

export function intervalDuration(a) {
    return a.endTime - a.startTime;
}

export function computeTranscriptSliceByTime(transcriptFrame, startTime, endTime) {
    const startTimes = transcriptFrame.startTime;
    if (lodash.isEmpty(startTimes)) {
        return (null);
    }
    const endTimes = transcriptFrame.endTime;
    if (startTimes[0] > endTimes[0]) {
        return [0, 0];
    }
    const spanInterval = new TimeInterval(startTime, endTime);
    let idx = searchSorted(endTimes, startTime, false) | 0;
    let firstWordInterval = new TimeInterval(startTimes[idx], endTimes[idx]);
    let firstWordIndex = idx | 0;
    let overlap = intervalIntersection(firstWordInterval, spanInterval);
    if (overlap) {
        if ((intervalDuration(overlap) / intervalDuration(firstWordInterval)) < 0.5) {
            firstWordIndex = (firstWordIndex + 1);
        }
    }
    idx = (searchSorted(startTimes, endTime, false) - 1);
    let lastWordInterval = new TimeInterval(startTimes[idx], endTimes[idx]);
    let lastWordIndex = idx | 0;
    let overlap_1 = intervalIntersection(lastWordInterval, spanInterval);
    if (overlap_1) {
        if ((intervalDuration(overlap_1) / intervalDuration(lastWordInterval)) < 0.5) {
            lastWordIndex = (lastWordIndex - 1);
        }
    }
    if (lastWordIndex >= firstWordIndex) {
        return [firstWordIndex, lastWordIndex];
    }
    else {
        return [firstWordIndex, firstWordIndex - 1];
    }
}

export function computeCueFrameSliceByTime(cueFrame, startTime, endTime) {
    const cueTimes = cueFrame.timestamp;
    const firstCueIndex = searchSorted(cueTimes, startTime, false) | 0;
    const lastCueIndex = (searchSorted(cueTimes, endTime, false) - 1) | 0;
    if (lastCueIndex >= firstCueIndex) {
        return [firstCueIndex, lastCueIndex];
    }
    else {
        return [firstCueIndex, firstCueIndex - 1];
    }
}

export function findAudioIntervalContains(intervals, point) {
    const startTimeIdx = (searchSorted(intervals, point, false) - 1) | 0;
    if ((startTimeIdx >= System_Array__$005B$005D$1_get_lastIndex(intervals)) ? true : (startTimeIdx < 0)) {
        return [null, null, null];
    }
    else {
        const startTime = intervals[startTimeIdx] | 0;
        const endTime = intervals[startTimeIdx + 1] | 0;
        return [startTime, endTime, startTimeIdx];
    }
}

export function audioRegionsSpanFindAudioIntervalContains(regionsSpan, point) {
    return findAudioIntervalContains(regionsSpan.data.timestamp, point);
}

export function audioRegionsFrameIntervalIsSilence(regionsFrame, idx) {
    return (idx % 2) === 0;
}

export function audioRegionsSpanIntervalIsSilence(regionsSpan, idx) {
    return audioRegionsFrameIntervalIsSilence(regionsSpan.data, idx);
}

export function audioRegionSpanIsContinuousAudio(regionsSpan) {
    const epsilon = 20;
    const patternInput = audioRegionsSpanFindAudioIntervalContains(regionsSpan, regionsSpan.startTime + epsilon);
    const startTime = patternInput[0] | 0;
    const intervalIdx = patternInput[2] | 0;
    const endTime = patternInput[1] | 0;
    if (audioRegionsFrameIntervalIsSilence(regionsSpan.data, intervalIdx)) {
        return false;
    }
    else if ((startTime > (regionsSpan.startTime + epsilon)) ? true : (endTime < (regionsSpan.endTime - epsilon))) {
        return false;
    }
    else {
        return true;
    }
}

export function reconcileAudioIntervals(targetIntervals, exclusionBeginnings, exclusionEndings, conflict) {
    const badSet = new Set();
    const arr = indexed(zip(exclusionBeginnings, exclusionEndings));
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const startTime = forLoopVar[1][0] | 0;
        const i = forLoopVar[0] | 0;
        const endTime = forLoopVar[1][1] | 0;
        const startIdx = findAudioIntervalContains(targetIntervals, startTime)[2] | 0;
        const endIdx = findAudioIntervalContains(targetIntervals, endTime)[2] | 0;
        if (lodash.isNull(startIdx)) {
            continue;
        }
        if (startIdx === endIdx) {
            const value = badSet.add(i);
            void value;
        }
    }
    const arr_1 = indexed(exclusionEndings);
    for (let idx_1 = 0; idx_1 <= (arr_1.length - 1); idx_1++) {
        const forLoopVar_1 = arr_1[idx_1];
        const i_1 = forLoopVar_1[0] | 0;
        const excludeEndingTime = forLoopVar_1[1] | 0;
        if (badSet.has(i_1)) {
            continue;
        }
        const idx_2 = findAudioIntervalContains(targetIntervals, excludeEndingTime)[2] | 0;
        if (lodash.isNull(idx_2)) {
            continue;
        }
        if (conflict(idx_2)) {
            targetIntervals[idx_2] = (excludeEndingTime + 2);
        }
    }
    const arr_2 = indexed(exclusionBeginnings);
    for (let idx_3 = 0; idx_3 <= (arr_2.length - 1); idx_3++) {
        const forLoopVar_2 = arr_2[idx_3];
        const i_2 = forLoopVar_2[0] | 0;
        const excludeBeginTime = forLoopVar_2[1] | 0;
        if (badSet.has(i_2)) {
            continue;
        }
        const idx_4 = findAudioIntervalContains(targetIntervals, excludeBeginTime)[2] | 0;
        if (lodash.isNull(idx_4)) {
            continue;
        }
        if (conflict(idx_4)) {
            targetIntervals[idx_4 + 1] = (excludeBeginTime - 2);
        }
    }
}

export function intervalsToInterleaved(intervals, endTime, terminated) {
    const result = [];
    if (terminated) {
        System_Array__$005B$005D$1_append_1505(result, 0);
    }
    for (let idx = 0; idx <= (intervals.length - 1); idx++) {
        const interval = intervals[idx];
        System_Array__$005B$005D$1_append_1505(result, interval.startTime);
        System_Array__$005B$005D$1_append_1505(result, interval.endTime);
    }
    if (terminated) {
        System_Array__$005B$005D$1_append_1505(result, endTime);
    }
    return result;
}

export function startsAndEndsToInterleaved(startTimes, endTimes, terminated, endTime) {
    const result = [];
    if (terminated) {
        System_Array__$005B$005D$1_append_1505(result, 0);
    }
    const arr = zip(startTimes, endTimes);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const starts = forLoopVar[0] | 0;
        const ends = forLoopVar[1] | 0;
        System_Array__$005B$005D$1_append_1505(result, starts);
        System_Array__$005B$005D$1_append_1505(result, ends);
    }
    if (terminated) {
        System_Array__$005B$005D$1_append_1505(result, endTime);
    }
    return result;
}

export function interleavedToStartsEnds(interleaved, terminated) {
    const startIndex = (terminated ? 1 : 0) | 0;
    const endIndex = (terminated ? (System_Array__$005B$005D$1_get_lastIndex(interleaved) - 1) : System_Array__$005B$005D$1_get_lastIndex(interleaved)) | 0;
    const startTimes = Int32Array.from(delay(() => map((i) => interleaved[i], rangeNumber(startIndex, 2, endIndex))));
    const endTimes = Int32Array.from(delay(() => map((i_1) => interleaved[i_1], rangeNumber(startIndex + 1, 2, endIndex))));
    return [startTimes, endTimes];
}

export function interleavedToIntervals(interleaved, terminated) {
    const patternInput = interleavedToStartsEnds(interleaved, terminated);
    const startTimes = patternInput[0];
    const endTimes = patternInput[1];
    return Array.from(delay(() => collect((matchValue) => {
        const starts = matchValue[0] | 0;
        const ends = matchValue[1] | 0;
        return singleton(new TimeInterval(starts, ends));
    }, zip(startTimes, endTimes))));
}


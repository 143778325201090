// open BasicTypes
// open AudioRegionActions
// open AppRoot

import { chaatToolModel, mutationActions, transportState } from '../app-root';
import { AudioRegionActions } from '../masala-lib/editorial/models/actions/audio-region-actions';

// type AudioRegionActions1() =
export class ChaatAudioRegionActions extends AudioRegionActions {
  get mutationActions() {
    return mutationActions;
  }
  get currentAudioPosition() {
    return transportState.audioPosition;
  }
  get audioSelectionRegion() {
    return chaatToolModel.audioRegionSelection;
  }
  get currentAudioRegions() {
    return chaatToolModel.nonVoiceAudioRegions;
  }
  get currentAudioRegionIntervals() {
    return chaatToolModel.nonVoiceAudioRegionIntervals;
  }
}

// let AudioRegionActions():IAudioRegionActions = !< AudioRegionActions1()

import { Sorted } from '../sorted/sorted';

export class NavigationPoint {
  navigatorKey: string;
  index: number;
  position: number;
  // }
  constructor(navigatorKey: string, index: number, position: number) {
    this.navigatorKey = navigatorKey;
    this.index = index;
    this.position = position;
  }
}

export enum EdgeEnum {
  Leading = 1,
  Trailing = 2,
  Midpoint = 3,
}

export class TimelineNavigator {
  navigatorKey: string;
  positions: Sorted;

  constructor(key: string) {
    this.navigatorKey = key;
    this.positions = null;
  }

  setPositions(sorted) {
    this.positions = sorted;
  }

  getKey() {
    return this.navigatorKey;
  }

  setIntervals(sorted: Sorted, edge0: EdgeEnum) {
    const edge = edge0 || EdgeEnum.Leading;
    //         match edge with
    if (edge === EdgeEnum.Leading) {
      this.setPositions(sorted.fromStartPoints());
    } else if (edge === EdgeEnum.Trailing) {
      this.setPositions(sorted.fromEndPoints());
    } else if (edge === EdgeEnum.Midpoint) {
      this.setPositions(sorted.fromMidPoints());
    }
  }

  getPositionForIndex(index: number) {
    if (this.positions) {
      return this.positions.pointAt(index).starts;
    } else {
      throw Error('no positions');
    }
  }

  navigationPoint(index: number) {
    if (this.positions) {
      if (this.positions.checkValidIndex(index)) {
        const position = this.getPositionForIndex(index);
        return new NavigationPoint(this.navigatorKey, index, position);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  move(point0: NavigationPoint, direction: number) {
    if (point0.navigatorKey !== this.navigatorKey) {
      throw Error("navigator keys don't match");
    }
    const nextNavigationPoint = this.navigationPoint(point0.index + direction);
    if (nextNavigationPoint) {
      return nextNavigationPoint;
    } else {
      return point0;
    }
  }

  next(point: NavigationPoint) {
    return this.move(point, 1);
  }

  prev(point: NavigationPoint) {
    return this.move(point, -1);
  }

  nextClosest(position: number) {
    if (this.positions) {
      return this.navigationPoint(this.positions.firstAfter(position));
    } else {
      return null;
    }
  }

  prevClosest(position: number) {
    if (this.positions) {
      return this.navigationPoint(this.positions.lastBeforeOrAt(position));
    } else {
      return null;
    }
  }
}

export class Navigation {
  navigators: any = {};

  addNavigator(navigator: TimelineNavigator) {
    this.navigators[navigator.getKey()] = navigator;
  }

  getNavigatorForKey(key: string): TimelineNavigator {
    return this.navigators[key];
  }

  next(point: NavigationPoint): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.next(point);
    } else {
      return null;
    }
  }

  prev(point: NavigationPoint): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.prev(point);
    } else {
    }
  }

  nextClosest(point: NavigationPoint, position: number): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.nextClosest(position);
    } else {
      return null;
    }
  }

  prevClosest(point: NavigationPoint, position: number): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.prevClosest(position);
    } else {
      return null;
    }
  }
}

import { Element, ElementId, IdRange } from '../basic-types';
import { EKinds } from './element-kinds';
import { ElementList } from './element-list';

export type AdhocWordRange = {
  kind: string;
  id: ElementId;
  range: IdRange;
  wordAddress: number;
  endWordAddress: number;
  // }
};

export function makeAdhocWordRange(range: IdRange, words: ElementList): Element {
  const wordAddress = words.getIndex(range.starts);
  const endWordAddress = words.getIndex(range.ends);
  const result: AdhocWordRange = {
    kind: EKinds.ADHOC_WORD_RANGE,
    id: EKinds.ADHOC_WORD_RANGE + ':' + range.starts + ':' + range.ends,
    range,
    wordAddress,
    endWordAddress,
  };
  // TODO work out the strong typing?
  return result as unknown as Element;
}

export function isWordRange(element: Element) {
  return element.kind === EKinds.ADHOC_WORD_RANGE;
}

import React from 'react';
import { lineViewerConfigurations } from './line-viewer-configuration.js';
import { chaatRenderWordRange } from './chaat-word-range-renderer.js';

export function SentenceView({ element, chaat }) {
  const model = chaat.model; // TODO use singleton model instead???
  const styleLayersRenderer = chaat.styleLayersRenderer;
  const startIndex = element.wordAddress;
  const endIndex = element.endWordAddress;
  const sentenceHTML = chaatRenderWordRange(styleLayersRenderer, model.words, {
    starts: startIndex,
    ends: endIndex,
  });
  const sentenceNum = model.sentences.getIndex(element.id) + 1;
  const prefix = `<span>[${sentenceNum}] </span>`;
  // TODO change to SENTENCE const
  const style = lineViewerConfigurations['SENTENCE'].style;
  return <div className={style} dangerouslySetInnerHTML={{ __html: prefix + sentenceHTML }} />;
}

export function ScriptLineView({ element, chaat }) {
  const config = lineViewerConfigurations[element.kind];
  let prefix = config.prefix;
  // TODO move styling to App.css??
  return (
    <div className={config.style}>
      <span className="markdown-prefix" style={{ verticalAlign: 'top', userSelect: 'none' }}>
        {prefix}
      </span>
      <span className="line-content">{element.content}</span>
    </div>
  );
}

function ScriptElement({ element, onClick, chaat }) {
  const styleLayersRenderer = chaat.styleLayersRenderer;
  const kind = element.kind;
  const config = lineViewerConfigurations[kind];
  let LineComponent = element.kind === 'SENTENCE' ? SentenceView : ScriptLineView;
  // TODO is chaat prop needed?
  const renderedStyles = 'script-line ' + styleLayersRenderer.getStyleForElement(element.id);
  return (
    <div className={renderedStyles} id={element.id} onClick={onClick}>
      <LineComponent element={element} chaat={chaat} />
    </div>
  );
}

function versionedElementKey(element) {
  // TODO move to utils in F#
  return element.id + ':' + element.timestamp;
}

export function renderView(chaat) {
  const model = chaat.model; // TODO maybe just use singleton directly?
  let elements = model.elements;
  elements = elements && elements.elements;
  elements = elements || [];

  const handleLineClick = (event, id) => {
    if (event.target.id) {
      chaat.handleLineClick(event, event.target.id);
    } else {
      chaat.handleLineClick(event, id);
    }
  };

  return (
    <div className="script" id="scriptId">
      {elements.map(el => (
        <ScriptElement
          key={versionedElementKey(el)}
          element={el}
          onClick={event => handleLineClick(event, el.id)}
          chaat={chaat}
        />
      ))}
    </div>
  );
}

import { Union, Record } from "../../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { string_type, union_type, bool_type, array_type, record_type, tuple_type, int32_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { TimeInterval$reflection } from "./timestamper-types.fs.js";

export class SlicePair extends Record {
    constructor(contentSlice, transcriptSlice) {
        super();
        this.contentSlice = contentSlice;
        this.transcriptSlice = transcriptSlice;
    }
}

export function SlicePair$reflection() {
    return record_type("MatchResult.SlicePair", [], SlicePair, () => [["contentSlice", tuple_type(int32_type, int32_type)], ["transcriptSlice", tuple_type(int32_type, int32_type)]]);
}

export class GapPair extends Record {
    constructor(contentPos, gapInterval) {
        super();
        this.contentPos = (contentPos | 0);
        this.gapInterval = gapInterval;
    }
}

export function GapPair$reflection() {
    return record_type("MatchResult.GapPair", [], GapPair, () => [["contentPos", int32_type], ["gapInterval", TimeInterval$reflection()]]);
}

export class PositionPair extends Record {
    constructor(contentPos, transcriptPos) {
        super();
        this.contentPos = (contentPos | 0);
        this.transcriptPos = (transcriptPos | 0);
    }
}

export function PositionPair$reflection() {
    return record_type("MatchResult.PositionPair", [], PositionPair, () => [["contentPos", int32_type], ["transcriptPos", int32_type]]);
}

export class SliceIntervalsPair extends Record {
    constructor(contentSlice, intervals, terminal) {
        super();
        this.contentSlice = contentSlice;
        this.intervals = intervals;
        this.terminal = terminal;
    }
}

export function SliceIntervalsPair$reflection() {
    return record_type("MatchResult.SliceIntervalsPair", [], SliceIntervalsPair, () => [["contentSlice", tuple_type(int32_type, int32_type)], ["intervals", array_type(TimeInterval$reflection())], ["terminal", bool_type]]);
}

export class MatchInfo extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SlicePairs", "GapPairs", "PositionPairs", "SliceIntervalsPairs"];
    }
}

export function MatchInfo$reflection() {
    return union_type("MatchResult.MatchInfo", [], MatchInfo, () => [[["Item", array_type(SlicePair$reflection())]], [["Item", array_type(GapPair$reflection())]], [["Item", array_type(PositionPair$reflection())]], [["Item", array_type(SliceIntervalsPair$reflection())]]]);
}

export class InfoTag extends Record {
    constructor(globalPosition, interval, kind, name, message) {
        super();
        this.globalPosition = (globalPosition | 0);
        this.interval = interval;
        this.kind = kind;
        this.name = name;
        this.message = message;
    }
}

export function InfoTag$reflection() {
    return record_type("MatchResult.InfoTag", [], InfoTag, () => [["globalPosition", int32_type], ["interval", TimeInterval$reflection()], ["kind", string_type], ["name", string_type], ["message", string_type]]);
}

export class MatchResult extends Record {
    constructor(matcherKind, matchInfo, infoTags) {
        super();
        this.matcherKind = matcherKind;
        this.matchInfo = matchInfo;
        this.infoTags = infoTags;
    }
}

export function MatchResult$reflection() {
    return record_type("MatchResult.MatchResult", [], MatchResult, () => [["matcherKind", string_type], ["matchInfo", MatchInfo$reflection()], ["infoTags", array_type(InfoTag$reflection())]]);
}


import { Record } from "../../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { class_type, record_type, obj_type, array_type, int32_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { searchSorted } from "../../../../sorted/search-sorted.fs.js";
import { Section } from "./sections.fs.js";
import { matchSectionTrimSilence, matchSectionInterpolate, matchSectionCommonWordSequences, SeqMatcherOptions, longWordMatcherOpts, seqMatcherOpts, matchSectionWordSequences, matchSectionContentCues, matchSectionLongWords, LongWordMatcherOptions } from "./matchers.fs.js";
import { extractDataFromOutput, TimestamperEngine, processWithMatchers } from "./engine.fs.js";
import { empty, singleton, collect, map, delay } from "../../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { indexed } from "../../../../../.fable/fable-library.3.1.0-beta-001/Array.js";

export class TimestamperResult extends Record {
    constructor(wordStartTimes, wordEndTimes, warnStartTimes, warnEndTimes, warnData, interpolationStartTimes, interpolationEndTimes, interpolationData) {
        super();
        this.wordStartTimes = wordStartTimes;
        this.wordEndTimes = wordEndTimes;
        this.warnStartTimes = warnStartTimes;
        this.warnEndTimes = warnEndTimes;
        this.warnData = warnData;
        this.interpolationStartTimes = interpolationStartTimes;
        this.interpolationEndTimes = interpolationEndTimes;
        this.interpolationData = interpolationData;
    }
}

export function TimestamperResult$reflection() {
    return record_type("Timestamper.TimestamperResult", [], TimestamperResult, () => [["wordStartTimes", array_type(int32_type)], ["wordEndTimes", array_type(int32_type)], ["warnStartTimes", array_type(int32_type)], ["warnEndTimes", array_type(int32_type)], ["warnData", array_type(obj_type)], ["interpolationStartTimes", array_type(int32_type)], ["interpolationEndTimes", array_type(int32_type)], ["interpolationData", array_type(obj_type)]]);
}

export class Timestamper0 {
    constructor() {
        this.contentSpan = (null);
        this.cuesSpan = (null);
        this.transcriptSpan = (null);
        this.audioRegionsSpan = (null);
        this.startTime = 0;
        this.endTime = 0;
        this.language = (null);
        this.engine = (null);
        // AUTO_ATTACH_INTERFACES_DIRECTIVE ;
    }
    getResult() {
        return Timestamper0__getResult(this)
    }
    run() {
        return Timestamper0__run(this)
    }
}

export function Timestamper0$reflection() {
    return class_type("Timestamper.Timestamper0", void 0, Timestamper0);
}

export function Timestamper0_$ctor() {
    return new Timestamper0();
}

function Timestamper0__postProcessWordIntervalsWithShortWarnings_7FC91187(this$, words, wordStartTimes, wordEndTimes, shortWarningIndexes, warnStartTimes, warnEndTimes) {
    const wordCount = wordStartTimes.length | 0;
    for (let idx = 0; idx <= (shortWarningIndexes.length - 1); idx++) {
        const shortWarningIndex = shortWarningIndexes[idx] | 0;
        const warningStart = warnStartTimes[shortWarningIndex] | 0;
        const warningEnd = warnEndTimes[shortWarningIndex] | 0;
        const warningMidpoint = (~(~((warningStart + warningEnd) / 2))) | 0;
        const warningDuration = (warningEnd - warningStart) | 0;
        let midpointWord = searchSorted(wordEndTimes, warningMidpoint, true) | 0;
        if (warningDuration === 0) {
            midpointWord = (midpointWord - 1);
        }
        const word = midpointWord | 0;
        const wordStart = wordStartTimes[word] | 0;
        const wordEnd = wordEndTimes[word] | 0;
        if (warningEnd !== wordEnd) {
            continue;
        }
        const wordDuration = (wordStart - wordEnd) | 0;
        const wordLen = words[word].length | 0;
        const minDuration = ((wordLen === 1) ? 30 : ((wordLen === 2) ? 50 : 80)) | 0;
        if (wordDuration >= minDuration) {
            continue;
        }
        const previousWord = (word - 1) | 0;
        const nextWord = (word + 1) | 0;
        if ((nextWord >= wordCount) ? true : (previousWord < 0)) {
            continue;
        }
        const testLeft = (wordStart - 50) | 0;
        const testRight = (wordEnd + 50) | 0;
        if (wordStartTimes[nextWord] > testRight) {
            if (wordEndTimes[previousWord] > testLeft) {
                continue;
            }
            const newWordStartTime = (wordEnd - minDuration) | 0;
            if ((newWordStartTime - 30) < wordStartTimes[previousWord]) {
                continue;
            }
            wordStartTimes[word] = newWordStartTime;
            wordEndTimes[previousWord] = (newWordStartTime - 1);
            continue;
        }
        if (wordEndTimes[previousWord] < testLeft) {
            const newWordEndTime = (wordStart + minDuration) | 0;
            if ((newWordEndTime + 30) > wordEndTimes[nextWord]) {
                continue;
            }
            wordEndTimes[word] = newWordEndTime;
            wordStartTimes[nextWord] = (newWordEndTime + 1);
            warnEndTimes[shortWarningIndex] = newWordEndTime;
            continue;
        }
        const wordMidpoint = warningMidpoint | 0;
        const halfDuration = (~(~(minDuration / 2))) | 0;
        const newWordEndTime_1 = (wordMidpoint + halfDuration) | 0;
        const newWordStartTime_1 = (wordMidpoint - halfDuration) | 0;
        if ((newWordEndTime_1 + 30) > wordEndTimes[nextWord]) {
            continue;
        }
        if ((newWordStartTime_1 - 30) < wordStartTimes[previousWord]) {
            continue;
        }
        wordStartTimes[word] = newWordStartTime_1;
        wordEndTimes[word] = newWordEndTime_1;
        wordStartTimes[nextWord] = (newWordEndTime_1 + 1);
        wordEndTimes[previousWord] = (newWordStartTime_1 - 1);
        warnStartTimes[shortWarningIndex] = newWordStartTime_1;
        warnEndTimes[shortWarningIndex] = newWordEndTime_1;
    }
}

function Timestamper0__run(this$) {
    let options_1, language, options_2, options_3;
    const section = new Section(this$.contentSpan, this$.transcriptSpan, this$.cuesSpan, this$.audioRegionsSpan, this$.startTime, this$.endTime);
    let longWordsMatcher1;
    const options = new LongWordMatcherOptions(10, 8, 0);
    longWordsMatcher1 = ((section_1) => matchSectionLongWords(options, section_1));
    const cueMatcher = matchSectionContentCues;
    let output = processWithMatchers([section], [cueMatcher]);
    output = processWithMatchers(output, [longWordsMatcher1]);
    const matchers = [(section_3) => matchSectionWordSequences(seqMatcherOpts, section_3), (section_4) => matchSectionLongWords(longWordMatcherOpts, section_4), (options_1 = (new SeqMatcherOptions(seqMatcherOpts.seqMinLength, seqMatcherOpts.seqMinRatio, false, true, seqMatcherOpts.seqLevenshteinCutoff)), (section_5) => matchSectionWordSequences(options_1, section_5)), (language = this$.language, (section_6) => matchSectionCommonWordSequences(seqMatcherOpts, language, section_6)), (options_2 = (new LongWordMatcherOptions(4, 3, 1)), (section_7) => matchSectionLongWords(options_2, section_7)), (options_3 = (new SeqMatcherOptions(seqMatcherOpts.seqMinLength, seqMatcherOpts.seqMinRatio, seqMatcherOpts.seqMatchEnds, seqMatcherOpts.seqMatchLongest, 0.8)), (section_8) => matchSectionWordSequences(options_3, section_8)), (section_9) => matchSectionInterpolate(false, section_9), matchSectionTrimSilence, (section_11) => matchSectionInterpolate(true, section_11)];
    this$.engine = TimestamperEngine();
    this$.engine.setMatchers(matchers);
    this$.engine.routeOutputs(output);
    this$.engine.run();
}

function Timestamper0__getResult(this$) {
    const result0 = extractDataFromOutput(this$.engine.outputQueue, this$.contentSpan.data.word, this$.endTime);
    const words = result0.words;
    const wordIntervals = result0.wordIntervals;
    const wordStartTimes = Array.from(delay(() => map((i) => i.startTime, wordIntervals)));
    const wordEndTimes = Array.from(delay(() => map((i_1) => i_1.endTime, wordIntervals)));
    const warnData = result0.warningData;
    const warnIntervals = result0.warningIntervals;
    const warnStartTimes = Array.from(delay(() => map((i_2) => i_2.startTime, warnIntervals)));
    const warnEndTimes = Array.from(delay(() => map((i_3) => i_3.endTime, warnIntervals)));
    const interpolationData = result0.interpolateData;
    const interpolationIntervals = result0.interpolateIntervals;
    const interpolationStartTimes = Array.from(delay(() => map((i_4) => i_4.startTime, interpolationIntervals)));
    const interpolationEndTimes = Array.from(delay(() => map((i_5) => i_5.endTime, interpolationIntervals)));
    const shortWarningIndexes = Int32Array.from(delay(() => collect((matchValue) => {
        const warning = matchValue[1];
        const i_6 = matchValue[0] | 0;
        return ((warning) === "short") ? singleton(i_6) : empty();
    }, indexed(warnData))));
    Timestamper0__postProcessWordIntervalsWithShortWarnings_7FC91187(this$, words, wordStartTimes, wordEndTimes, shortWarningIndexes, warnStartTimes, warnEndTimes);
    return new TimestamperResult(wordStartTimes, wordEndTimes, warnStartTimes, warnEndTimes, warnData, interpolationStartTimes, interpolationEndTimes, interpolationData);
}

export function Timestamper() {
    return Timestamper0_$ctor();
}

// JS BOILERPLATE GENERATED
 
import { makeObservable, observable } from 'mobx';
import { ElementId, Element } from '../basic-types';
import { ElementList, EmptyElementList } from '../elements/element-list';
import { Navigation, NavigationPoint } from '../navigation/timeline-navigator';
import { Sorted } from '../sorted/sorted';
import { AudioTransport, TransportState } from './audio-transport';

export class PlayerState {
  navigationPoint: NavigationPoint;
  constructor() {
    this.navigationPoint = null;
    makeObservable(this, {
      navigationPoint: observable.ref,
    });
  }
  // }
}

// // TODO change adjustPlaybackRateAction param to enum?

// [<Emit("!!$0")>]

export class StructuredPlayer {
  playerState: PlayerState;
  audioTransport: AudioTransport;
  transportState: TransportState;
  nav: Navigation;
  elements: ElementList;
  peekDuration = 1500;
  playbackRateValues: number[];
  playbackRateSelect: number;

  constructor(playerState0, audioTransport0, transportState0, navigation0) {
    this.playerState = playerState0;
    this.audioTransport = audioTransport0;
    this.transportState = transportState0;
    this.nav = navigation0;
    this.elements = EmptyElementList;
    this.peekDuration = 1500;
    this.playbackRateValues = [0.7, 0.8, 0.9, 1.0];
    this.playbackRateSelect = 3;
  }

  setElements(elements0) {
    this.elements = elements0;
  }

  getAudioPosition() {
    return this.audioTransport.audioPosition;
  }

  play() {
    this.audioTransport.play();
  }

  playForward() {
    this.play();
  }

  pause(keepPauseAfter = false) {
    this.audioTransport.pause(keepPauseAfter);
  }

  seek(position: number, keepPauseAfter = false) {
    this.audioTransport.seek(position);
    if (!this.transportState.isPlaying && !keepPauseAfter) {
      this.transportState.audioRestartPosition = position;
    }
  }

  rewind() {
    if (this.transportState.isPlaying) {
      this.pause();
    } else {
      this.audioTransport.rewind();
      if (!this.transportState.isPlaying) {
        this.play();
      }
    }
  }

  pauseThenPlayAt(delay: number, position: number, keepPauseAfter = false) {
    this.audioTransport.pause(keepPauseAfter);
    this.seek(position, keepPauseAfter);
    setTimeout(() => this.play(), delay);
  }

  togglePlay() {
    if (this.transportState.isPlaying) {
      this.pause(false);
    } else {
      this.playForward();
    }
  }

  seekElement(elementId: ElementId) {
    this.seek(this.elements.time(elementId), false);
  }

  playElement(elementId) {
    this.seekElement(elementId);
    this.play();
  }

  playPeek() {
    if (this.transportState.isPlaying && this.transportState.audioRestartPosition !== 0) {
      this.audioTransport.seek(this.transportState.audioRestartPosition);
    } else {
      this.audioTransport.setPauseAfter(this.getAudioPosition() + this.peekDuration);
      this.transportState.audioRestartPosition = this.getAudioPosition();
      this.play();
    }
  }

  playPeekBack() {
    if (this.transportState.isPlaying && this.transportState.audioRestartPosition !== 0) {
      const playFrom = this.transportState.audioRestartPosition - this.peekDuration;
      this.pauseThenPlayAt(100, playFrom, true);
    } else if (this.transportState.audioRestartPosition !== 0) {
      this.audioTransport.setPauseAfter(this.transportState.audioRestartPosition);
      this.seek(this.transportState.audioRestartPosition - this.peekDuration, true);
      this.play();
    }
  }

  nudge(ms: number) {
    if (!this.transportState.isPlaying) {
      this.seek(this.transportState.audioPosition + ms, false);
    } else if (this.transportState.audioRestartPosition !== 0) {
      const nudgeTo = this.transportState.audioRestartPosition + ms;
      this.transportState.audioRestartPosition = nudgeTo;
      this.pauseThenPlayAt(100, nudgeTo, false);
      this.audioTransport.setPauseAfter(nudgeTo + this.peekDuration);
    }
  }

  // TODO expose move method on navigators and eliminate duplicate code?
  next() {
    const currentPoint = this.playerState.navigationPoint;
    const nextPoint = this.nav.next(currentPoint);
    if (nextPoint !== currentPoint) {
      this.seek(nextPoint.position);
      this.playerState.navigationPoint = nextPoint;
    }
  }

  prev() {
    const currentPoint = this.playerState.navigationPoint;
    const nextPoint = this.nav.prev(currentPoint);
    if (nextPoint !== currentPoint) {
      this.seek(nextPoint.position);
      this.playerState.navigationPoint = nextPoint;
    }
  }

  nextClosest() {
    const point = this.playerState.navigationPoint;
    if (point) {
      const nextPoint = this.nav.nextClosest(point, this.getAudioPosition());
      if (nextPoint) {
        this.playerState.navigationPoint = nextPoint;
        if (this.transportState.isPlaying) {
          this.pauseThenPlayAt(600, nextPoint.position);
        } else {
          this.seek(nextPoint.position);
        }
      }
    }
  }

  prevClosest() {
    const point = this.playerState.navigationPoint;
    if (point) {
      const tolerance = this.transportState.isPlaying ? 300 : 10;
      const nextPoint = this.nav.prevClosest(point, this.getAudioPosition() - tolerance);
      // TODO factor out this code also in nextClosest??
      if (nextPoint) {
        this.playerState.navigationPoint = nextPoint;
        if (this.transportState.isPlaying) {
          this.pauseThenPlayAt(600, nextPoint.position);
        } else {
          this.seek(nextPoint.position);
        }
      }
    }
  }

  playSection() {
    const point = this.playerState.navigationPoint;
    if (point) {
      const nextPoint = this.nav.nextClosest(point, this.getAudioPosition() + 50);
      if (nextPoint) {
        this.audioTransport.setPauseAfter(nextPoint.position);
        this.audioTransport.clearAudioRestartPosition();
        this.play();
      }
    }
  }

  seekToNavigationPoint(point: NavigationPoint) {
    if (point) {
      this.seek(point.position);
    }
  }

  setNavigationPoint(point: NavigationPoint) {
    this.playerState.navigationPoint = point;
    this.seekToNavigationPoint(point);
  }

  navigate(key: string, index: number) {
    const navigator = this.nav.getNavigatorForKey(key);
    const point = navigator.navigationPoint(index);
    this.setNavigationPoint(point);
  }

  adjustPlaybackRateAction(direction: boolean) {
    const select = direction ? this.playbackRateSelect + 1 : this.playbackRateSelect - 1;
    if (select >= 0 && select < this.playbackRateValues.length) {
      this.playbackRateSelect = select;
      this.audioTransport.setPlaybackRate(this.playbackRateValues[this.playbackRateSelect]);
    }
  }

  setKerningPoints(points: Sorted) {
    this.audioTransport.setKerningPoints(points);
  }

  setKerningDuration(duration: number) {
    this.audioTransport.setKerningDuration(duration);
  }

  setKerning(points: Sorted, duration: number) {
    this.audioTransport.setKerning(points, duration);
  }

  kerningEnable(enable: boolean) {
    this.audioTransport.kerningEnable(enable);
  }
}

//
// Provides handy references to all the collection docs for a given episode key.
//
// should perhaps be renamed to something like EpisodeDocPaths
//

export class DbPaths {
  constructor(db, key) {
    this.db = db;
    this.key = key;
  }

  get rootRef() {
    return this.db;
  }

  collectionDocRef(path) {
    return this.rootRef.collection(path).doc(this.key);
  }

  get metadataDocRef() {
    return this.collectionDocRef('Episode__metadata');
  }

  get chaatMetadataDocRef() {
    return this.collectionDocRef('Episode__chaatMetadata');
  }

  get verbatimDocRef() {
    return this.collectionDocRef('Episode__verbatim');
  }

  get structuralDocRef() {
    return this.collectionDocRef('Episode__structural');
  }

  get wordGroupsDocRef() {
    return this.collectionDocRef('Episode__wordGroups');
  }

  get translationsDocRef() {
    return this.collectionDocRef('Episode__translations');
  }

  get metadataBlocksDocRef() {
    return this.collectionDocRef('Episode__metadataBlocks');
  }

  // get threadsDocRef() {
  //   return this.collectionDocRef("Episode__threads");
  // }

  get chaatCuesDocRef() {
    return this.collectionDocRef('Episode__chaatCues');
  }

  get chaatTimestampsDocRef() {
    return this.collectionDocRef('Episode__chaatTimestamps');
  }

  get chaatAudioAnalysisDocRef() {
    return this.collectionDocRef('Episode__chaatAudioAnalysis');
  }

  get chaatAudioRegionsDocRef() {
    return this.collectionDocRef('Episode__chaatAudioRegions');
  }

  get chaatAudioMarkersDocRef() {
    return this.collectionDocRef('Episode__chaatAudioMarkers');
  }

  get chaatSpeechTranscriptsDocRef() {
    return this.collectionDocRef('Episode__chaatSpeechTranscripts');
  }

  get sentenceVersionsDocRef() {
    return this.collectionDocRef('Episode__sentenceVersions');
  }

  get wordGroupVersionsDocRef() {
    return this.collectionDocRef('Episode__wordGroupVersions');
  }

  get structuralVersionsDocRef() {
    return this.collectionDocRef('Episode__structuralVersions');
  }

  get translationVersionsDocRef() {
    return this.collectionDocRef('Episode__translationVersions');
  }

  get warningSuppressionsDocRef() {
    return this.collectionDocRef('Episode__warningSuppressions');
  }

  get chaatAudioProcessingJobDocRef() {
    return this.collectionDocRef('Episode__chaatAudioProcessingJob');
  }

  get chaatTranscriptionJobDocRef() {
    return this.collectionDocRef('Episode__chaatTranscriptionJob');
  }

  get chaatSignoffsDocRef() {
    return this.collectionDocRef('Episode__chaatSignoffs');
  }
}

// "Episode__metadata"
// "Episode__chaatMetadata"
// "Episode__verbatim"
// "Episode__structural"
// "Episode__wordGroups"
// "Episode__translations"
// "Episode__metadataBlocks"
// "Episode__threads"
// "Episode__chaatCues"
// "Episode__chaatTimestamps"
// "Episode__chaatAudioAnalysis"
// "Episode__chaatAudioRegions"
// "Episode__chaatAudioMarkers"
// "Episode__chaatSpeechTranscripts"
// "Episode__sentenceVersions"
// "Episode__wordGroupVersions"
// "Episode__structuralVersions"
// "Episode__warningSuppressions"

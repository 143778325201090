import { Element, ElementKind } from '../basic-types';

// }

export class Precedence {
  precedence: string[];
  keyToPrecedenceLevel: any;
  constructor(precedence0: string[]) {
    this.precedence = precedence0;
    this.keyToPrecedenceLevel = {};

    for (const [index, kind] of this.precedence.entries()) {
      this.keyToPrecedenceLevel[kind] = index;
    }
  }

  leastPrecedenceLevel() {
    return this.precedence.length;
  }

  precedenceProjection(kind: string): number {
    return this.keyToPrecedenceLevel[kind];
  }

  precedenceAtLeastFilter(kind: ElementKind): (el: Element) => boolean {
    const level: number = this.keyToPrecedenceLevel[kind];
    return (el: Element) => this.keyToPrecedenceLevel[el.kind] >= level;
  }

  get functions() {
    return {
      precedenceProjection: kind => this.precedenceProjection(kind),
    };
  }
}

import ASCIIFolder from 'fold-to-ascii';
import {
  Element,
  ElementId,
  IdRange,
  IndexRange,
  NO_INDEX,
  SpanAnchors,
  SpanExclusiveAnchors,
} from './basic-types';
import { EKinds } from './elements/element-kinds';
import { ElementList } from './elements/element-list';

// // TODO a good many of these functions work on the editorial models using wordId anchors but could have corresponding impls using word indexes

// [<ImportMember("./content-funcs.js")>]

// [<ImportMember("./content-funcs.js")>]

// [<ImportMember("./content-funcs.js")>]

export function wordIndexRangeToWordStrings(indexRange: IndexRange, words: ElementList) {
  // TODO strong typing for this instead?
  const wordElements = words.elements;
  const result = [];
  // TODO create better way to iterate or map number range?
  for (let i = indexRange.starts; i <= indexRange.ends; i++) {
    result.push(wordElements[i]['text']);
  }
  return result;
}

export function wordIdRangeToWordStrings(idRange: IdRange, words: ElementList): string[] {
  // TODO stronger typing?
  const wordsRange = words.idRangeAsElements(idRange);
  return wordsRange.map(word => word['text']);
}

export function wordStringsToWordElements(
  words: string[],
  startIndex: number,
  indexToId: any
): Element[] {
  const wordElements = [];
  for (const [index, word] of words.entries()) {
    wordElements.push({ kind: EKinds.WORD, id: indexToId[startIndex + index], text: word });
  }
  return wordElements;
}

export function getContentStringFromWordIdMaxChars(
  wordId: ElementId,
  words: ElementList,
  maxChars: number
) {
  const index = words.getIndex(wordId);
  if (index === NO_INDEX) {
    return '';
  }

  const resultWords = [];
  let charCount = 0;
  let i = index;
  const wordElements = words.elements;
  while (charCount < maxChars && i < wordElements.length) {
    const word = wordElements[i];
    // TODO strong typing?
    const text: string = word['text'];
    resultWords.push(text);
    charCount += text.length;
    i++;
  }

  return resultWords.join(' ');
}

export function getSentenceWordIdRange(sentence: Element, words: ElementList) {
  // TODO make generic handle both inclusive and exclusive with sniff of anchor properties??
  const anchors = <SpanExclusiveAnchors>sentence.anchors;
  const startWordId = anchors.startWordId;
  const endWordId = words.prevId(anchors.endWordIdExclusive);
  return { starts: startWordId, ends: endWordId };
}

export function getSentenceWordStrings(sentence: Element, words: ElementList): string[] {
  const wordRange = getSentenceWordIdRange(sentence, words);
  return wordIdRangeToWordStrings(wordRange, words);
}

export function getSentencesWordStrings(sentences: ElementList): string[] {
  const result = [];
  for (const sentence of sentences.elements) {
    result.push(...getSentenceWordStrings(sentence, sentences.words));
  }
  return result;
}

export function getElementEditableContentString(element: Element, words: ElementList): string {
  // TODO use for word groups also to get transcript content string?
  if (element.kind === EKinds.SENTENCE) {
    return getSentenceWordStrings(element, words).join(' ');
  } else if (element.kind === EKinds.WORD_GROUP) {
    throw new Error('word groups do not have editable content');
  } else {
    return element.content;
  }
}

export function getWordGroupTranscriptText(group: Element, words0: ElementList): string {
  // TODO need to back off for deletes on endWordId same as in content roots logic
  const anchors = <SpanAnchors>group.anchors;
  const wordRange = { starts: anchors.startWordId, ends: anchors.endWordId };
  const words = wordIdRangeToWordStrings(wordRange, words0);
  return words.join(' ');
}

const wordGroupKindSymbols = {
  VOCAB: '',
  TRICKY: '~',
  SIC: '$',
  // |}
};

export function getWordGroupJWScriptRepresentation(group: Element, words: ElementList) {
  let result = '<';
  const note: string = group.content['note'] ?? '';
  const delimiter = note.includes('=') ? '|' : '=';
  result += wordGroupKindSymbols[group.subKind];
  result += getWordGroupTranscriptText(group, words);
  if (note) {
    result += delimiter + note;
  }
  result += '>';
  return result;
}

export function getElementVersionDescriptiveContent(element: Element, words: ElementList): string {
  const kind = element.kind;
  if (kind === EKinds.WORD_GROUP) {
    return getWordGroupJWScriptRepresentation(element, words);
  } else if (kind === EKinds.SENTENCE) {
    const sentenceWords: any[] = element['words'];
    return sentenceWords.map(word => word.text).join(' ');
  } else {
    return element.content;
  }
}

const punctuationRegex = /[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~¡¿—–]/g;
const standaloneEmDashRegex = /\s+--\s+/g;
const joinedEmDashRegex = /---/g;
// TODO make complete equivalence of punctuation here with punctuation in jw_script_processor.py
const standalonePunctuationRegex = /\s+([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s+/g;
const trailingStandalonePunctuationRegex = /\s+([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s+$/g;
const trailingPunctuationRegex = /([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*$/g;
const leadingPunctuationRegex = /^([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*/g;

const whitespaceRegex = /\s+/g;
const startingWhitespaceRegex = /^\s+/g;
const trailingWhitespaceRegex = /\s+$/g;

export function joinStandaloneEmDashes(str: string) {
  if (!str) {
    return str;
  }
  // TODO with replacing with space hyphenated words may count as two words, what is correct?
  return str.replace(standaloneEmDashRegex, '--- ');
}

export function restoreEmDashes(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(joinedEmDashRegex, ' --');
}

export function joinStandalonePunctuation(str: string) {
  if (!str) {
    return str;
  }
  str = str.replace(standalonePunctuationRegex, '$1 ');
  // TODO not sure will handle linefeed correctly, look JS regex doc and test
  return str.replace(trailingStandalonePunctuationRegex, '$1');
  // TODO need to handle standalone quotes " ' differently open should join with following and closing with preceeding
  // TODO or should just have alarm and message when trying save edit with standalone quotes to simplify implementation?
}

export function normalizeWhiteSpace(str: string) {
  if (!str) {
    return str;
  }
  str = str.replace(whitespaceRegex, ' ');
  if (str === ' ') {
    str = '';
  }
  str = str.replace(startingWhitespaceRegex, '');
  return str.replace(trailingWhitespaceRegex, '');
}

export function normalizePunctuation(str: string) {
  str = joinStandaloneEmDashes(str);
  return joinStandalonePunctuation(str);
}

export function normalizeTranscriptText(str: string) {
  str = normalizePunctuation(str);
  return normalizeWhiteSpace(str);
}

export function validateTranscriptText(str: string) {
  // TODO check for standalone quotes or other things not dealt with by automatic normalization
}

function getWords(str: string) {
  return str.split(' ');
}

export function getTranscriptWordsFromString(str: string) {
  if (!str) {
    return [];
  }
  str = normalizeTranscriptText(str);
  if (!str) {
    return [];
  }
  const words = getWords(str);
  return words.map(word => restoreEmDashes(word));
}

export function stripTrailingPunctuation(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(trailingPunctuationRegex, '');
}

export function stripLeadingPunctuation(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(leadingPunctuationRegex, '');
}

export function trimPunctuation(str: string) {
  str = stripTrailingPunctuation(str);
  return stripLeadingPunctuation(str);
}

export function strongNormalizeWord(word: string) {
  word = word.replace(punctuationRegex, '');
  word = ASCIIFolder.foldReplacing(word);
  return word.toLowerCase();
}

export function strongNormalizeWordArray(words: string[]) {
  return words.map(word => strongNormalizeWord(word));
}

import js$002Dlevenshtein from "js-levenshtein";
import { compare as compare_1, min, comparePrimitives, max } from "../../../../../.fable/fable-library.3.1.0-beta-001/Util.js";
import { Record } from "../../../../../.fable/fable-library.3.1.0-beta-001/Types.js";
import { record_type, bool_type, float64_type, int32_type } from "../../../../../.fable/fable-library.3.1.0-beta-001/Reflection.js";
import { audioRegionSpanIsContinuousAudio, ContentSpan__get_words, TranscriptSpan__get_words, audioRegionsSpanIntervalIsSilence, audioRegionsSpanFindAudioIntervalContains } from "./spans.fs.js";
import { append, zip, sortInPlaceBy, indexed, reverse } from "../../../../../.fable/fable-library.3.1.0-beta-001/Array.js";
import { System_Array__$005B$005D$1_extend_5975E3, System_Array__$005B$005D$1_indexOf_1505, Fable_Core_JS_Map$2__Map$2_setDefault_2A0A0, System_Array__$005B$005D$1_append_1505, System_Array__$005B$005D$1_get_lastIndex } from "../../../../basic-types.fs.js";
import { join } from "../../../../../.fable/fable-library.3.1.0-beta-001/String.js";
import { System_Array__$005B$005D$1_slice_Z3227AE51 } from "./util.fs.js";
import lodash from "lodash";
import { op_UnaryNegation_Int32 } from "../../../../../.fable/fable-library.3.1.0-beta-001/Int32.js";
import { GapPair, SliceIntervalsPair, InfoTag, PositionPair, MatchResult, SlicePair, MatchInfo } from "./match-result.fs.js";
import { map, empty, singleton, collect, delay } from "../../../../../.fable/fable-library.3.1.0-beta-001/Seq.js";
import { TimeInterval } from "./timestamper-types.fs.js";
import { searchSorted } from "../../../../sorted/search-sorted.fs.js";

export function levenshteinRatio(a, b) {
    const distance = js$002Dlevenshtein(a, b) | 0;
    const length = max(comparePrimitives, a.length, b.length) | 0;
    return (length - distance) / length;
}

export class SeqMatcherOptions extends Record {
    constructor(seqMinLength, seqMinRatio, seqMatchEnds, seqMatchLongest, seqLevenshteinCutoff) {
        super();
        this.seqMinLength = (seqMinLength | 0);
        this.seqMinRatio = seqMinRatio;
        this.seqMatchEnds = seqMatchEnds;
        this.seqMatchLongest = seqMatchLongest;
        this.seqLevenshteinCutoff = seqLevenshteinCutoff;
    }
}

export function SeqMatcherOptions$reflection() {
    return record_type("Matchers.SeqMatcherOptions", [], SeqMatcherOptions, () => [["seqMinLength", int32_type], ["seqMinRatio", float64_type], ["seqMatchEnds", bool_type], ["seqMatchLongest", bool_type], ["seqLevenshteinCutoff", float64_type]]);
}

export const seqMatcherOpts = new SeqMatcherOptions(4, 0.3, true, false, 0);

export class LongWordMatcherOptions extends Record {
    constructor(minWordLength, prefixTest, maxMatch) {
        super();
        this.minWordLength = (minWordLength | 0);
        this.prefixTest = (prefixTest | 0);
        this.maxMatch = (maxMatch | 0);
    }
}

export function LongWordMatcherOptions$reflection() {
    return record_type("Matchers.LongWordMatcherOptions", [], LongWordMatcherOptions, () => [["minWordLength", int32_type], ["prefixTest", int32_type], ["maxMatch", int32_type]]);
}

export const longWordMatcherOpts = new LongWordMatcherOptions(8, 6, 0);

export const spanishCommonList = ["un", "y", "el", "de", "es", "la", "a", "se", "los", "las", "una", "uno", "unos", "que", "yo", "muy", "mi", "hay", "no", "por", "en", "al", "lo", "le", "esto", "esta", "con", "para", "su", "te", "si", "ser", "bien", "yo", "del", "todo", "soy", "pero", "eso", "vamos", "estoy", "tengo", "ahora", "algo", "tenda", "nada", "nos", "quando", "porque", "como", "entonces", "estaban", "estaba"];

export const spanishCommon = new Set(spanishCommonList);

export const englishCommonList = ["i", "you", "we", "us", "our", "they", "them", "and", "the", "or", "if", "in", "of", "that", "was", "his", "her", "she", "he", "that", "it", "was", "for", "that", "had", "not", "be", "on", "this", "which", "have", "from", "but", "all", "were", "my", "are", "me", "one", "their", "so", "an", "said", "would", "been", "will", "by", "has", "any", "do", "your", "what", "has", "man", "could", "other", "than", "then", "some", "very", "about"];

export const englishCommon = new Set(englishCommonList);

export const germanCommonList = ["ich", "ist", "nicht", "sie", "du", "das", "die", "es", "und", "der", "zu", "ein", "in", "wir", "mir", "mit", "was", "den", "mich", "auf", "dass", "er", "eine", "des", "wurde", "als", "fur", "dem", "sich", "an", "war", "nach", "am", "auch", "aus", "bei", "bis", "zum", "einer", "durch", "einem", "werden", "sind", "zur", "wird", "uber", "einen", "um", "oder", "wurden", "unter", "wie", "jahr", "seine", "vor", "vom", "nur", "ab", "seit", "seiner", "sowie", "noch", "sein", "hatte", "zwei", "jahre", "diese", "dieser", "aber", "zwischen", "hat", "man", "haben"];

export const germanCommon = new Set(germanCommonList);

export const portugueseCommonList = ["que", "nao", "de", "um", "para", "eu", "se", "me", "uma", "esta", "com", "do", "por", "te", "os", "bem", "em", "ele", "isso", "mas", "da", "como", "no", "sim", "as", "mais", "na", "meu", "voce", "aqui", "muito", "foi", "estou", "vamos", "ela", "fazer", "vai", "isto", "ja", "tem", "so", "minha", "nos", "ser", "tudo", "ao", "tenho", "vou", "sei", "agora", "ha", "la", "tu", "quando", "porque", "estas", "quem", "onde", "nada", "ter"];

export const portugueseCommon = new Set(portugueseCommonList);

export const common = {
    english: englishCommon,
    german: germanCommon,
    portuguese: portugueseCommon,
    spanish: spanishCommon,
};

export function matchSequences(a, b, compare) {
    let i = 0;
    while ((i < a.length) ? (i < b.length) : false) {
        const equal = compare([a[i], b[i]]);
        if (!equal) {
            break;
        }
        else {
            i = (i + 1);
        }
    }
    return i | 0;
}

export function cueTimeToInterval(timestamp, audioRegions) {
    const patternInput = audioRegionsSpanFindAudioIntervalContains(audioRegions, timestamp);
    const startTime = patternInput[0] | 0;
    const regionIdx = patternInput[2] | 0;
    const endTime = patternInput[1] | 0;
    if (!audioRegionsSpanIntervalIsSilence(audioRegions, regionIdx)) {
        return [timestamp, timestamp];
    }
    else {
        return [startTime, endTime];
    }
}

export function matchHeadTail(a, b, compare) {
    const shortestLen = min(comparePrimitives, a.length, b.length) | 0;
    const forwardMatchLen = matchSequences(a, b, compare) | 0;
    if (forwardMatchLen === shortestLen) {
        const slice = [0, forwardMatchLen];
        return [forwardMatchLen, slice, slice];
    }
    const reverseA = reverse(a);
    const reverseB = reverse(b);
    const reverseMatchLen = matchSequences(reverseA, reverseB, compare) | 0;
    const forward = forwardMatchLen > reverseMatchLen;
    const matchLen = (forward ? forwardMatchLen : reverseMatchLen) | 0;
    if (forward) {
        return [[0, matchLen], [0, matchLen], matchLen];
    }
    else {
        return [[a.length - matchLen, System_Array__$005B$005D$1_get_lastIndex(a)], [b.length - matchLen, System_Array__$005B$005D$1_get_lastIndex(b)], matchLen];
    }
}

export function wordsIndex(words) {
    const result = new Map([]);
    const arr = indexed(words);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const word = forLoopVar[1];
        const i = forLoopVar[0] | 0;
        System_Array__$005B$005D$1_append_1505(Fable_Core_JS_Map$2__Map$2_setDefault_2A0A0(result, word, []), i);
    }
    return result;
}

export function wordSpans(words) {
    let current = 0;
    const spans = [];
    for (let idx = 0; idx <= (words.length - 1); idx++) {
        const word = words[idx];
        const length = word.length | 0;
        System_Array__$005B$005D$1_append_1505(spans, [current, (current + length) - 1]);
        current = (current + length);
    }
    return spans;
}

export function substringCount(needle, haystack) {
    const loop = (count_mut, index_mut) => {
        loop:
        while (true) {
            const count = count_mut, index = index_mut;
            if (index >= haystack.length) {
                return count | 0;
            }
            else {
                const matchValue = haystack.indexOf(needle, index) | 0;
                if (matchValue === -1) {
                    return count | 0;
                }
                else {
                    const idx = matchValue | 0;
                    count_mut = (count + 1);
                    index_mut = (idx + 1);
                    continue loop;
                }
            }
            break;
        }
    };
    if (needle.length === 0) {
        return 0;
    }
    else {
        return loop(0, 0) | 0;
    }
}

export function underscoreJoin(a) {
    return join("_", a);
}

export function patternCount(a, patternSlice) {
    const matchStr = underscoreJoin(System_Array__$005B$005D$1_slice_Z3227AE51(a, patternSlice));
    const contentStr = underscoreJoin(a);
    return substringCount(matchStr, contentStr) | 0;
}

export class LCSResult extends Record {
    constructor(aPos, bPos, matchLen) {
        super();
        this.aPos = (aPos | 0);
        this.bPos = (bPos | 0);
        this.matchLen = (matchLen | 0);
    }
}

export function LCSResult$reflection() {
    return record_type("Matchers.LCSResult", [], LCSResult, () => [["aPos", int32_type], ["bPos", int32_type], ["matchLen", int32_type]]);
}

export function findLCS(a, b) {
    let matchLen = 0;
    const storage = [];
    const aLen = a.length | 0;
    const bLen = b.length | 0;
    for (let i = 0; i <= System_Array__$005B$005D$1_get_lastIndex(a); i++) {
        const ind = System_Array__$005B$005D$1_indexOf_1505(b, a[i]) | 0;
        if (ind === -1) {
            continue;
        }
        let j = i | 0;
        let k = ind | 0;
        while ((j < aLen) ? (k < bLen) : false) {
            if (b[k] === a[j]) {
                matchLen = (matchLen + 1);
            }
            else {
                System_Array__$005B$005D$1_append_1505(storage, new Int32Array([matchLen, j - matchLen, k - matchLen]));
                matchLen = 0;
            }
            j = (j + 1);
            k = (k + 1);
        }
        System_Array__$005B$005D$1_append_1505(storage, new Int32Array([matchLen, j - matchLen, k - matchLen]));
        matchLen = 0;
    }
    if (!(lodash.isEmpty(storage))) {
        sortInPlaceBy((m) => op_UnaryNegation_Int32(m[0]), storage, {
            Compare: comparePrimitives,
        });
        const result = storage[0];
        return new LCSResult(result[1], result[2], result[0]);
    }
    else {
        return null;
    }
}

export function findLongestMatch(a, b) {
    const lcs = findLCS(a, b);
    if (lcs) {
        return [[lcs.aPos, (lcs.aPos + lcs.matchLen) - 1], [lcs.bPos, (lcs.bPos + lcs.matchLen) - 1], lcs.matchLen];
    }
    else {
        return [null, null, 0];
    }
}

export function checkMatchLength(a, matchLen, options) {
    if (a.length === 0) {
        return false;
    }
    else {
        const matchRatio = matchLen / a.length;
        if (matchLen > options.seqMinLength) {
            return true;
        }
        else {
            return matchRatio > options.seqMinRatio;
        }
    }
}

export function tryMatch(a, b, options) {
    const levenshteinMatch = (tupledArg) => {
        const str1 = tupledArg[0];
        const str2 = tupledArg[1];
        const score = levenshteinRatio(str1, str2);
        return score > options.seqLevenshteinCutoff;
    };
    const eqCompare = (tupledArg_1) => {
        const a_1 = tupledArg_1[0];
        const b_1 = tupledArg_1[1];
        return a_1 === b_1;
    };
    const matchFunc = (options.seqLevenshteinCutoff > 0.1) ? levenshteinMatch : eqCompare;
    if (options.seqMatchEnds) {
        const patternInput = matchHeadTail(a, b, matchFunc);
        const matchLen = patternInput[2] | 0;
        const bSlice = patternInput[1];
        const aSlice = patternInput[0];
        if (checkMatchLength(a, matchLen, options)) {
            return [aSlice, bSlice];
        }
        else {
            return [null, null];
        }
    }
    else if (options.seqMatchLongest) {
        const patternInput_1 = findLongestMatch(a, b);
        const matchLen_1 = patternInput_1[2] | 0;
        const bSlice_1 = patternInput_1[1];
        const aSlice_1 = patternInput_1[0];
        if (checkMatchLength(a, matchLen_1, options)) {
            return [aSlice_1, bSlice_1];
        }
        else {
            return [null, null];
        }
    }
    else {
        return [null, null];
    }
}

export function wholeSlice(a) {
    return [0, System_Array__$005B$005D$1_get_lastIndex(a)];
}

export function matchSectionWordSequences(options, section) {
    const transcriptWords = TranscriptSpan__get_words(section.transcript);
    const contentWords = ContentSpan__get_words(section.content);
    if (contentWords.length > 100) {
        return (null);
    }
    const patternInput = tryMatch(contentWords, transcriptWords, options);
    const transcriptSlice = patternInput[1];
    const contentSlice = patternInput[0];
    if (lodash.isNull(contentSlice)) {
        return (null);
    }
    return new MatchResult("wordsequence", new MatchInfo(0, [new SlicePair(contentSlice, transcriptSlice)]), []);
}

export function matchSectionCommonWordSequences(options, language, section) {
    const transcriptWords = TranscriptSpan__get_words(section.transcript);
    const contentWords = ContentSpan__get_words(section.content);
    const commonSet = (common[language]);
    if (contentWords.length > 25) {
        return (null);
    }
    const contentIdxs = Int32Array.from(delay(() => collect((matchValue) => {
        const w = matchValue[1];
        const i = matchValue[0] | 0;
        return commonSet.has(w) ? singleton(i) : empty();
    }, indexed(contentWords))));
    const transcriptIdxs = Int32Array.from(delay(() => collect((matchValue_1) => {
        const w_1 = matchValue_1[1];
        const i_1 = matchValue_1[0] | 0;
        return commonSet.has(w_1) ? singleton(i_1) : empty();
    }, indexed(transcriptWords))));
    const seq1 = Array.from(delay(() => map((i_2) => contentWords[i_2], contentIdxs)));
    const seq2 = Array.from(delay(() => map((i_3) => transcriptWords[i_3], transcriptIdxs)));
    const patternInput = tryMatch(seq1, seq2, options);
    const transcriptSlice = patternInput[1];
    const contentSlice = patternInput[0];
    if (lodash.isNull(contentSlice)) {
        return (null);
    }
    const matchInfo = new MatchInfo(2, Array.from(delay(() => collect((matchValue_2) => {
        const b = matchValue_2[1] | 0;
        const a = matchValue_2[0] | 0;
        return singleton(new PositionPair(a, b));
    }, zip(System_Array__$005B$005D$1_slice_Z3227AE51(contentIdxs, contentSlice), System_Array__$005B$005D$1_slice_Z3227AE51(transcriptIdxs, transcriptSlice))))));
    return new MatchResult("commonwords", matchInfo, []);
}

export function testWordSetMatchingOrder(section, contentIndex, transcriptIndex, testWords) {
    const transcriptWords = TranscriptSpan__get_words(section.transcript);
    const contentWords = ContentSpan__get_words(section.content);
    let failedTest = false;
    const contentPositions = [];
    const transcriptPositions = [];
    const workingPairs = [];
    for (let idx = 0; idx <= (testWords.length - 1); idx++) {
        const word = testWords[idx];
        System_Array__$005B$005D$1_extend_5975E3(contentPositions, contentIndex.get(word));
        System_Array__$005B$005D$1_extend_5975E3(transcriptPositions, transcriptIndex.get(word));
    }
    contentPositions.sort(compare_1);
    transcriptPositions.sort(compare_1);
    const arr = zip(contentPositions, transcriptPositions);
    for (let idx_1 = 0; idx_1 <= (arr.length - 1); idx_1++) {
        const forLoopVar = arr[idx_1];
        const transcriptPos = forLoopVar[1] | 0;
        const contentPos = forLoopVar[0] | 0;
        if (contentWords[contentPos] !== transcriptWords[transcriptPos]) {
            failedTest = true;
            break;
        }
        System_Array__$005B$005D$1_append_1505(workingPairs, new PositionPair(contentPos, transcriptPos));
    }
    return [failedTest, workingPairs];
}

export function matchSectionLongWords(options, section) {
    let x_3, x_4;
    const transcript = section.transcript;
    const content = section.content;
    const contentWordsStr = underscoreJoin(ContentSpan__get_words(content));
    const contentIndex = wordsIndex(ContentSpan__get_words(content));
    const transcriptIndex = wordsIndex(TranscriptSpan__get_words(transcript));
    const minLen = options.minWordLength | 0;
    let maxMatch = options.maxMatch | 0;
    if (maxMatch === 0) {
        maxMatch = (~(~(ContentSpan__get_words(content).length / 2)));
    }
    if (maxMatch === 0) {
        return (null);
    }
    const sortedContentWords = Array.from(delay(() => collect((word) => ((word.length >= minLen) ? singleton(word) : empty()), contentIndex.keys())));
    if (lodash.isEmpty(sortedContentWords)) {
        return (null);
    }
    sortInPlaceBy((w) => op_UnaryNegation_Int32(w.length), sortedContentWords, {
        Compare: comparePrimitives,
    });
    let currentWordLen = sortedContentWords[0].length | 0;
    const words = [];
    let pairs = [];
    let workingWords = [];
    let completedSome = false;
    let matched = 0;
    for (let idx = 0; idx <= (sortedContentWords.length - 1); idx++) {
        const word_1 = sortedContentWords[idx];
        if ((word_1.length !== currentWordLen) ? true : (matched >= maxMatch)) {
            currentWordLen = word_1.length;
            const patternInput = testWordSetMatchingOrder(section, contentIndex, transcriptIndex, append(words, workingWords));
            const newPairs = patternInput[1];
            const failedTest = patternInput[0];
            if (!failedTest) {
                System_Array__$005B$005D$1_extend_5975E3(words, workingWords);
                pairs = newPairs;
                completedSome = true;
            }
            workingWords = [];
            if (failedTest ? completedSome : false) {
                break;
            }
            if (completedSome ? (matched >= maxMatch) : false) {
                break;
            }
        }
        const contentWordPositions = contentIndex.get(word_1);
        const transcriptWordPositions = transcriptIndex.get(word_1);
        if (lodash.isEmpty(transcriptWordPositions)) {
            continue;
        }
        if (contentWordPositions.length !== transcriptWordPositions.length) {
            continue;
        }
        const wordPrefix = word_1.slice(0, options.prefixTest + 1);
        if (contentWordPositions.length !== substringCount(wordPrefix, contentWordsStr)) {
            continue;
        }
        System_Array__$005B$005D$1_append_1505(workingWords, word_1);
        matched = (matched + 1);
    }
    if (x_3 = workingWords, !(lodash.isEmpty(x_3))) {
        const patternInput_1 = testWordSetMatchingOrder(section, contentIndex, transcriptIndex, append(words, workingWords));
        const newPairs_1 = patternInput_1[1];
        const failedTest_1 = patternInput_1[0];
        if (!failedTest_1) {
            pairs = newPairs_1;
        }
    }
    if (x_4 = pairs, lodash.isEmpty(x_4)) {
        return (null);
    }
    return new MatchResult("longwords", new MatchInfo(2, pairs), []);
}

export function matchSectionInterpolate(allowSilences, section) {
    const infoTags = [];
    const silences = !audioRegionSpanIsContinuousAudio(section.audioRegions);
    if (silences ? (!allowSilences) : false) {
        return (null);
    }
    const contentWords = ContentSpan__get_words(section.content);
    const spans = wordSpans(contentWords);
    const startTime = section.startTime | 0;
    const endTime = section.endTime | 0;
    const duration = (endTime - startTime) | 0;
    const pos = section.content.data.globalPosition[0] | 0;
    const infoTag = new InfoTag(pos, new TimeInterval(startTime, endTime), "", "", null);
    if (((duration > 1000) ? (contentWords.length > 1) : false) ? (!silences) : false) {
        System_Array__$005B$005D$1_append_1505(infoTags, new InfoTag(infoTag.globalPosition, infoTag.interval, "warn", "long", infoTag.message));
    }
    const lastCharPos = spans[System_Array__$005B$005D$1_get_lastIndex(spans)][1] | 0;
    const charCount = (lastCharPos + 1) | 0;
    const quanta = duration / charCount;
    if ((quanta < 15) ? (!silences) : false) {
        System_Array__$005B$005D$1_append_1505(infoTags, new InfoTag(infoTag.globalPosition, infoTag.interval, "warn", "short", infoTag.message));
    }
    if (silences) {
        System_Array__$005B$005D$1_append_1505(infoTags, new InfoTag(infoTag.globalPosition, infoTag.interval, "warn", "silences", infoTag.message));
    }
    System_Array__$005B$005D$1_append_1505(infoTags, new InfoTag(infoTag.globalPosition, infoTag.interval, "interpolate", "interpolate", infoTag.message));
    const b = startTime | 0;
    const intervals = Array.from(delay(() => collect((matchValue) => {
        const starts = matchValue[0] | 0;
        const ends = matchValue[1] | 0;
        return singleton(new TimeInterval(~(~(b + (quanta * starts))), ~(~(b + (quanta * (ends + 1))))));
    }, spans)));
    return new MatchResult("interpolate", new MatchInfo(3, [new SliceIntervalsPair(wholeSlice(contentWords), intervals, true)]), infoTags);
}

export function matchSectionContentCues(section) {
    const contentCues = section.contentCues;
    const audioRegions = section.audioRegions;
    const cueTimestamps = contentCues.data.timestamp;
    const cueContentGlobalPositions = contentCues.data.globalPosition;
    const gapPairs = [];
    const arr = indexed(cueTimestamps);
    for (let idx = 0; idx <= (arr.length - 1); idx++) {
        const forLoopVar = arr[idx];
        const ts = forLoopVar[1] | 0;
        const cueIdx = forLoopVar[0] | 0;
        const patternInput = cueTimeToInterval(ts, audioRegions);
        const startTime = patternInput[0] | 0;
        const endTime = patternInput[1] | 0;
        const contentIdx = searchSorted(section.content.data.globalPosition, cueContentGlobalPositions[cueIdx], false) | 0;
        System_Array__$005B$005D$1_append_1505(gapPairs, new GapPair(contentIdx, new TimeInterval(startTime, endTime)));
    }
    if (lodash.isEmpty(gapPairs)) {
        return (null);
    }
    return new MatchResult("cue", new MatchInfo(1, gapPairs), []);
}

export function matchSectionTrimSilence(section) {
    let x;
    const audioRegions = section.audioRegions;
    const contentWords = ContentSpan__get_words(section.content);
    const epsilon = 25;
    let gapPairs = [];
    const patternInput = audioRegionsSpanFindAudioIntervalContains(audioRegions, section.startTime + epsilon);
    const startTime = patternInput[0] | 0;
    const regionIdx = patternInput[2] | 0;
    const endTime = patternInput[1] | 0;
    if (audioRegionsSpanIntervalIsSilence(audioRegions, regionIdx)) {
        System_Array__$005B$005D$1_append_1505(gapPairs, new GapPair(0, new TimeInterval(section.startTime, endTime)));
    }
    const patternInput_1 = audioRegionsSpanFindAudioIntervalContains(audioRegions, section.endTime - epsilon);
    const startTime_1 = patternInput_1[0] | 0;
    const regionIdx_1 = patternInput_1[2] | 0;
    const endTime_1 = patternInput_1[1] | 0;
    if (audioRegionsSpanIntervalIsSilence(audioRegions, regionIdx_1)) {
        System_Array__$005B$005D$1_append_1505(gapPairs, new GapPair(contentWords.length, new TimeInterval(startTime_1, section.endTime)));
    }
    if (x = gapPairs, lodash.isEmpty(x)) {
        return (null);
    }
    if (gapPairs.length === 2) {
        if (gapPairs[1].gapInterval.startTime <= gapPairs[0].gapInterval.endTime) {
            gapPairs = [gapPairs[0]];
        }
    }
    const infoTags = [];
    const pos = section.content.data.globalPosition[0] | 0;
    for (let idx = 0; idx <= (gapPairs.length - 1); idx++) {
        const pair = gapPairs[idx];
        System_Array__$005B$005D$1_append_1505(infoTags, new InfoTag(pos + pair.contentPos, pair.gapInterval, "trim", "trim", null));
    }
    return new MatchResult("trim", new MatchInfo(1, gapPairs), infoTags);
}

